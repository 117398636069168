import axios from 'axios';
import { BASE_API_URL } from '../urls';
import { loadInvoices } from '../../Invoices/actions/invoicesActions';
import { handleErrors } from '../../utils/helpers/errors';
import { setHeadersToken } from '../../utils/helpers/store';
import { loadContracts } from '../../Contracts/actions/contractsActions';

export const loadPayments = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'PAYMENTS_LOADING' });

    axios
      .get(`${BASE_API_URL}payments/`, { headers: setHeadersToken() })
      .then((res) => {
        dispatch({ type: 'PAYMENTS_LOADED', payload: res.data });
        Object.values(res.data).map(
          (payment) =>
            payment.status.slug === 'processing' &&
            payment.method.slug === 'multibanco' &&
            dispatch(checkCallback(payment))
        );
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'PAYMENT_FAILED');
      });
  };
};
export const updatePayment = (payment, data) => {
  return (dispatch, getState) => {
    dispatch({ type: 'PAYMENTS_UPDATING' });

    axios
      .patch(`${BASE_API_URL}payments/${payment.id}/`, data, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch(loadPayments());
        dispatch(loadInvoices());
        dispatch(loadContracts());
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'PAYMENT_FAILED');
      });
  };
};

export const createPayment = (paymentData) => {
  return (dispatch, getState) => {
    dispatch({ type: 'PAYMENT_START' });
    dispatch({ type: 'INVOICES_PAYING_START' });
    axios
      .post(`${BASE_API_URL}payments/${paymentData.invoice}`, paymentData, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'PAYMENT_SUCCESS' });
        if (res.data.method === 2) dispatch(checkCallback(res.data));
        dispatch(loadInvoices());
        dispatch(loadContracts());
        dispatch({ type: 'INVOICES_PAYING_FINISHED' });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'INVOICES_PAYING_FINISHED');
      });
  };
};

export const checkCallback = (payment, token_callback = null) => {
  return (dispatch, getState) => {
    dispatch({ type: 'PAYMENT_CHECK_START' });

    let headers = setHeadersToken();

    switch (payment.method.slug) {
      case 'multibanco': // ifthenpay
        headers['Content-Type'] = 'text/xml; charset=utf-8';
        break;
      default:
        break;
    }

    axios
      .get(payment.transaction_callback, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: 'PAYMENT_CHECK_SUCCESS' });

        if (res.data.includes('?xml') && res.data.includes('<CodigoErro>0</CodigoErro>'))
          dispatch(
            updatePayment(payment, {
              status: 'confirmed',
              confirmed_by: 'system',
            })
          );
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'PAYMENT_CHECK_FAILED');
      });
  };
};
