import { Typography } from '@mui/material';
import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import preloaderGif from '../../../assets/img/preloader.gif';

const PreloaderWrapper = styled.div<{ overlay: boolean }>`
  ${({ overlay }): FlattenSimpleInterpolation => {
    const style = css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 0;
      transition: all .3s;
    `;
    const overlayStyle = css`
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
    `;
    if (overlay) {
      return css`
        ${style}
        ${overlayStyle}
      `;
    }
    return css`
      ${style}
    `;
  }}
`;

type PreloaderImageProps = {
  width: number;
  height: number;
  visible: boolean;
};
const PreloaderImage = styled.img<PreloaderImageProps>`
  ${({ width, height, visible }): FlattenSimpleInterpolation => {
    return css`
    z-index: 3;
      width: ${width}px;
      height: ${height}px;
      display: ${visible ? 'inline-block' : 'none'};
    `;
  }}
`;

export function Preloader({ width = 48, height = 48, visible = true, overlay = false, text = '' }): React.ReactElement {
  return (
    <PreloaderWrapper overlay={overlay}>
      <PreloaderImage src={preloaderGif} alt="preloader" width={width} height={height} visible={visible} />
      {text && <Typography variant="body2">{text}</Typography>}
    </PreloaderWrapper>
  );
}

export default Preloader;
