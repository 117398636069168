export const currencyFormatDE = (num, sign = true) => {
  if (typeof num === 'string') {
    if (
      (num.includes(',') && num.includes('.')) ||
      (!num.includes(',') && num.indexOf('.') < num.length - 3)
    ) {
      num = num.replace('.', '')
    }

    if (num.indexOf(',') >= num.length - 3) {
      num = num.replace(',', '.')
    }

    num = parseFloat(num)
  }

  return (
    `${sign === true ? '€ ' : ''}` +
    num
      .toFixed(2) // always two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // use . as a separator
  )
}
