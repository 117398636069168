import React from 'react'

import PaymentSelectMethodButton from './PaymentSelectMethodButton'

const PaymentSelectMethod = ({
  setPaymentMethod,
  handleNext,
  paymentMethods,
}) => (
  <div className="grid__container spacing-16">
    {Object.values(paymentMethods).map((item, idx) => (
      <div className="grid__item xs-6 sm-4" key={idx}>
        <PaymentSelectMethodButton
          handleOnClick={() => {
            setPaymentMethod(item.name)
            handleNext()
          }}
          icon={item.icon}
          iconAlt={item.title}
        />
      </div>
    ))}
  </div>
)

export default PaymentSelectMethod
