import React from 'react'
import {
  Description as ContractIcon,
  PersonPin,
  // Visibility,
  Mail,
  Person,
  FileCopy,
  PhoneAndroid as PhoneMobileIcon,
  Home as PhoneHomeIcon,
  Work as PhoneWorkIcon,
} from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Tooltip } from '@mui/material'
import { highlight } from '../react-highlight'

const IconTextSmall = ({ icon: Icon, text, children }) => (
  <div
    className="grid__container --align-center spacing-8"
    style={{ flexWrap: 'nowrap' }}
  >
    <div className="grid__item">
      <Icon className="--t-light-gray" style={{ height: 14, width: 14 }} />
    </div>
    <div className="grid__item">
      <div className="body_2">{text}</div>
    </div>
    {children}
  </div>
)

const ContractListCard = ({
  contract,
  inputContract = '',
  setMember,
  viewContract,
}) => {
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
  }
  let name = highlight(`${contract.member.full_name}`, inputContract)
  let emailsStr = contract.member.emails.join('; ')
  let emails = (
    <>
      {highlight(emailsStr, inputContract)}{' '}
      <Tooltip title="Copiar emails">
        <IconButton
          style={{ height: 14, width: 14 }}
          onClick={() => copyToClipboard(emailsStr.replaceAll(' ', ''))}
        >
          <FileCopy className="--t-primary" style={{ height: 14, width: 14 }} />
        </IconButton>
      </Tooltip>
    </>
  )
  let phoneIcons = {
    mobile: PhoneMobileIcon,
    home: PhoneHomeIcon,
    work: PhoneWorkIcon,
  }
  let phones = (
    <div className="grid__container spacing-16">
      {Object.entries(contract.member.phones).map(([key, value], idx) => (
        <div className="grid__item" key={idx}>
          <IconTextSmall
            icon={phoneIcons[key]}
            text={highlight(value, inputContract)}
          />
        </div>
      ))}
    </div>
  )

  return (
    <div className="card">
      <div className="card__body">
        <div className="grid__container --space-between --align-center spacing-16">
          {/* Contract info */}
          <div className="grid__item xs-8">
            <div className="grid__container grid__container--column spacing-24">
              <div className="grid__item">
                <div className="grid__container spacing-16 --align-center">
                  <div className="grid__item">
                    <ContractIcon className="--t-light-gray" />
                  </div>

                  <div className="grid__container grid__container--column">
                    <div className="grid__item">
                      <div className="body_1 --t-bold">
                        {highlight(contract.composed_number, inputContract)}
                      </div>
                    </div>
                    {contract.ref_number && (
                      <div className="grid__item">
                        <div className="body_2 text-mutted">
                          Ref:{' '}
                          <span className="--t-bold">
                            {highlight(contract.ref_number, inputContract)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid__item">
                <div className="grid__container grid__container--column spacing-16">
                  <div className="grid__item">
                    <IconTextSmall
                      icon={Person}
                      text={
                        <span>
                          <span>{name}</span>
                          <br />
                          <small>CID:{contract.member.customer_id}</small>
                        </span>
                      }
                    />
                  </div>
                  {phones && <div className="grid__item">{phones}</div>}
                  {emails && (
                    <div className="grid__item">
                      <IconTextSmall icon={Mail} text={emails} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Action buttons */}
          <div className="grid__item">
            <div className="grid__container spacing-16 --align-center">
              <div className="grid__item">
                <span
                  className={`chip ${
                    contract.status.class_name
                      ? 'chip--' + contract.status.class_name + '--light'
                      : 'disabled'
                  }`}
                >
                  {contract.status.name}
                </span>
              </div>
              {/* <div className="grid__item">
                <Tooltip title="Ver contrato">
                  <IconButton onClick={viewContract}>
                    <Visibility fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </div> */}
              <div className="grid__item">
                <Tooltip title="Ver sistema como o membro">
                  <IconButton onClick={() => setMember(contract.member)}>
                    <PersonPin fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractListCard
