import React from 'react';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import ExchangeCard from './ExchangeCard';
import { Dialogs, HandleOpenDialogProps } from './Exchanges';
import { Exchange } from './types';
import { Grid } from '@mui/material';

type GridItemProps = {
  index?: number;
  children: React.ReactNode;
};
const GridItem: React.FC<GridItemProps> = ({ index = 0, children }) => {
  return (
    <Grid
      className="grid__item xs-12 md-6 lg-4 xl-3 animated zoomIn faster"
      style={{ animationDelay: `${index * 0.1}s` }}
    >
      {children}
    </Grid>
  );
};

type ExchangesCardsProps = {
  exchanges: Exchange[];
  handleOpenDialog: (data: HandleOpenDialogProps) => void;
};
export function ExchangesCards({ exchanges, handleOpenDialog }: ExchangesCardsProps): React.ReactElement {
  return (
    <div className="panel panel--transparent animated fadeIn faster">
      <div className="panel__body">
        <div className="grid">
          <div className="grid__container spacing-16">
            {Object.values(exchanges).length > 0 ? (
              Object.values(exchanges).map((exchange, index) => (
                <GridItem key={index} index={index}>
                  <ExchangeCard
                    exchange={exchange}
                    handleOpenDialog={() =>
                      handleOpenDialog({
                        dialog: Dialogs.Buy,
                        open: true,
                        exchange: exchange,
                      })
                    }
                  />
                </GridItem>
              ))
            ) : (
              <GridItem>
                <div className="card --ta-center">
                  <div className="card__body">
                    <h1 className="text-mutted">
                      <InfoIcon style={{ fontSize: 48 }} />
                    </h1>
                    <p className="text-mutted">De momento, não existem reservas disponíveis para trocar ou aluguer.</p>
                  </div>
                </div>
              </GridItem>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExchangesCards;
