import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import AnimatedGridItem from '../../../components/utils/AnimatedGridItem';
import TransactionCardSmall from '../../../components/Exchanges/TransactionCardSmall';
import { getExchangesSelector } from '../../../components/Exchanges/selectors';
import { HandleOpenDialogProps } from '.';

type PendingTransactionsProps = {
  handleOpenDialog: (data: HandleOpenDialogProps) => void;
};
export function PendingTransactions({ handleOpenDialog }: PendingTransactionsProps): React.ReactElement | null {
  const { transactions } = useSelector(getExchangesSelector);
  const hasTransactions = transactions.length > 0;

  if (!hasTransactions) {
    return null;
  }
  return (
    <Fragment>
      {Object.values(transactions).map((transaction, index) => (
        <AnimatedGridItem key={index} index={index}>
          <TransactionCardSmall transaction={transaction} handleTransactionDialog={handleOpenDialog} />
        </AnimatedGridItem>
      ))}
    </Fragment>
  );
}

export default PendingTransactions;
