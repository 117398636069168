import React from 'react'

const GridItem = ({
  index = 0,
  animate = true,
  customClass = '',
  children,
}) => {
  return (
    <div
      className={`${
        customClass ? customClass : 'grid__item xs-12 sm-6 md-4 xl-3'
      } ${animate ? 'animated zoomIn faster' : ''}`}
      style={{ animationDelay: `${index * 0.1}s` }}
    >
      {children}
    </div>
  )
}

export default GridItem
