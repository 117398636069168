import { EuroSymbol } from '@material-ui/icons'
import { Avatar } from '@material-ui/core'
import React from 'react'

const EuroCoin = () => {
  return (
    <Avatar
      style={{
        backgroundColor: '#f9d34c',
        color: '#b38313',
        height: 26,
        width: 26,
        marginLeft: 2,
      }}
    >
      <EuroSymbol style={{ width: 16, height: 16, marginLeft: -2 }} />
    </Avatar>
  )
}

export default EuroCoin
