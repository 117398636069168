import React from 'react';

import Bookings from '../../../components/Bookings/Bookings';
import SectionHeader from '../SectionHeader';

export function BookingsPage(): React.ReactElement {
  return (
    <div className="bookings">
      <div className="grid__container grid__container--column spacing-32">
        <div className="grid__item">
          <SectionHeader
            icon="today"
            title="Bookings"
            subtitle="As suas próximas estadias nos nossos Empreendimentos."
          />
        </div>
        <div className="grid__item">
          <Bookings />
        </div>
      </div>
    </div>
  );
}

export default BookingsPage;
