import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import InputMask from 'react-input-mask'

const Input = ({
  type,
  required,
  autoFocus,
  name,
  label,
  placeholder,
  value,
  handleChange,
  errors,
  helperText,
  icon,
  mask
}) => {
  const inputProps = {
    type: type ? type : 'text',
    name: name,
    value: value,
    onChange: handleChange(name),
    placeholder: placeholder,
    required: required ? true : false,
    autoFocus: autoFocus ? true : false
  }
  const textFieldProps = {
    icon: icon,
    label: label,
    helperText: errors && errors[name] ? errors[name] : helperText,
    fullWidth: true,
    error: errors && errors[name] ? true : false
  }
  if (mask)
    return (
      <InputMask mask={mask} alwaysShowMask={false} maskChar="" {...inputProps}>
        {inputProps => CustomTextField({ ...inputProps, ...textFieldProps })}
      </InputMask>
    )
  return CustomTextField({ ...inputProps, ...textFieldProps })
}

export default Input

const CustomTextField = ({ ...props }) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: props.icon && (
        <InputAdornment
          variant="filled"
          position="end"
          style={{ color: '#999' }}
        >
          {props.icon}
        </InputAdornment>
      )
    }}
    InputLabelProps={{
      shrink: props.placeholder && props.label && true
    }}
  />
)
