const initialState = {
  isLoading: false,
  items: [],
  errors: null,
}

const contractsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONTRACTS_LOADING':
      return { ...state, errors: null, isLoading: true }
    case 'CONTRACTS_LOADED':
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      }

    case 'FAILED':
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      }

    default:
      return state
  }
}
export default contractsReducer
