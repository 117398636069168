import axios from 'axios'
import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const loadResorts = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'RESORTS_LOADING' })

    axios
      .get(`${BASE_API_URL}`, {
        headers: setHeadersToken()
      })
      .then(res => {
        dispatch({ type: 'RESORTS_LOADED', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch)
      })

    axios
      .get(`${BASE_API_URL}property_types/`, {
        headers: setHeadersToken()
      })
      .then(res => {
        dispatch({ type: 'PROPERTY_TYPES_LOADED', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch)
      })
  }
}
