import React, { Fragment } from 'react';
import moment from 'moment';
import { DateRange as CalendarIcon } from '@material-ui/icons';

type BookingDatesProps = {
  checkin: string | Date;
  checkout: string | Date;
  calendarIconClass?: string;
  hideLabel?: boolean;
  hideIcon?: boolean;
  centered?: boolean;
};
export function BookingDates({
  checkin,
  checkout,
  calendarIconClass,
  hideLabel,
  hideIcon,
  centered,
}: BookingDatesProps): React.ReactElement {
  return (
    <div className="grid__container spacing-8 --align-center">
      {hideLabel ? (
        <Fragment>
          {!hideIcon && (
            <div className="grid__item" style={{ fontSize: '20px', display: 'flex' }}>
              <CalendarIcon className={calendarIconClass ? calendarIconClass : 'defaultIconClass'} fontSize="inherit" />
            </div>
          )}
          <div className="grid__item">
            <p>
              <span className="--t-bold">{moment(checkin).format('DD MMM YYYY')}</span>{' '}
              <span className="text-mutted">-</span>{' '}
              <span className="--t-bold">{moment(checkout).format('DD MMM YYYY')}</span>
            </p>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="grid__item xs-12 divisor divisor--h"></div>
          <div className="grid__item xs-12 mt-16 mb-16">
            <div className="grid__container spacing-16">
              <div className={`grid__item --flex-fill ${centered ? '--ta-center' : ''}`}>
                {!hideLabel && <p className="body_2 --nomargin">Check-in</p>}
                <p>
                  <b>{moment(checkin).format('ddd, DD MMM YYYY')}</b>
                </p>
              </div>
              <div className="grid__item divisor divisor--v"></div>
              <div className={`grid__item --flex-fill ${centered ? '--ta-center' : ''}`}>
                {!hideLabel && <p className="body_2 --nomargin">Checkout</p>}
                <p>
                  <b>{moment(checkout).format('ddd, DD MMM YYYY')}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="grid__item xs-12 divisor divisor--h"></div>
        </Fragment>
      )}
    </div>
  );
}

export default BookingDates;
