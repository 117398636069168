import moment from 'moment'

export function getDaysBetweenWeeks(weekStart, weekEnd, includeDays = false) {
  let from,
    to,
    daysDif,
    days = []

  from = moment(weekStart).weekday(0)
  to = moment(weekEnd).weekday(6)

  if (includeDays) {
    daysDif = to.diff(from, 'days')

    for (let i = 0; i < daysDif; i++) {
      days.push(moment(weekStart).add(i, 'days').toDate())
    }
  }
  return {
    from: from.toDate(),
    to: to.toDate(),
    days: days,
  }
}
export function getRange(date, addDays) {
  let from,
    to,
    days = []
  from = moment(date).toDate()
  to = moment(date).add(addDays, 'days').toDate()
  for (let i = 0; i < addDays; i++) {
    days.push(moment(date).add(i, 'days').toDate())
  }
  return {
    from: from,
    to: to,
    days: days,
  }
}
