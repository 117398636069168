import React from 'react'
import { IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import { Create as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { currencyFormatDE } from '../utils/currency'

const Rule = ({ rule, index, handleEdit, handleDelete, propertyTypes }) => {
  return (
    <div className="list__item pl-8 pr-8">
      <div className="content">
        <div className="grid__container spacing-16 --align-center">
          <div className="grid__item">
            <label className="overline">#</label>
            <b>{index + 1}</b>
          </div>
          {rule.property_type && (
            <div className="grid__item">
              <label className="overline">Empreendimento</label>
              <p>
                {
                  propertyTypes.find(
                    (property) => property.id === rule.property_type
                  ).name
                }
              </p>
            </div>
          )}
          {rule.range_start && (
            <div className="grid__item">
              <div className="grid__container spacing-16">
                <div className="grid__item" xs>
                  <label className="overline">De</label>
                  <p>{moment(rule.range_start).format('DD/MM/YYYY')}</p>
                </div>
                <div className="grid__item" xs>
                  <label className="overline">Até</label>
                  <p>{moment(rule.range_end).format('DD/MM/YYYY')}</p>
                </div>
              </div>
            </div>
          )}
          {rule.fee && (
            <div className="grid__item">
              <label className="overline">Valor</label>
              <p>{currencyFormatDE(rule.fee)}</p>
            </div>
          )}
          {handleEdit && handleDelete && (
            <div className="grid__item ml-auto">
              <IconButton onClick={handleEdit}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <DeleteIcon fontSize="small" className="--t-alert" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    propertyTypes: state.resorts.propertyTypes,
  }
}
export default connect(mapStateToProps)(Rule)
