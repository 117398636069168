import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Base from './Base';
import LoginStepper from './LoginStepper';
import { getAuthSelector, getAuthToken } from '../../components/Auth/selectors';

export default function LoginPage(): React.ReactElement {
  const { isAuthenticated } = useSelector(getAuthSelector);
  const hasAuthToken = getAuthToken();
  const isLoggedIn = isAuthenticated || hasAuthToken;

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Base>
      <LoginStepper />
    </Base>
  );
}
