import React from 'react';
import { connect } from 'react-redux';
import CustomExpansionPanel from '../utils/CustomExpansionPanel';
import TransactionCardSmall from './TransactionCardSmall';

const CurrentTransactions = ({ transactions, myId, handleOpenDialog }) => {
  const processing_transactions = Object.values(transactions).filter(
    (transaction) => transaction.status.slug === 'processing' && transaction.exchange.seller !== myId
  );
  return (
    <CustomExpansionPanel
      title="Transações em aberto"
      helperText="Trocas que possuem valor na negociação e o titular está a aguardar o 
                  recebimento da transferência para confirmar a transação."
      expanded={true}
    >
      <div className="grid">
        <div className="grid__container grid__container--column spacing-24">
          {Object.values(processing_transactions).length > 0 ? (
            Object.values(processing_transactions).map((transaction, index) => (
              <div className="grid__item" key={transaction.id}>
                <TransactionCardSmall transaction={transaction} handleTransactionDialog={handleOpenDialog} />
              </div>
            ))
          ) : (
            <div className="grid__item">
              <p className="section__subtitle section__subtitle--small">Não existem transações em aberto.</p>
            </div>
          )}
        </div>
      </div>
    </CustomExpansionPanel>
  );
};

const mapStateToProps = (state) => ({
  myId: state.members.member?.customer_id,
  transactions: state.exchanges.transactions,
});

export default connect(mapStateToProps)(CurrentTransactions);
