import React from 'react'
import Input from '../../components/utils/Input'
import { LockOutlined as PassIcon } from '@material-ui/icons'
import PassStrengthBar from '../../components/react-pass-strengh-bar/PassStrengthBar'

const AuthStepInsertPass = ({
  step,
  activeStep,
  handleOnSubmit,
  handleInputChange,
  handleBack,
  credentials,
  hanldeSendPasswordRecovery,
  props,
}) => {
  return (
    <form onSubmit={handleOnSubmit}>
      <div className="grid__container grid__container--column spacing-24">
        {/* INPUT Password */}
        <div className="grid__item">
          <Input
            name="password"
            type="password"
            label="Senha"
            value={credentials.password}
            handleChange={handleInputChange}
            errors={props.errors}
            icon={<PassIcon />}
            required
            autoFocus={activeStep === step ? true : false}
          />
          <PassStrengthBar value={credentials.password} />
        </div>

        {/* Forgot password button */}
        <div className="grid__item">
          <span
            className="btn btn--primary--link"
            onClick={hanldeSendPasswordRecovery}
          >
            Esqueci / Redefinir minha senha
          </span>
        </div>
      </div>

      {/* ACTIONS */}
      <div className="stepper__footer-actions">
        {activeStep > 0 && (
          <button
            type="button"
            className={`btn btn--primary outlined action-btn`}
            onClick={handleBack}
          >
            Voltar
          </button>
        )}
        <button type="submit" className={`btn btn--primary raised action-btn`}>
          Continuar
        </button>
      </div>
    </form>
  )
}

export default AuthStepInsertPass
