import React, { Fragment } from 'react';
import { Dialog, DialogTitle, IconButton, Slide, DialogContent, useTheme, useMediaQuery } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close as CloseIcon, Done as SuccessIcon, Warning as FailIcon } from '@material-ui/icons';

import { Preloader } from './Preloader';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ResponsiveDialogProps = {
  open: boolean;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  text?: string | null;
  textYes?: string;
  textNo?: string;
  handleYes?: () => void;
  handleCloseDialog?: () => void;
  handleNo?: () => void;
  hideCloseButton?: boolean;
  failedMessage?: string | React.ReactNode | null;
  successMessage?: string | React.ReactNode | null;
  children?: React.ReactNode;
  failedComponent?: React.ReactElement;
  successComponent?: React.ReactElement;
  actions?: React.ReactElement | null;
  actionsClass?: string;
  maxWidth?: 'md' | 'lg' | 'sm';
};

export function ResponsiveDialog({
  failedMessage,
  failedComponent,
  successMessage,
  successComponent,
  title,
  subtitle,
  open,
  handleCloseDialog,
  hideCloseButton,
  children,
  actions,
  actionsClass,
  isLoading = false,
  maxWidth = 'md',
}: ResponsiveDialogProps): React.ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={!hideCloseButton ? handleCloseDialog : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
    >
      {!failedMessage && !failedComponent && !successMessage && !successComponent && title && (
        <DialogTitle id="alert-dialog-title">
          {
            <div className="grid__container --align-center --space-between">
              <div className="grid__item">
                <h1 className="dialog__title">{title}</h1>
                {subtitle && <p className="dialog__subtitle">{subtitle}</p>}
              </div>
              {!hideCloseButton && (
                <div className="grid__item">
                  <IconButton onClick={handleCloseDialog}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
          }
        </DialogTitle>
      )}
      <DialogContent>
        {successComponent || failedComponent ? (
          <Fragment>
            {successComponent}
            {failedComponent}
          </Fragment>
        ) : (
          <Fragment>
            {failedMessage || successMessage ? (
              <div className="--ta-center">
                <div className="dialog__icon">
                  {successMessage && <SuccessIcon className="--t-success" />}
                  {failedMessage && <FailIcon className="--t-warning" />}
                </div>
                <div className="dialog__body">
                  {successMessage &&
                    (typeof successMessage === 'string' ? (
                      <p dangerouslySetInnerHTML={{ __html: successMessage }} />
                    ) : (
                      successMessage
                    ))}
                  {failedMessage &&
                    (typeof failedMessage === 'string' ? (
                      <p dangerouslySetInnerHTML={{ __html: failedMessage }} />
                    ) : (
                      failedMessage
                    ))}
                </div>
              </div>
            ) : (
              children
            )}
          </Fragment>
        )}
        {(actions || failedMessage || successMessage) && (
          <div
            className={`dialog__action-btns ${actionsClass} ${(failedMessage || successMessage) && '--justify-center'}`}
            style={{
              paddingTop: 16,
              marginTop: 16,
              borderTop: '1px solid rgba(0,0,0,0.075)',
            }}
          >
            {isLoading ? (
              <Preloader />
            ) : failedMessage || successMessage ? (
              <button className="btn btn--primary outlined action-btn" onClick={handleCloseDialog}>
                Fechar
              </button>
            ) : (
              actions
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ResponsiveDialog;
