import React from 'react'

export const SwipeStep = ({
  handleNext,
  nextSubmit,
  nextBtnTxt = 'Continuar',
  handlePrev,
  prevBtnTxt = 'Voltar',
  children,
  step,
  activeStep,
}) => {
  return (
    <div className={`swipeable__view ${activeStep !== step && 'hidden'}`}>
      <div className={`wrapper wrapper--hidden `}>
        {children}
        {(handleNext || handlePrev) && (
          <div className="stepper__footer-actions">
            {handleNext && (
              <button
                className="btn btn--primary raised action-btn"
                onClick={nextSubmit ? null : handleNext}
                type={nextSubmit ? 'submit' : 'button'}
              >
                {nextBtnTxt}
              </button>
            )}
            {handlePrev && (
              <button
                className="btn btn--primary outlined action-btn"
                onClick={handlePrev}
              >
                {prevBtnTxt}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
