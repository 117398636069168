import axios from 'axios'
import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const setSelectedTransaction = (transaction) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_SELECTED_TRANSACTION', payload: transaction })
  }
}
export const handleTransaction = (action) => {
  return (dispatch, getState) => {
    const memberId = getState().members?.member?.customer_id
    let data = (() => {
      switch (action) {
        case 'giveup':
          dispatch({ type: 'TRANSACTION_GIVEUP' })
          return {
            canceled_by: 'buyer',
            canceled_reason: 'Desistência de aquisição.',
          }
        case 'confirm-payment':
          dispatch({ type: 'TRANSACTION_CONFIRM_PAYMENT' })
          return {
            is_paid: true,
          }
        case 'confirm':
          dispatch({ type: 'TRANSACTION_CONFIRM' })
          return {
            is_confirmed: true,
          }

        default:
          return null
      }
    })(action)

    if (data)
      axios
        .patch(
          `${BASE_API_URL}transactions/${memberId}/${
            getState().exchanges.selectedTransaction.id
          }/`,
          data,
          {
            headers: setHeadersToken(),
          }
        )
        .then((res) => {
          dispatch({
            type: 'TRANSACTION_PROCESS_SUCCESS',
            payload: res.data.msg ? res.data.msg : null,
          })
          dispatch(loadExchanges())
          dispatch(loadCurrentTransactions())
        })
        .catch((error) => {
          handleErrors(error, dispatch, 'TRANSACTION_PROCESS_FAILED')
        })
  }
}
export const loadExchanges = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'EXCHANGES_LOADING' })

    axios
      .get(`${BASE_API_URL}`, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'EXCHANGES_LOADED', payload: res.data })
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'EXCHANGES_FAILED')
      })
  }
}
export const loadCurrentTransactions = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'TRANSACTIONS_LOADING' })
    let memberId = getState().members?.member?.customer_id
    if (memberId)
      axios
        .get(`${BASE_API_URL}transactions/${memberId}/`, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          if (
            Object.values(getState().exchanges.transactions).length !==
              Object.values(res.data).length ||
            (Object.values(getState().exchanges.transactions).length > 0 &&
              Object.values(res.data).length > 0 &&
              getState().exchanges.transactions[0].id !== res.data[0].id)
          )
            dispatch(loadHistoryTransactions())

          dispatch({ type: 'TRANSACTIONS_LOADED', payload: res.data })
        })
        .catch((error) => {
          handleErrors(error, dispatch, 'TRANSACTIONS_FAILED')
        })
    else dispatch({ type: 'TRANSACTIONS_LOADED', payload: [] })
  }
}
export const loadHistoryTransactions = (direction = null) => {
  return (dispatch, getState) => {
    dispatch({ type: 'TRANSACTIONS_HISTORY_LOADING' })
    let memberId = getState().members?.member?.customer_id
    let url = `${BASE_API_URL}transactions/history/${memberId}/`
    if (direction === 'next' && getState().exchanges.transactionHistory.next)
      url = getState().exchanges.transactionHistory.next

    if (memberId)
      axios
        .get(url, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          dispatch({
            type: 'TRANSACTIONS_HISTORY_LOADED',
            payload: res.data,
            direction: direction,
          })
        })
        .catch((error) => {
          handleErrors(error, dispatch, 'TRANSACTIONS_HISTORY_FAILED')
        })
    else
      dispatch({
        type: 'TRANSACTIONS_HISTORY_LOADED',
        payload: [],
        direction: direction,
      })
  }
}
