import React from 'react'
import { NavLink } from 'react-router-dom'
import HelpToolTip from '../utils/HelpToolTip.tsx'
import { CompareArrows as TradeIcon } from '@material-ui/icons'

const ExchangeBankLink = ({ booking }) => {
  return (
    <NavLink to="/banco-de-trocas" className="btn">
      <HelpToolTip
        icon={<TradeIcon color="primary" />}
        title={
          booking.exchange
            ? 'Reserva depositada no Banco de Trocas'
            : booking.in_transaction
            ? 'Reserva em transação no Banco de Trocas'
            : 'Erro.'
        }
      />
    </NavLink>
  )
}

export default ExchangeBankLink
