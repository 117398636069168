import Dashboard from '../views/Dashboard';
import LoginPage from '../views/Auth/Login';
import LogoutPage from '../views/Auth/Logout';
import ResetPassword from '../views/Auth/ResetPassword';

const indexRoutes = [
  { path: '/users/auth/password/confirm/:token/', component: ResetPassword },
  { path: '/login', component: LoginPage },
  { path: '/logout', component: LogoutPage },
  { path: '*', component: Dashboard, private: true },
];

export default indexRoutes;
