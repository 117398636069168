import React from 'react'
import { Description as ContractIcon } from '@material-ui/icons'
import { ExchangedBooking } from '../commom/ExchangedBooking'

const ContractHeader = ({
  contract,
  iconSize = 20,
  hideLabel = false,
  isBookingFromExchange = false,
}) => {
  return (
    <div className='grid__container --align-center spacing-8'>
      {hideLabel && (
        <div className='grid__item'>
          <ContractIcon style={{ opacity: 0.25, fontSize: iconSize }} />
        </div>
      )}
      <div className='grid__item'>
        {!hideLabel && <p className='body_2 --nomargin'>Contrato</p>}
        {isBookingFromExchange ? (
          <ExchangedBooking />
        ) : (
          <p className='body_2 --t-bold'>{contract}</p>
        )}
      </div>
    </div>
  )
}

export default ContractHeader
