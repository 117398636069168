import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../components/Auth/actions/authActions';
import { Preloader } from '../../components/utils/Preloader';
import Base from './Base';
import { getSiteConfigSelector } from '../../selectors';
import { Error } from '../../components/Error';

export function LogoutPage(): React.ReactElement {
  const { isLoading, error, data: siteConfig } = useSelector(getSiteConfigSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);

  if (isLoading) return <Preloader />;
  if (error) {
    <Error error={error} />;
  }

  return (
    <Base>
      <div className="grid__container grid__container--column spacing-40">
        <div className="grid__item">
          <div className="grid__container grid__container--column spacing-32">
            <div className="grid__item">
              <p className="card__title">Agradecemos a sua visita.</p>
              <p className="card__text">Esperamos que tenha tido uma excelente experiência no nosso novo sistema.</p>
              <p className="card__text">
                Críticas e sugestões podem ser enviadas para{' '}
                <a href={`mailto:${siteConfig.contact_email}`}>
                  <b>{siteConfig.contact_email}</b>.
                </a>
                .
              </p>
              <p className="card__text">Até a próxima.</p>
            </div>
          </div>
        </div>
        <div className="grid__item --ta-center">
          <NavLink className="btn btn--primary outlined action-btn" to="/login">
            Aceder novamente
          </NavLink>
        </div>
      </div>
    </Base>
  );
}

export default LogoutPage;
