import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Invoices from '../../../components/Invoices/Invoices';
import SectionHeader from '../SectionHeader';
import {
  setSelectedTransaction,
  handleTransaction,
  loadCurrentTransactions,
} from '../../../components/Exchanges/actions/exchangesActions';
import { hasFunds } from '../../../components/Contracts/utils';
import ExchangeDialogs from '../../../components/Exchanges/ExchangeDialogs';
import { getBookingsSelector } from '../../../components/Bookings/selectors';
import { getContractsSelector } from '../../../components/Contracts/selectors';
import { getExchangesSelector } from '../../../components/Exchanges/selectors';
import { Transaction } from '../../../components/Exchanges/types';
import { loadBookings } from '../../../components/Bookings/actions/bookingsActions';
import { loadContracts } from '../../../components/Contracts/actions/contractsActions';
import { State } from '../../../@types';

import UpcomingBookings from './UpcomingBookings';
import PendingTransactions from './PendingTransactions';
import { getMemberSelector } from '../../../components/Members/selectors';

export enum Dialogs {
  GiveUp = 'GiveUp',
  ConfirmPayment = 'ConfirmPayment',
  ConfirmTransaction = 'ConfirmTransaction',
}

export type HandleOpenDialogProps = {
  open: boolean;
  dialog: keyof typeof Dialogs | null;
  transaction: Transaction | null;
};

type DialogProps = {
  handleYes: () => Dispatch<any>;
  text: string;
};

export function Home(): React.ReactElement {
  const { items: bookings } = useSelector(getBookingsSelector);
  const { items: contracts } = useSelector(getContractsSelector);
  const { items: exchanges } = useSelector(getExchangesSelector);
  const { member } = useSelector(getMemberSelector);
  const {
    siteConfig: { data: siteConfig },
  } = useSelector((state: State) => state);
  const [openDialog, setOpenDialog] = useState<DialogProps | null>(null);
  
  const dispatch = useDispatch();
  
  const memberId = member?.customer_id;
  const upcomingBookings = Object.values(bookings).filter(
    (booking) => moment(booking.checkin) > moment() && booking.status === 'confirmed'
  );
  const contractsAllowedToBook = Object.values(contracts).some((contract) => hasFunds(contract));
  const isMembersWebsite = siteConfig.site?.domain === 'membros.pontagrande.com';

  const transactionDialogs = {
    [Dialogs.GiveUp]: {
      handleYes: () => dispatch(handleTransaction('giveup')),
      text: 'Deseja desistir da transação?',
    },
    [Dialogs.ConfirmPayment]: {
      handleYes: () => dispatch(handleTransaction('confirm-payment')),
      text: 'Confirma que efetuou a transferência?',
    },
    [Dialogs.ConfirmTransaction]: {
      handleYes: () => dispatch(handleTransaction('confirm')),
      text: 'Confirma o recebimento da transferência?',
    },
  };

  const handleOpenDialog = ({ open = true, dialog = null, transaction = null }: HandleOpenDialogProps) => {
    if (transaction) {
      dispatch(setSelectedTransaction(transaction));
    } else {
      dispatch(setSelectedTransaction(null));
    }

    if (open && dialog) {
      setOpenDialog({ ...transactionDialogs[dialog] });
    } else {
      setOpenDialog(null);
    }
  };

  useEffect(() => {
    if (memberId) {
      dispatch(loadCurrentTransactions());
      dispatch(loadBookings());
      dispatch(loadContracts());
      if (isMembersWebsite) {
        dispatch(loadCurrentTransactions());
      }
    }
  }, [dispatch, isMembersWebsite, memberId]);

  return (
    <div className="section">
      <SectionHeader icon="home" title="Home" />
      <div className="grid">
        <div className="grid__container spacing-32">
          <UpcomingBookings contractsAllowedToBook={contractsAllowedToBook} upcomingBookings={upcomingBookings} />

          <Invoices />

          <PendingTransactions handleOpenDialog={handleOpenDialog} />
        </div>
      </div>
      <ExchangeDialogs openDialog={openDialog} handleOpenDialog={handleOpenDialog} exchanges={exchanges} />
    </div>
  );
}

export default Home;
