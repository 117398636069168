import axios from 'axios'
import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const loadMember = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'MEMBER_LOADING' })

    axios
      .get(`${BASE_API_URL}`, { headers: setHeadersToken() })
      .then((res) => {
        dispatch({ type: 'MEMBER_LOADED', payload: res.data })
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'MEMBER_FAILED')
      })
  }
}

export const setMember = (member) => {
  return (dispatch, getState) =>
    dispatch({ type: 'MEMBER_LOADED', payload: member })
}
