import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import { createPayment } from './actions/paymentsActions';
import { Preloader } from '../utils/Preloader';

const PaypalButtonWrapper = React.memo(({ invoice, handlePaymentConfirm }) => {
  const { balance_to_pay: amount, currency_code: currency, id:invoiceId } = invoice;

  const dispatch = useDispatch();

  const style = { color: 'blue', height: 36, label: 'pay' };

  const [{ options, isPending }, payPalDispatch] = usePayPalScriptReducer();
  useEffect(() => {
    payPalDispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency]);

  return (
    <Fragment>
      {isPending && (
        <div style={{ marginTop: 15 }}>
          <span className="body_2 text-mutted">Por favor aguarde...</span>
          <Preloader />
        </div>
      )}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              return orderId;
            });
        }}
        onApprove={async function (data, actions) {
          const order = await actions.order.capture();
          if (!order) {
            console.log('Error order response');
          }
          const { status, orderID } = order;
          if (status === 'COMPLETED') {
            const paymentData = {
              invoice: invoiceId,
              amount,
              status: 'confirmed',
              method: 'paypal',
              transaction_id: orderID,
              confirmed_by: 'system',
            };
            dispatch(createPayment(paymentData));
            handlePaymentConfirm();
          }
        }}
      />
    </Fragment>
  );
});

export default function PaypalButton(props) {
  const { invoice, handlePaymentConfirm } = props;
  const { currency_code: currency, supplier } = invoice;
  const { paypal_client_id, paypal_client_id_sandbox } = supplier;
  const clientId = process.env.NODE_ENV === 'production' ? paypal_client_id : paypal_client_id_sandbox;

  return (
    <div style={{ marginTop: 15, maxWidth: 250 }}>
      <PayPalScriptProvider options={{ 'client-id': clientId, components: 'buttons', currency }} deferLoading={true}>
        <PaypalButtonWrapper invoice={invoice} handlePaymentConfirm={handlePaymentConfirm} />
      </PayPalScriptProvider>
    </div>
  );
}
