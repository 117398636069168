import {
  Home as HomeIcon,
  Person as MemberIcon,
  Work as ContractsIcon,
  Today as BookingsIcon,
  CompareArrows as TradeIcon,
} from '@material-ui/icons';

import Home from '../views/Dashboard/Home';
import Member from '../views/Dashboard/Member';
import Contracts from '../views/Dashboard/Contracts';
import BookingsPage from '../views/Dashboard/Bookings';
import ExchangesPage from '../views/Dashboard/Exchanges';

const dashboardRoutes = [
  {
    path: '/home',
    pageTitle: 'Home',
    navbarName: 'Home',
    icon: HomeIcon,
    component: Home,
  },
  {
    path: '/dados',
    pageTitle: 'Dados',
    navbarName: 'Dados',
    icon: MemberIcon,
    component: Member,
  },
  {
    path: '/contratos',
    pageTitle: 'Contratos',
    navbarName: 'Contratos',
    icon: ContractsIcon,
    component: Contracts,
  },
  {
    path: '/reservas',
    pageTitle: 'Reservas',
    navbarName: 'Reservas',
    icon: BookingsIcon,
    component: BookingsPage,
  },
  {
    path: '/banco-de-trocas',
    pageTitle: 'Banco de Trocas',
    navbarName: 'Banco de Trocas',
    icon: TradeIcon,
    component: ExchangesPage,
  },

  { redirect: true, path: '/', to: '/home', navbarName: 'Redirect' },
];

export default dashboardRoutes;
