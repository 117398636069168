import React from 'react'
import { NavLink } from 'react-router-dom'

import { Today as BookingsIcon } from '@material-ui/icons'

export const BookNow = () => (
  <div className="card animated bounceIn bg--primary gradient --ta-center">
    <div className="card__body">
      <h1>
        <BookingsIcon style={{ fontSize: 48 }} />
      </h1>
      <p className="card__text --t-bold">
        Não perca tempo e faça já a sua reserva!
      </p>
    </div>
    <div className="card__footer">
      <NavLink to="/contratos">
        <button className="btn btn--white outlined">Reservar</button>
      </NavLink>
    </div>
  </div>
)

export default BookNow
