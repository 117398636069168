import React from 'react'
import { currencyFormatDE } from '../utils/currency'

const PaymentDialogHeader = ({ invoice }) => {
  return (
    <div className="payment__title">
      <div className="grid__container spacing-16" justify="space-between">
        <div className="grid__item xs-12 sm-6">
          <div className="grid__container grid__container--column spacing-16">
            <div className="grid__item">
              <label className="overline">Referência</label>
              <p className="card__text">{invoice.reference}</p>
            </div>
            {invoice.contract && (
              <div className="grid__item">
                <label className="overline">Contrato</label>
                <p className="card__title">
                  {invoice.contract.composed_number}
                </p>
              </div>
            )}
            <div className="grid__item">
              <label className="overline">Ano</label>
              <p className="card__text">{invoice.year_reference}</p>
            </div>
          </div>
        </div>
        <div className="grid__item xs-12 sm-6 --ta-right">
          <label className="overline">Valor</label>
          <p className="card__title">
            <b>{currencyFormatDE(invoice.balance_to_pay)}</b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentDialogHeader
