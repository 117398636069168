import React, { useState } from 'react'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import axios from 'axios'
import { BASE_API_URL } from './urls'

import { Chip, Checkbox, FormControlLabel } from '@material-ui/core'

import { setHeadersToken } from '../utils/helpers/store'
import ResponsiveDialog from '../utils/ResponsiveDialog'
import {
  loadCurrentTransactions,
  loadExchanges,
  loadHistoryTransactions,
} from './actions/exchangesActions'
import Media from '../utils/Media'
import BookingDates from '../Bookings/BookingDates'
import { currencyFormatDE } from '../utils/currency'
import EuroCoin from '../utils/EuroCoin'

const BuyDialog = (props) => {
  const { open } = props
  const rule = props.exchange,
    exchange = rule?.exchange

  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState({
    failedMessage: '',
    successMessage: '',
  })
  const [activeStep, setActiveStep] = useState(0)
  const [confirmCheck, setConfirmCheck] = useState(false)

  const handleSuccess = ({ message = null }) => {
    setIsLoading(false)
    setResponse({
      ...response,
      successMessage: message ? message : 'O processo de troca foi iniciado.',
    })
    props.loadExchanges()
    props.loadHistoryTransactions()
    props.loadCurrentTransactions()
  }

  const handleClose = () => {
    props.handleCloseDialog()
    setTimeout(() => {
      setActiveStep(0)
      setConfirmCheck(false)
      setResponse({ successMessage: '', failedMessage: '' })
    }, 500)
  }

  const getStep = (step) => {
    switch (step) {
      case !isNaN(+step):
        return step
      case 'next':
        return activeStep >= Object.keys(getStepsContent()).length
          ? 0
          : activeStep + 1
      case 'back':
        return activeStep > 0 ? activeStep - 1 : 0
      default:
        return 0
    }
  }
  const handleChangeStep = (step) => {
    setActiveStep(getStep(step))
  }

  const handleConfirm = () => {
    const data = { ...props.exchange }
    setIsLoading(true)
    axios
      .post(`${BASE_API_URL}transactions/${props.buyerId}/`, data, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        handleSuccess({
          message: res.data.msg
            ? res.data.msg
            : !data.fee && 'Troca realizada com sucesso!',
        })
      })
      .catch((error) =>
        setResponse({ ...response, failedMessage: error.response.data.msg })
      )
      .finally(() => setIsLoading(false))
  }

  const getStepsContent = () => {
    let content = {
      0: 'A reserva não foi carregada corretamente, entre em contacto conosco.',
    }
    if (exchange)
      content = {
        0: (
          <>
            <div className='card__media-wrapper card__media-wrapper--wide'>
              <Media
                src={`${exchange.booking.property_type.get_featured_img}`}
                alt={exchange.booking.property_type.name}
              />

              <div className='card__body'>
                <h1 className='card__title'>
                  {exchange.booking.property_type.name}
                </h1>
                <BookingDates
                  checkin={exchange.booking.checkin}
                  checkout={exchange.booking.checkout}
                />
              </div>
            </div>
            <div className='card__body'>
              <div className='grid__container grid__container--column spacing-24'>
                {rule.buyer_booking && (
                  <div className='grid__item'>
                    <p className='card__text text-mutted'>
                      Trocar pela minha reserva:
                    </p>
                    <h1 className='card__title' style={{ marginBottom: 5 }}>
                      {rule.buyer_booking.property_type.name}
                    </h1>
                    <BookingDates
                      checkin={rule.buyer_booking.checkin}
                      checkout={rule.buyer_booking.checkout}
                    />
                  </div>
                )}
                {rule.fee && (
                  <div className='grid__item'>
                    {rule.buyer_booking && (
                      <p className='card__text text-mutted'>+ valor:</p>
                    )}
                    {!rule.buyer_booking && (
                      <p className='card__text text-mutted'>Alugar por</p>
                    )}
                    <Chip
                      variant='outlined'
                      style={{ fontWeight: 'bold', fontSize: '16px' }}
                      avatar={<EuroCoin />}
                      label={currencyFormatDE(rule.fee, false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ),
        1: rule.fee && (
          <div className='dialog__body'>
            <p>
              Ao confirmar a transação, terá até 24h para efetuar a
              transferência bancária para o IBAN abaixo.
            </p>

            <p>
              Assim que o titular da reserva receber o valor, ser-lhe-á
              confirmada a transação.
            </p>
            <p>
              Caso a transação não seja confirmada em 72h entre em contacto
              conosco através do email office@pontagrande.com.
            </p>
            <label className='overline'>Valor</label>
            <p className='card__title'>{currencyFormatDE(rule.fee)}</p>
            <label className='overline'>IBAN</label>
            <p className='card__title'>{exchange.iban}</p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmCheck}
                  onChange={() => setConfirmCheck(!confirmCheck)}
                  value={confirmCheck}
                  color='primary'
                />
              }
              label=' Confirmo que farei a transferência ou depósito em até 24
              horas'
            />
          </div>
        ),
      }
    if (rule && !rule.fee) {
      content = { 0: content[0] }
    }
    return content
  }

  return (
    <ResponsiveDialog
      open={open}
      handleCloseDialog={handleClose}
      dialogTitle='Transação'
      dialogSubTitle='Confirmação de Troca / Aluguer'
      failedMessage={response.failedMessage}
      successMessage={response.successMessage}
      isLoading={isLoading}
      actions={
        <>
          <button
            className={`btn btn--primary outlined action-btn`}
            onClick={() =>
              activeStep > 0 ? handleChangeStep('back') : handleClose()
            }
          >
            {rule ? (activeStep > 0 ? 'Voltar' : 'Cancelar') : 'Fechar'}
          </button>
          {rule &&
            (activeStep < Object.keys(getStepsContent()).length - 1 ? (
              <button
                className={`btn btn--primary raised action-btn`}
                onClick={() => handleChangeStep('next')}
              >
                Continuar
              </button>
            ) : (
              <button
                className={`btn btn--primary raised action-btn ${
                  rule
                    ? rule.fee && confirmCheck !== true
                      ? 'disabled'
                      : ''
                    : ''
                }`}
                onClick={handleConfirm}
              >
                Confirmar
              </button>
            ))}
        </>
      }
    >
      {rule ? (
        <SwipeableViews
          axis={'x'}
          index={activeStep}
          disabled={true}
          style={{ width: '100%' }}
        >
          {Object.values(getStepsContent()).map((step, index) => (
            <div key={index}>{step}</div>
          ))}
        </SwipeableViews>
      ) : (
        !isLoading && (
          <p className='--t-alert'>Essa reserva não está mais disponível</p>
        )
      )}
    </ResponsiveDialog>
  )
}
const mapStateToProps = (state) => ({
  buyerId: state.members?.member?.customer_id,
})
const mapDispatchToProps = (dispatch) => ({
  loadExchanges: () => dispatch(loadExchanges()),
  loadHistoryTransactions: () => dispatch(loadHistoryTransactions()),
  loadCurrentTransactions: () => dispatch(loadCurrentTransactions()),
})
export default connect(mapStateToProps, mapDispatchToProps)(BuyDialog)
