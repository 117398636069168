import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Preloader } from '../utils/Preloader';
import { BASE_API_URL } from './urls';
import { createPayment } from './actions/paymentsActions';
import { loadInvoices } from '../Invoices/actions/invoicesActions';

const StripePayment = (props) => {
  const { invoice } = props;
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (!stripePromise) {
      const supplier = invoice?.supplier?.ifthenpay_user;
      setStripePromise(loadStripe(process.env[`REACT_APP_STRIPE_PK_${supplier.toUpperCase()}`]));
    }
    return () => {
      if (stripePromise && !invoice && stripePromise) {
        setStripePromise(null);
      }
    };
  }, [invoice, stripePromise]);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

const CheckoutForm = (props) => {
  const { createPayment, loadInvoices, handlePaymentConfirm, invoice } = props;

  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  let [payload, setPayload] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const getClientSecret = useCallback(() => {
    if (!clientSecret && invoice)
      axios
        .post(`${BASE_API_URL}payments/create-payment-intent/`, {
          amount: invoice.balance_to_pay,
          invoice: invoice,
          id: invoice.id,
        })
        .then((res) => {
          if (res.data.clientSecret) setClientSecret(res.data.clientSecret);
          else setErrorMsg(res.data.error);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [invoice, clientSecret]);

  useEffect(() => {
    getClientSecret();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) return;

    setIsLoading(true);
    payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    setPayload({ ...payload });
    setIsLoading(false);
  };

  useEffect(() => {
    if (payload && !payload.error) {
      // Don't store the transaction_id as payload.id bc the payload.id is the payment intent ID
      const paymentData = {
        invoice: invoice.id,
        amount: invoice.balance_to_pay,
        status: 'confirmed',
        method: 'stripe',
        confirmed_by: 'system',
      };
      createPayment(paymentData);
      handlePaymentConfirm();
      loadInvoices();
    }
  }, [payload, invoice, createPayment, loadInvoices, handlePaymentConfirm]);

  return (
    <form onSubmit={handleSubmit} className="grid__container grid__container--column spacing-16 pt-16">
      {errorMsg ? (
        <div className="grid__item">
          <span className="helper-text error">{errorMsg}</span>
        </div>
      ) : (
        <Fragment>
          <div className="grid__item">
            <div className="form-control">
              <div className="field-wrapper Stripe-field-wrapper">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
              <span className="helper-text error">{payload?.error?.message}</span>
            </div>
          </div>
          <div className="grid__item">
            {isLoading ? (
              <Preloader />
            ) : (
              <button type="submit" className="btn btn--primary raised" disabled={!stripe}>
                Pagar
              </button>
            )}
          </div>
        </Fragment>
      )}
    </form>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadInvoices: () => dispatch(loadInvoices()),
    createPayment: (payment_data) => dispatch(createPayment(payment_data)),
  };
};
export default connect(null, mapDispatchToProps)(StripePayment);
