import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';

import ResponsiveDialog from '../utils/ResponsiveDialog';
import { Invoice } from '../Invoices/types';
import { ValueOf } from '../../@types';

import PaypalButton from './PaypalButton';
import PaymentMethodStep from './PaymentMethodStep';
import PaymentSelectMethod from './PaymentSelectMethod';
import StripePayment from './StripePayment';
import { CardMethods, CardMethodType, PaymentMethods, PAYMENT_METHODS, PaymentMethodType } from './PaymentMethods';
import PaymentDialogHeader from './PaymentDialogHeader';
import { createPayment } from './actions/paymentsActions';

const stepsLabels = ['Selecione a forma de pagamento', 'Confirmação', 'Dados para Multibanco ou Transferência'];

enum Steps {
  methodSelection = 0,
  cardSelected = 1,
  manualSelected = 2,
}

type GetStepContentProps = {
  step: ValueOf<typeof Steps>;
  invoice: Invoice;
  paymentMethod: PaymentMethodType;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethodType>>;
  handleNext: () => void;
  handlePaymentConfirm: () => void;
};

function getStepContent({
  step,
  invoice,
  paymentMethod,
  setPaymentMethod,
  handleNext,
  handlePaymentConfirm,
}: GetStepContentProps) {
  switch (step) {
    case Steps.methodSelection: {
      return (
        <PaymentSelectMethod
          setPaymentMethod={setPaymentMethod}
          handleNext={handleNext}
          paymentMethods={PAYMENT_METHODS}
        />
      );
    }
    case Steps.cardSelected: {
      const cardsButtons = {
        [PaymentMethods.paypal]: (
          <PaypalButton commit={true} invoice={invoice} handlePaymentConfirm={handlePaymentConfirm} />
        ),
        [PaymentMethods.stripe]: <StripePayment handlePaymentConfirm={handlePaymentConfirm} invoice={invoice} />,
      };

      return (
        <PaymentMethodStep
          method_name={PAYMENT_METHODS[paymentMethod]['title']}
          processing_time={PAYMENT_METHODS[paymentMethod]['processingTime']}
          image={PAYMENT_METHODS[paymentMethod]['icon']}
        >
          {cardsButtons[paymentMethod as CardMethodType]}
        </PaymentMethodStep>
      );
    }
    case Steps.manualSelected:
      // If PayPal method, it will end before getting into this step
      switch (paymentMethod) {
        case PaymentMethods.multibanco:
          return (
            <div className="grid__container grid__container--column spacing-16">
              <div className="grid__item">
                <label className="overline">Entidade</label>
                <p className="card__text big">
                  <b>{invoice.supplier.ifthenpay_entity}</b>
                </p>
              </div>
              <div className="grid__item">
                <label className="overline">Referência</label>
                <p className="card__text big">
                  <b>{invoice.multibanco_ref}</b>
                </p>
              </div>
            </div>
          );
        case PaymentMethods.bankTransfer:
          return (
            <div className="grid__container grid__container--column spacing-16">
              <div className="grid__item">
                <p>
                  <b className="--t-alert">IMPORTANTE</b>: Identifique sua transferência utilizando seu número de
                  contrato.
                </p>
              </div>
              <div className="grid__item">
                <label className="overline">IBAN</label>
                <p className="card__text big">
                  <b>{invoice.supplier.nib}</b>
                </p>
              </div>
              <div className="grid__item">
                <label className="overline">SWIFT</label>
                <p className="card__text big">
                  <b>{invoice.supplier.swift}</b>
                </p>
              </div>
            </div>
          );

        default:
          return null;
      }
    default:
      return null;
  }
}

type PaymentDialogProps = {
  open: boolean;
  invoice: Invoice | null;
  handlePaymentClose: () => void;
  extraSuccessMessage?: string; // Message coming from booking
};

function PaymentDialog({
  invoice,
  handlePaymentClose,
  open,
  extraSuccessMessage = '',
}: PaymentDialogProps): React.ReactElement {
  const [successMessage, setSuccessMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(PaymentMethods.stripe);

  const dispatch = useDispatch();

  function handleClose() {
    setSuccessMessage('');
    handlePaymentClose();
  }

  function handlePaymentConfirm() {
    setSuccessMessage(`Pagamento concluído com sucesso! ${extraSuccessMessage}`.trim());
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }
  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function payInvoiceManually() {
    if (invoice) {
      const payment_data = {
        invoice: invoice.id,
        amount: invoice.balance_to_pay,
        status: 'processing',
        method: paymentMethod,
        created_by: 'system',
      };
      if (paymentMethod === PaymentMethods.multibanco)
        Object.assign(payment_data, {
          transaction_reference: invoice.multibanco_ref,
          transaction_id: invoice.id,
        });

      dispatch(createPayment(payment_data));
      handleNext();
    }
  }

  const getActionBtns = (activeStep: number, paymentMethod: PaymentMethodType) => {
    switch (activeStep) {
      case 1: {
        return (
          <Fragment>
            <button className="btn btn--primary outlined action-btn mr-auto" onClick={handleBack}>
              Voltar
            </button>
            {!CardMethods.hasOwnProperty(paymentMethod) && (
              <button
                className="btn btn--primary raised action-btn ml-auto"
                onClick={() => {
                  payInvoiceManually();
                  handleNext();
                }}
              >
                Confirmar
              </button>
            )}
          </Fragment>
        );
      }
      case 2: {
        return (
          <button className="btn btn--primary raised action-btn ml-auto" onClick={handleClose}>
            Fechar
          </button>
        );
      }
      default:
        return null;
    }
  };
  return (
    <ResponsiveDialog
      open={open}
      handleCloseDialog={handleClose}
      title="Pagamento"
      successMessage={successMessage}
      actions={getActionBtns(activeStep, paymentMethod)}
      actionsClass={'--space-between'}
    >
      {invoice && (
        <div className="grid__container grid__container--column spacing-16">
          <div className="grid__item">
            <PaymentDialogHeader invoice={invoice} />
          </div>
          <div className="grid__item">
            <>
              <Stepper activeStep={activeStep} orientation="vertical">
                {stepsLabels.map((label, index) => {
                  return (
                    <Step key={index}>
                      <StepLabel className="card__text">{label}</StepLabel>
                      <StepContent>
                        <div>
                          {getStepContent({
                            step: index,
                            invoice: invoice,
                            paymentMethod,
                            setPaymentMethod,
                            handleNext,
                            handlePaymentConfirm,
                          })}
                        </div>
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </>
          </div>
        </div>
      )}
    </ResponsiveDialog>
  );
}

export default PaymentDialog;
