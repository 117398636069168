import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Media from '../utils/Media';
import ContractHeader from '../Contracts/ContractHeader';
import { getSiteConfigSelector } from '../../selectors';
import { getContractsSelector } from '../Contracts/selectors';

import { Booking } from './types';
import { HandleOpenDialogProps } from './Bookings';
import BookingDates from './BookingDates';
import ExchangeBankLink from './ExchangeBankLink';
import BookingMenu from './BookingMenu';

type BookingCardProps = {
  booking: Booking;
  handleOpenDialog: (data: HandleOpenDialogProps) => void;
};
export function BookingCard({ booking, handleOpenDialog }: BookingCardProps): React.ReactElement {
  const { data: siteConfig } = useSelector(getSiteConfigSelector);
  const { items: contracts } = useSelector(getContractsSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isBookingFromExchange = !Boolean(contracts.find((contract) => booking.contract === contract.composed_number));

  return (
    <div className="card" style={{ height: '100%' }}>
      <div className="card__section card__media-wrapper card__media-wrapper--wide">
        <Media src={`${booking.property_type.get_featured_img}`} alt={booking.property_type.name} />
        <label className={`chip chip--status ${booking.status}`} style={{ position: 'absolute', top: 8, right: 8 }}>
          {booking.status_display}
        </label>

        <div className="card__body">
          <h1 className="card__title">{booking.property_type.name}</h1>
          <p className="smaller" style={{ whiteSpace: 'pre-wrap' }}>
            {booking.property_type.resort.address}
          </p>
        </div>
      </div>
      <div className="card__body">
        <div className="grid__container grid__container--column spacing-16 --align-center">
          <div className="grid__item">
            <ContractHeader contract={booking.contract} isBookingFromExchange={isBookingFromExchange} />
          </div>
          <div className="grid__item">
            <BookingDates checkin={booking.checkin} checkout={booking.checkout} />
          </div>
        </div>
      </div>
      <div className="card__action-btns --justify-center">
        {(booking.exchange || booking.in_transaction) && <ExchangeBankLink booking={booking} />}
        {siteConfig.site.domain === 'membros.pontagrande.com' &&
          moment(booking.checkin) > moment() &&
          !booking.exchange &&
          !booking.in_transaction &&
          booking.status === 'confirmed' && (
            <Fragment>
              <button
                type="submit"
                className={`btn btn--primary outlined action-btn`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                Depositar
              </button>

              <BookingMenu
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                booking={booking}
                handleOpenDialog={handleOpenDialog}
                isBookingFromExchange={isBookingFromExchange}
              />
            </Fragment>
          )}
      </div>
    </div>
  );
}

export default BookingCard;
