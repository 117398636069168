import React from 'react'
import {
  MailOutlined as MailIcon,
  LockOutlined as PassIcon,
} from '@material-ui/icons'
import Input from '../../components/utils/Input'
import FormBase from './FormBase'

const CreateUserForm = ({
  email,
  password,
  password_confirm,
  errors,
  handleSubmit,
  handleChange,
  handleBack,
}) => {
  return (
    <FormBase handleSubmit={handleSubmit} handleBack={handleBack}>
      <div className="grid__container grid__container--column spacing-24">
        <div className="grid__item">
          <Input
            name="email"
            type="email"
            label="Manter/Novo email"
            value={email}
            handleChange={handleChange}
            errors={errors}
            icon={<MailIcon />}
            required
          />
        </div>
        <div className="grid__item">
          <Input
            name="password"
            type="password"
            label="Nova senha"
            value={password}
            handleChange={handleChange}
            errors={errors}
            icon={<PassIcon />}
            required
            autoFocus={true}
          />
        </div>
        <div className="grid__item">
          <Input
            name="password_confirm"
            type="password"
            label="Confirme a nova senha"
            value={password_confirm}
            handleChange={handleChange}
            errors={errors}
            icon={<PassIcon />}
            required
          />
        </div>
      </div>
    </FormBase>
  )
}

export default CreateUserForm
