import React, { useEffect, useRef } from 'react'
import { getStrengthLevel, getLocale } from './utils'
import { TIPS_TITLE } from './config'
import './styles.scss'

const PassStrengthBar = ({ value, minLength = 8, showTips = true, locale }) => {
  locale = getLocale(locale)
  const [strengthLevel, tips, maxLevelAchieved] = getStrengthLevel(
    value,
    minLength,
    locale
  )
  const mainRef = useRef(null)
  const tipsRef = useRef(null)
  useEffect(() => {
    const mainEl = mainRef.current
    const containerEl = tipsRef.current

    if (mainEl && containerEl) {
      const closed = '0px'
      switch (true) {
        case value && !maxLevelAchieved:
          mainEl.classList.add('showing')
          mainEl.style.maxHeight =
            containerEl.scrollHeight + mainEl.scrollHeight + 'px'
          mainEl.style.visibility = 'visible'
          containerEl.style.maxHeight = mainEl.style.maxHeight
          containerEl.style.visibility = 'visible'
          break
        case value && maxLevelAchieved:
          containerEl.style.maxHeight = closed
          mainEl.classList.remove('showing')
          break
        default:
          mainEl.style.maxHeight = closed
          mainEl.classList.remove('showing')
          break
      }
    }
  }, [value, maxLevelAchieved])

  return (
    <div ref={mainRef} className={`pass-strength ${strengthLevel.className}`}>
      <div className="pass-strength__bar"></div>
      <p className="pass-strength__bar-description">
        {strengthLevel.text[locale]}
      </p>
      <Tips ref={tipsRef} tips={tips} showTips={showTips} locale={locale} />
    </div>
  )
}

export default PassStrengthBar

const Tips = React.forwardRef(({ tips, showTips, locale }, ref) => {
  if (!(showTips && tips)) return null
  return (
    <div ref={ref} className="pass-strength__tips-container">
      <p className="pass-strength__tips__title">{TIPS_TITLE[locale]}:</p>
      {tips}
    </div>
  )
})
