import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { default as serverReducer } from '../../components/utils/reducers/serverReducer';
import { default as siteConfigReducer } from '../../components/utils/reducers/siteConfigReducer';
import { default as communicationsReducer } from '../../components/Communications/reducers/communicationsReducer';
import { default as authReducer } from '../../components/Auth/reducers/authReducer';
import { default as resortsReducer } from '../../components/Resorts/reducers/resortsReducer';
import { default as contractsReducer } from '../../components/Contracts/reducers/contractsReducer';
import { default as invoicesReducer } from '../../components/Invoices/reducers/invoicesReducer';
import { default as paymentsReducer } from '../../components/Payments/reducers/paymentsReducers';
import { default as membersReducer } from '../../components/Members/reducers/membersReducer';
import { default as bookingsReducer } from '../../components/Bookings/reducers/bookingsReducer';
import { default as exchangesReducer } from '../../components/Exchanges/reducers/exchangeReducer';

const createRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    server: serverReducer,
    siteConfig: siteConfigReducer,
    communications: communicationsReducer,
    auth: authReducer,
    members: membersReducer,
    contracts: contractsReducer,
    resorts: resortsReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    bookings: bookingsReducer,
    exchanges: exchangesReducer,
  });
};

export default createRootReducer