import React from 'react';

import Exchanges from '../../../components/Exchanges/Exchanges';
import SectionHeader from '../SectionHeader';

export function ExchangesPage(): React.ReactElement {
  return (
    <div className="grid__container grid__container--column spacing-32">
      <div className="grid__item">
        <SectionHeader icon="compare_arrows" title="Banco de Trocas">
          <p className="section__subtitle">
            O Banco de Trocas foi criado para permitir aumentar a flexibilidade da escolha do seu período de férias.
            <br />O Grupo Ponta Grande não tem quaisquer benefícios financeiros nas transações efetuadas.
          </p>
        </SectionHeader>
      </div>
      <div className="grid__item">
        <Exchanges />
      </div>
    </div>
  );
}

export default ExchangesPage;
