import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './redux/reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

export const history = createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)];

const composeEnhancers = composeWithDevTools({});

export default function configureStore(initialState = {}) {
  return createStore(createRootReducer(history), initialState, composeEnhancers(applyMiddleware(...middleware)));
}
