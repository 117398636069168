import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Receipt } from '@material-ui/icons'

import { Preloader } from '../utils/Preloader'
import InvoicePayments from './InvoicePayments'
import { currencyFormatDE } from '../utils/currency'
import BookingDates from '../Bookings/BookingDates'

const Invoice = (props) => {
  const { invoice, invoices, handlePayClick } = props

  return (
    <div className="panel">
      <div className="panel__header">
        <div className="icon text-mutted">
          <Receipt />
        </div>
        <h1 className="panel__title">Fatura</h1>
      </div>
      <div className="panel__body">
        <div className="grid">
          <div className="grid__container grid__container--column spacing-16 --justify-space-between">
            {invoice.contract && (
              <div className="grid__item">
                <label className="overline">Contrato</label>
                <p className="card__title">
                  {invoice.contract.composed_number}
                </p>
              </div>
            )}
            <div className="grid__item">
              <label className="overline">Referencia</label>
              <p className="card__text">{invoice.reference}</p>
            </div>
            <div className="grid__item">
              <label className="overline">Ano</label>
              <p className="card__text">{invoice.year_reference}</p>
            </div>
            {invoice.booking && (
              <div className="grid__item">
                <label className="overline">Reserva</label>
                <p className="card__text">
                  {invoice.booking.property_type.name}
                </p>

                <BookingDates
                  checkin={invoice.booking.checkin}
                  checkout={invoice.booking.checkout}
                />
              </div>
            )}

            <div className="grid__item" style={{ alignContent: 'center' }}>
              {invoice.voucher && (
                <div
                  className="bg--super-light-gray flat"
                  style={{
                    border: '1px dashed rgba(0,0,0,0.2) ',
                    borderRadius: '5px',
                    padding: '16px',
                  }}
                >
                  <label className="overline">Desconto</label>
                  {invoice.voucher.title && invoice.voucher.display_title && (
                    <p className="--nomargin">
                      <b>{invoice.voucher.title}</b>
                    </p>
                  )}
                  {(invoice.voucher.value || invoice.voucher.pct) && (
                    <p className="subtitle_1 --t-success --nomargin">
                      {invoice.voucher.value && (
                        <b>€ {invoice.voucher.value}</b>
                      )}
                      {invoice.voucher.pct && <b>{invoice.voucher.pct}%</b>}
                    </p>
                  )}
                  {invoice.voucher.short_description && (
                    <p className="small card__text text-mutted">
                      {invoice.voucher.short_description}
                    </p>
                  )}
                  {(invoice.voucher.active_from ||
                    invoice.voucher.active_until) && (
                    <p className="small card__text text-mutted">
                      válido{' '}
                      {invoice.voucher.active_from && (
                        <span>
                          de{' '}
                          <b>
                            {moment(invoice.voucher.active_from).format(
                              'DD/MM/YYYY'
                            )}
                          </b>{' '}
                        </span>
                      )}
                      {invoice.voucher.active_until && (
                        <span>
                          até{' '}
                          <b>
                            {moment(invoice.voucher.active_until).format(
                              'DD/MM/YYYY'
                            )}
                          </b>
                        </span>
                      )}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="grid__item --ta-right">
              <label className="overline">Valor</label>

              {invoice.voucher && (
                <p className="small card__text --t-alert --nomargin">
                  antes{' '}
                  <strike>
                    {currencyFormatDE(invoice.balance_without_discount)}
                  </strike>
                </p>
              )}
              <p className="card__title --nomargin">
                {currencyFormatDE(invoice.balance_to_pay)}
              </p>
            </div>
            {invoice.status.slug !== 'processing' && (
              <div
                className="grid__item"
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {invoices.isPaying === true && (
                  <Preloader width={50} height={50} />
                )}
                <button
                  className={`btn btn--primary raised action-btn ${
                    invoices.isPaying || invoice.is_processing ? 'disabled' : ''
                  }`}
                  onClick={handlePayClick}
                >
                  Pagar
                </button>
              </div>
            )}

            {invoice.payments.length > 0 && (
              <div className="grid__item">
                <InvoicePayments
                  payments={Object.values(invoice.payments).filter(
                    (payment) => payment.method.is_internal !== true
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
  }
}

export default connect(mapStateToProps)(Invoice)
