const initialState = {
  isLoading: false,
  items: [],
  propertyTypes: [],
  errors: null,
}

const resortsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESORTS_LOADING':
      return { ...state, errors: null, isLoading: true }
    case 'RESORTS_LOADED':
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      }
    case 'PROPERTY_TYPES_LOADED':
      return {
        ...state,
        isLoading: false,
        propertyTypes: action.payload,
      }

    case 'FAILED':
      return {
        ...state,
        errors: action.errors,
        items: [],
        isLoading: false,
      }

    default:
      return state
  }
}
export default resortsReducer
