import axios from 'axios';
import { handleErrors } from '../helpers/errors';
import { setHeadersToken } from '../helpers/store';

export const loadSiteConfig = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'SITE_CONFIG_LOADING' });
    axios
      .get(`${window.location.origin}/site-config/`, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'SITE_CONFIG_LOADED', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'SITE_CONFIG_FAILED');
      });
  };
};

export const maintenanceCheck = (dispatchFallback = null) => {
  return (dispatch, getState) => {
    dispatch({ type: 'MAINTENANCE_CHECK_LOADING' });
    axios
      .get(`${window.location.origin}/site-config/maintenance-check`, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'MAINTENANCE_CHECK_LOADED', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'MAINTENANCE_CHECK_FAILED');
      });
  };
};
