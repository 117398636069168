import { Typography } from '@mui/material';
import React from 'react';
import { Error as ErrorType } from '../../@types';

export type ErrorProps = {
  error: ErrorType;
};
export function Error({ error }: ErrorProps): React.ReactElement {
  console.log(`${error.code}: ${error.text}`);

  return <Typography>Oops, algo deu errado.</Typography>;
}
