import React from 'react'
import AnimatedGridItem from '../../../components/utils/AnimatedGridItem'
import BookNow from '../../../components/Bookings/BookNow'
import UpcomingBooking from '../../../components/Bookings/UpcomingBooking'

const UpcomingBookings = ({ contractsAllowedToBook, upcomingBookings }) => {
  return (
    <AnimatedGridItem animate={false}>
      <div className="grid__container grid__container--column spacing-32">
        <div className="grid__item animated zoomIn faster">
          <div className="panel">
            {contractsAllowedToBook && <BookNow />}
            <div className="panel__header">
              <h1 className="panel__title">Próximas reservas</h1>
            </div>
            <div className="card__body">
              {upcomingBookings.length > 0 ? (
                <div
                  className="grid__container grid__container--column spacing-32"
                  style={{ overflow: 'hidden' }}
                >
                  {Object.values(upcomingBookings).map((booking, index) => (
                    <div
                      key={index}
                      className="grid__item list__item animated fadeInUp faster"
                      style={{
                        animationDelay: `${(index + 1) * 0.1}s`,
                      }}
                    >
                      <UpcomingBooking booking={booking} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-mutted">Não tem reservas agendadas.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </AnimatedGridItem>
  )
}

export default UpcomingBookings
