export type DeviceType = 'MOBILE' | 'TABLET' | 'DESKTOP';

export const deviceTypes = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const breakpoints = {
  TABLET: 625,
  DESKTOP: 971,
};

export const mediaQueries = {
  MOBILE: `(max-width: ${breakpoints.TABLET - 1}px)`,
  TABLET: `(min-width: ${breakpoints.TABLET}px)`,
  DESKTOP: `(min-width: ${breakpoints.DESKTOP}px)`,
};
