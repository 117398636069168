import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Navigate, Route, Outlet, useLocation } from 'react-router-dom';

import dashboardRoutes from '../../routes/dashboard';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Guides from '../../components/Communications/Guides/Guides';
import { loadUser } from '../../components/Auth/actions/authActions';
import { State } from '../../@types';
import { loadMember } from '../../components/Members/actions/membersActions';
import { getMemberSelector } from '../../components/Members/selectors';
import { getBookingsSelector } from '../../components/Bookings/selectors';
import { getContractsSelector } from '../../components/Contracts/selectors';
import { getInvoicesSelector } from '../../components/Invoices/selectors';
import { getExchangesSelector } from '../../components/Exchanges/selectors';
import { Preloader } from '../../components/utils/Preloader';

import Home from './Home';
import Member from './Member';
import Contracts from './Contracts';
import BookingsPage from './Bookings';
import ExchangesPage from './Exchanges';
import AlertSection from '../../components/Alerts/AlertSection';
import { loadInvoices } from '../../components/Invoices/actions/invoicesActions';
import { loadGuides } from '../../components/Communications/actions/communicationsActions';
import { loadResorts } from '../../components/Resorts/actions/resortsActions';
import { loadPartners } from '../../components/Bookings/actions/bookingsActions';

export function Dashboard(): React.ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const {
    auth: { user },
    siteConfig: { data: siteConfig },
  } = useSelector((state: State) => state);
  const { member, isLoading: isMemberLoading } = useSelector(getMemberSelector);
  const { isLoading: isBookingsLoading } = useSelector(getBookingsSelector);
  const { isLoading: isContractsLoading } = useSelector(getContractsSelector);
  const { items: invoices, isLoading: isInvoicesLoading } = useSelector(getInvoicesSelector);
  const { historyIsLoading: isHistoryTransactionsLoading } = useSelector(getExchangesSelector);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const invoicesCount = Object.keys(invoices).length;
  const userId = user?.id;
  const memberId = member?.customer_id;

  const isLoading =
    isMemberLoading || isBookingsLoading || isContractsLoading || isInvoicesLoading || isHistoryTransactionsLoading;

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch, userId]);

  useEffect(() => {
    if (memberId) {
      dispatch(loadGuides());
      dispatch(loadResorts());
      dispatch(loadPartners());
    }
  }, [dispatch, memberId, userId]);

  useEffect(() => {
    if (memberId) {
      dispatch(loadInvoices());
    }
  }, [dispatch, memberId, pathname]);

  useEffect(() => {
    if (!memberId) {
      dispatch(loadMember());
    }
  }, [dispatch, memberId]);

  if (!userId)
    return (
      <p>
        Falha ao carregar os dados de usuário. Por favor recarregue a página. Se o erro persistir entre em contato
        conosco.
      </p>
    );

  const { domain } = siteConfig.site;
  const isNotPG = domain !== 'membros.pontagrande.com';

  // @ts-ignore
  const routesComponents = [];
  const routes = dashboardRoutes.filter((route, index) => {
    if (isNotPG && route.path === '/banco-de-trocas') {
      return false;
    }

    const routeProps = {
      key: index,
      path: route.path,
      element: route.component && <route.component />,
    };

    if (route.redirect) {
      routesComponents.push(<Route key={index} path={route.path} element={<Navigate to={route.to} />} />);
    } else {
      routesComponents.push(<Route {...routeProps} />);
    }
    return true;
  });

  return (
    <Fragment>
      <Header handleToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
      <Sidebar
        routes={routes}
        sidebarOpen={isSidebarOpen}
        handleToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      <div className="dashboard-panel">
        {invoicesCount > 0 && (
          <div className="grid__item xs-12 animated zoomIn faster" style={{ animationDelay: `0.1s` }}>
            <AlertSection title="Atenção!">
              <p>Para poder aceder às nossas ofertas e fazer reservas é necessário regularizar os valores em aberto.</p>
            </AlertSection>
          </div>
        )}
        <div className="content">
          {isLoading && <Preloader overlay={true} />}
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/dados" element={<Member />} />
            <Route path="/contratos" element={<Contracts />} />
            <Route path="/reservas" element={<BookingsPage />} />
            <Route path="/banco-de-trocas" element={<ExchangesPage />} />
          </Routes>
        </div>
      </div>
      {siteConfig.site.domain === 'membros.pontagrande.com' && <Guides />}
      <Outlet />
    </Fragment>
  );
}

export default Dashboard;
