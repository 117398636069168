import React from 'react';
import { useSelector } from 'react-redux';

import { getSiteConfigSelector } from '../../selectors';

function Base({ children }: { children: React.ReactNode }): React.ReactElement {
  const siteConfigAPI = useSelector(getSiteConfigSelector);
  const { data: siteConfig } = siteConfigAPI;

  return (
    <div className="login-page">
      <div className="card login__box">
        <div className="card__body">
          <div className="grid__container grid__container--column spacing-40 --justify-center">
            <div className="grid__item --ta-center">
              <img
                src={siteConfig.logo}
                alt={siteConfig.title}
                style={{
                  maxHeight: 100,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: 150,
                }}
              />
            </div>
            <div className="grid__item">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Base;
