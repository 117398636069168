import React from 'react';
import { IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@material-ui/icons';

import { UserButton } from './UserButton';
import { useDevice } from '../../hooks/useDevice';
import { deviceTypes } from '../../constants/deviceTypes';

type HeaderNavProps = {
  handleToggleSidebar: () => React.SetStateAction<boolean>;
};

export function HeaderNav({ handleToggleSidebar }: HeaderNavProps): React.ReactElement {
  const deviceType = useDevice();
  const isDesktop = deviceType === deviceTypes.DESKTOP;
  return (
    <div className="headerNav">
      <UserButton />
      {!isDesktop && (
        <IconButton color="inherit" aria-label="open drawer" onClick={handleToggleSidebar}>
          <MenuIcon />
        </IconButton>
      )}
    </div>
  );
}

export default HeaderNav;
