export function highlight(
  str: string,
  term: string,
  htmlTag: 'b' | 'i' | 'span' = 'b',
  cssClass: string = '--t-primary'
) {
  const terms = term.split(' ');
  let htmlString = str;
  terms.forEach((term) => {
    const regex = new RegExp(term.replace(/"/gi, ''), 'gi');
    const match = str.match(regex)?.join('');
    if (match) {
      htmlString = htmlString.replace(match, `<${htmlTag} class="${cssClass}">${match}</${htmlTag}>`);
    }
  });
  return <span dangerouslySetInnerHTML={{ __html: htmlString }}></span>;
}
