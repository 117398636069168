const initialState = {
  isLoading: false,
  guides: [],
  errors: null,
}

const communicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMMUNICATIONS_LOADING':
      return { ...state, errors: null, isLoading: true }

    case 'COMMUNICATIONS_LOADED_GUIDES':
      return {
        ...state,
        errors: null,
        isLoading: false,
        guides: action.payload,
      }

    case 'COMMUNICATIONS_FAILED':
      return {
        ...JSON.parse(JSON.stringify(initialState)),
        errors: action.errors,
      }

    default:
      return state
  }
}
export default communicationsReducer
