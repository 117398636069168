import { useLayoutEffect, useState, useCallback } from 'react';

import { DeviceType, deviceTypes, mediaQueries } from '../constants/deviceTypes';

function addMediaQueryListener<K extends keyof MediaQueryListEventMap>(
  event: K,
  mql: MediaQueryList,
  handler: ({ matches, media }: MediaQueryListEvent) => void
): void {
  if (mql.addEventListener) {
    mql.addEventListener(event, handler);
  } else if (mql.addListener) {
    mql.addListener(handler);
  }
}

function removeMediaQueryListener<K extends keyof MediaQueryListEventMap>(
  event: K,
  mql: MediaQueryList,
  handler: ({ matches, media }: MediaQueryListEvent) => void
): void {
  if (mql.removeEventListener) {
    mql.removeEventListener(event, handler);
  } else if (mql.removeListener) {
    mql.removeListener(handler);
  }
}

export function useDevice(): DeviceType {
  const [deviceType, setDeviceType] = useState(deviceTypes.MOBILE);

  const handleMatch = useCallback(({ matches, media }: MediaQueryListEvent | MediaQueryList): void => {
    if (!matches) {
      return;
    }
    const currentType = Object.keys(mediaQueries).find((key) => mediaQueries[key as DeviceType] === media);
    if (!currentType) {
      return;
    }
    setDeviceType(currentType);
  }, []);

  useLayoutEffect(() => {
    const mediaQueriesList: MediaQueryList[] = [];

    Object.values(mediaQueries).forEach((media) => {
      const mql = window.matchMedia(media);

      // Get initial type
      handleMatch(mql);

      // Add listeners
      addMediaQueryListener('change', mql, handleMatch);

      // Populate the mediaQueriesList for the removal
      mediaQueriesList.push(mql);
    });
    return (): void => {
      // Remove listeners
      mediaQueriesList.forEach((mql) => {
        removeMediaQueryListener('change', mql, handleMatch);
      });
    };
  }, []); // eslint-disable-line

  return deviceType as DeviceType;
}
