import { store } from '../../..';
import { getAuthToken } from '../../Auth/selectors';

/*
Used in all API calls to prevent CSRF blocks
Access the store state to get auth token and return headers['Authorization'] 
*/
export function setHeadersToken() {
  const state = store.getState();
  const token = state.auth?.token || getAuthToken();
  if (token) {
    return { Authorization: `Token ${token}` };
  }
  return null;
}
