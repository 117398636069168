import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline, Snackbar } from '@material-ui/core';

import indexRoutes from './routes/index.jsx';
import PrivateRoutes from './routes/PrivateRoutes';
import { green, red } from '@material-ui/core/colors';
import { Warning as WarningIcon, WifiOff as OfflineIcon } from '@material-ui/icons';
import { setInternetConnection, checkServer } from './components/utils/actions/serverActions';
import { loadSiteConfig } from './components/utils/actions/siteConfigActions';
import Maintenance from './maintenance';
import { State } from './@types';

const theme = createTheme({
  // typography: {
  //   useNextVariants: true,
  // },
  palette: {
    primary: {
      main: '#2F80ED',
      contrastText: '#fff',
    },
    success: green,
    error: red,
    // danger: {
    //   color: '#fff',
    //   backgroundColor: red.dark,
    // },
  },
});

export function App(): React.ReactElement {
  const { error } = useSelector((state: State) => state.server);

  const dispatch = useDispatch();

  // Verify server each 2s
  useEffect(() => {
    let timer: number | null;
    if (error) {
      timer = window.setInterval(() => {
        dispatch(checkServer());
      }, 2000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [dispatch, error]);

  useEffect(() => {
    // Listen to internet connection
    window.onoffline = () => dispatch(setInternetConnection('OFFLINE'));
    window.ononline = () => dispatch(setInternetConnection('ONLINE'));

    dispatch(loadSiteConfig());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{
          backgroundImage: `url('./assets/img/bg_quad.jpg')`,
        }}
      >
        <Maintenance>
          <Routes>
            {indexRoutes.map((prop, index) => {
              const componentProps = {
                path: prop.path,
                element: <prop.component />,
              };
              return prop.private ? (
                <Route key={index} element={<PrivateRoutes />}>
                  <Route {...componentProps} />
                </Route>
              ) : (
                <Route key={index} {...componentProps} />
              );
            })}
          </Routes>
        </Maintenance>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(error)}
          ContentProps={{
            className: 'alert--danger',
            'aria-describedby': 'message-id',
          }}
          message={
            error && (
              <span id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>{error.code === 'off' ? <OfflineIcon /> : <WarningIcon />}</span>
                {error.text}
              </span>
            )
          }
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
