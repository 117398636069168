import React from 'react'

import { currencyFormatDE } from '../utils/currency'

const PaymentListItem = ({ payment }) => {
  return (
    <div className="grid__container spacing-8">
      <div className="grid__item xs-6" style={{ fontSize: '90%' }}>
        <label className="overline">Pagamento via</label>
        <p className="card__text --nomargin">{payment.method.name}</p>
        {payment.method.slug === 'multibanco' && (
          <div>
            <p className="card__text --nomargin">
              Ent. <b>{payment.invoice.supplier.ifthenpay_entity}</b>
            </p>
            <p className="card__text">
              Ref. <b>{payment.transaction_reference}</b>
            </p>
          </div>
        )}
      </div>
      <div
        className="grid__item xs-6"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize: '80%',
        }}
      >
        <label
          className={`chip chip--status chip--${
            payment.status.slug === 'processing'
              ? 'info'
              : payment.status.slug === 'confirmed'
              ? 'success'
              : 'alert'
          }`}
        >
          {payment.status.name}
        </label>
      </div>
      <div className="grid__item xs-8" style={{ fontSize: '80%' }}>
        <label className="overline">Efetuado em</label>
        <p className="card__text">{payment.created_at}</p>
      </div>
      <div className="grid__item xs-4 --ta-right" style={{ fontSize: '90%' }}>
        <label className="overline">Valor</label>
        <p className="card__text">
          <b>{currencyFormatDE(payment.amount)}</b>
        </p>
      </div>
    </div>
  )
}

export default PaymentListItem
