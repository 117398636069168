import styled from 'styled-components';

export const AlertWrapper = styled.section`
  background-color: #fff8df;
  padding: 1rem;
  border: 1px solid #ebdcb1;
  color: #c3a03c;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 2rem;
`;

export const AlertHeader = styled.header`
  display: flex;
  flex-direction: row;
`;

export const AlertHeaderIcon = styled.span`
  padding-right: 0.5rem;
`;
export const AlertHeaderTitle = styled.h2`
  font-size: 1.125rem;
  margin: 0;
`;

export const AlertContainer = styled.div`
  margin-top: 0.5rem;
  font-weight: 600;
`;
