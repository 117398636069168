import React from 'react'
import { Link } from 'react-router-dom'

const AuthStepPassRecoverySent = () => {
  return (
    <>
      <div className="grid__container grid__container--column --align-center spacing-16">
        <div className="grid__item">
          <p>
            Enviámos para o seu email, instruções para redefinir a sua senha.
          </p>
        </div>
        <div className="grid__item">
          <p>
            Caso não encontre o email na Caixa de Entrada verifique as caixas de
            SPAM e Lixo Eletrônico.
          </p>
        </div>
      </div>
      <div className="stepper__footer-actions --justify-center">
        <Link to="/" className="btn btn--primary raised">
          Tentar novamente
        </Link>
      </div>
    </>
  )
}

export default AuthStepPassRecoverySent
