import React from 'react'

import { Chip } from '@material-ui/core'

import Media from '../utils/Media'

import BookingDates from '../Bookings/BookingDates'
import BookingData from '../Bookings/BookingData'
import { currencyFormatDE } from '../utils/currency'
import EuroCoin from '../utils/EuroCoin'

const ExchangeCard = ({ handleOpenDialog, exchange }) => {
  const rule = exchange,
    ruleExchange = rule.exchange

  return (
    <div className="card">
      <div className="card__section card__media-wrapper card__media-wrapper--wide">
        <Media
          src={`${ruleExchange.booking.property_type.get_featured_img}`}
          alt={ruleExchange.booking.property_type.name}
        />

        <div className="card__body">
          <h1 className="card__title">
            {ruleExchange.booking.property_type.name}
          </h1>
        </div>
      </div>
      <div className="card__body pt-0-force">
        <div className="grid__container grid__container--column spacing-24">
          <div className="grid__item">
            <BookingDates
              checkin={ruleExchange.booking.checkin}
              checkout={ruleExchange.booking.checkout}
            />
          </div>
          {rule.buyer_booking && (
            <div className="grid__item">
              <p className="card__text text-mutted">
                Trocar pela minha reserva:
              </p>
              <BookingData booking={rule.buyer_booking} />
            </div>
          )}
          {rule.fee && (
            <div className="grid__item">
              {rule.buyer_booking && (
                <p className="card__text text-mutted">+ valor:</p>
              )}
              {!rule.buyer_booking && (
                <p className="card__text text-mutted">Alugar por</p>
              )}
              <Chip
                variant="outlined"
                style={{ fontWeight: 'bold' }}
                avatar={<EuroCoin />}
                label={currencyFormatDE(rule.fee, false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="card__action-btns --justify-center">
        <button
          className={`btn btn--primary raised action-btn`}
          onClick={handleOpenDialog}
        >
          {!rule.buyer_booking ? 'Alugar' : 'Trocar'}
        </button>
      </div>
    </div>
  )
}

export default ExchangeCard
