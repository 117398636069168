import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';
import 'moment/locale/pt';
import Calendar from 'react-calendar';
import axios from 'axios';

import ResponsiveDialog from '../utils/ResponsiveDialog';
import { Preloader } from '../utils/Preloader';
import { loadContracts } from '../Contracts/actions/contractsActions';
import { muteHelper, handleErrorMessage } from '../utils/functions';
import { setHeadersToken } from '../utils/helpers/store';
import { getRange } from '../utils/helpers/dates';
import { getForbiddenDates, getPeriodsGranted } from '../Contracts/utils';
import PaymentDialog from '../Payments/PaymentDialog';
import { Contract } from '../Contracts/types';
import { Invoice } from '../Invoices/types';
import { useDevice } from '../../hooks/useDevice';
import { deviceTypes } from '../../constants/deviceTypes';
import { PropertyType } from '../Resorts/types';

import { loadBookings } from './actions/bookingsActions';
import { BASE_API_URL } from './urls';
import { BASE_API_URL as INVOICE_BASE_API_URL } from '../Invoices/urls';
import { getMembership } from './utils';
import Vacancies from './Vacancies';
import BookingDates from './BookingDates';
import ContractAllowedPeriod from './ContractAllowedPeriod';

const DATE_FORMAT = 'YYYY-MM-DD';

moment.updateLocale('pt', {
  week: {
    dow: 1,
    doy: 7,
  },
});

type DateType = Date | Moment;
type WeekRange = [number, number];
type Range = {
  from: Date;
  to: Date;
  days?: Date[];
};
// type Grant = WeekRange[]
// type Deny = WeekRange[]
// type GrantDates = MomentDate[]
// type DenyDates = MomentDate[]

type PeriodGranted = {
  year: number;
  ranges: WeekRange[];
};

type Vacancy = {
  [key: string]: PropertyType;
};

type IniState = {
  availableDays: number[]; // week days based on availabilities
  periodsGranted: PeriodGranted[]; // Block according period from contract
  forbiddenDates: DateType[]; // Block according period from contract
  range: Range | null;
  roomSelected: number | null;
  failedMessage: string | null;
  successMessage: string | null;
  tryAgain: boolean;
  partnerSelected: null;
  isLoading: boolean;
  isBooking: boolean;
  min_date: DateType;
  max_date: DateType;
  years_available: number[];
  activeYear: number;
  invoice: Invoice | null;
  invoicePaid: boolean;
  startDate: DateType;
  bookings: {
    vacancies: Vacancy[] | null;
    isLoading: boolean;
  };
};

const iniState: IniState = {
  availableDays: [], // week days based on availabilities
  periodsGranted: [], // Block according period from contract
  forbiddenDates: [], // Block according period from contract
  range: null,
  roomSelected: null,
  failedMessage: null,
  successMessage: null,
  tryAgain: false,
  partnerSelected: null,
  isLoading: false,
  isBooking: false,
  min_date: moment(),
  max_date: moment().endOf('year'),
  years_available: [moment().year()],
  activeYear: moment().year(),
  invoice: null,
  invoicePaid: false,
  startDate: moment(),
  bookings: {
    vacancies: [],
    isLoading: false,
  },
};

type BookingDialogProps = {
  contract?: Contract;
  handleCloseDialog: () => void;
  open: boolean;
  isDeposit?: boolean;
};
export function BookingDialog({
  contract,
  handleCloseDialog,
  open,
  isDeposit,
}: BookingDialogProps): React.ReactElement {
  const [state, setState] = useState({ ...iniState });
  const dispatch = useDispatch();
  const deviceType = useDevice();
  const isMobile = deviceType === deviceTypes.MOBILE;

  const contractId = contract?.composed_number;
  const laterCreditDate = contract?.later_credit_date;

  useEffect(() => {
    let stateUpdate = {};
    const today = moment();
    const minDate = moment();
    const yearsAvailable = [today.year()];
    let maxDate = moment().endOf('year');

    if (laterCreditDate) {
      const laterCreditDateMoment = moment(laterCreditDate);
      const yearsDiff = laterCreditDateMoment.year() - today.year();

      if (yearsDiff > 0) {
        for (let i = 0; i < yearsDiff; i++) {
          yearsAvailable.push(today.add(1, 'years').year());
        }
      }
      maxDate =
        laterCreditDateMoment.isoWeekday() <= 5
          ? laterCreditDateMoment.endOf('week').subtract(1, 'weeks')
          : laterCreditDateMoment.endOf('week');

      Object.assign(stateUpdate, { years_available: yearsAvailable, min_date: minDate, max_date: maxDate });
    }

    /**
     * Get room with Available days and push into array to be a filter
     * for disabled days on calendar
     * Some rooms can be available only on Sundays or Saturdays or on both
     */

    if (contract?.properties_availables_for) {
      Object.assign(stateUpdate, { availableDays: contract?.properties_availables_for.map((day) => day) });
    }

    Object.assign(stateUpdate, { forbiddenDates: getForbiddenDates(contract) });

    setState((state) => ({ ...state, ...stateUpdate }));
    setPeriodsGranted();
  }, [contractId]);

  const handleSuccess = (msg: string) => {
    setState((state) => ({
      ...state,
      isLoading: false,
      isBooking: false,
      successMessage: msg ? msg : 'A sua reserva está confirmada!',
    }));
    dispatch(loadContracts());
    dispatch(loadBookings());
  };

  const handleClose = () => {
    if (state.tryAgain === true) {
      setState((state) => ({
        ...state,
        tryAgain: false,
        failedMessage: null,
        successMessage: null,
        bookings: { vacancies: null, isLoading: false },
        roomSelected: null,
        range: null,
      }));
    } else {
      handleCloseDialog();
      setTimeout(() => {
        setState({ ...iniState });
      }, 500);
    }
  };

  const handleCloseInvoice = () => {
    axios
      .get(`${INVOICE_BASE_API_URL}invoices/${contract?.member.customer_id}/pending/`, {
        headers: setHeadersToken(),
      })
      .then((res: { data: Invoice[] }) => {
        const invoice = Object.values(res.data).find((invoice) => invoice.id === state.invoice?.id);
        setState((state) => ({ ...state, invoice: null }));

        if (!invoice) {
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmClick = () => {
    if (state.range) {
      let data = {
        checkin: moment(state.range.from).format(DATE_FORMAT),
        checkout: moment(state.range.to).format(DATE_FORMAT),
        membership: getMembership(contract, state.range.from),
        property_type: state.roomSelected,
        request_action: !contract?.pay_per_use ? 'confirm' : 'pay_per_use',
      };
      setState((state) => ({ ...state, isBooking: true, tryAgain: false, failedMessage: null }));

      axios
        .post(`${BASE_API_URL}confirm/booking/`, data, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          if (res.data.invoice) {
            setState((state) => ({
              ...state,
              invoice: res.data.invoice,
              isLoading: false,
              isBooking: false,
              successMessage: 'A sua reserva está a aguardar a confirmação do pagamento.',
            }));
          } else {
            handleSuccess(res.data.msg);
          }
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isBooking: false,
            tryAgain: true,
            failedMessage: handleErrorMessage(error.response),
          }));
        });
    }
  };

  const verifyVacancies = (checkin: string, checkout: string) => {
    setState((state) => ({ ...state, bookings: { ...state.bookings, isLoading: true } }));

    axios
      .get(`${BASE_API_URL}${getMembership(contract, null)}/${checkin}/${checkout}`, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        setState((state) => ({
          ...state,
          bookings: { vacancies: res.data, isLoading: false },
        }));
      })
      .catch((error) => {
        let failedMessage: string = 'Erro desconhecido';
        if (error.response.data && error.response.data.msg) {
          failedMessage = error.response.data.msg;
        }
        if (error.response.status && error.response.status === 500) {
          failedMessage = 'Erro de conexão.';
        }

        setState((state) => ({
          ...state,
          failedMessage,
          bookings: {
            vacancies: [],
            isLoading: false,
          },
        }));
      });
  };

  const setPeriodsGranted = (currDate?: Date) => {
    setState((state) => ({
      ...state,
      periodsGranted: getPeriodsGranted(currDate, contract),
    }));
  };

  const handleRoomClick = (id: number) => {
    muteHelper('property');
    setState((state) => ({
      ...state,
      roomSelected: id,
      failedMessage: null,
    }));
    setTimeout(() => {
      document.querySelector('.dialog__action-btns')?.scrollIntoView();
    }, 300);
  };

  const handleDayChange = (date: Date) => {
    let range: Range;
    if (Array.isArray(date)) {
      range = { from: date[0], to: date[1] };
    } else {
      range = getRange(date, 7);
    }

    setState((state) => ({
      ...state,
      range: range,
      roomSelected: null,
      activeYear: moment(range.from).year(),
    }));

    verifyVacancies(moment(range.from).format(DATE_FORMAT), moment(range.to).format(DATE_FORMAT));

    if (isMobile) {
      setTimeout(() => {
        document.getElementById('vacancies')?.scrollIntoView();
      }, 500);
    }
  };

  const periodClick = (date: Moment) => {
    setState((state) => ({ ...state, startDate: date }));
  };

  const {
    range,
    forbiddenDates,
    periodsGranted,
    availableDays,
    roomSelected,
    isBooking,
    invoice,
    startDate,
    bookings,
  } = state;

  const { vacancies, isLoading: isBookingsLoading } = bookings;
  return (
    <Fragment>
      <ResponsiveDialog
        open={open}
        handleCloseDialog={handleClose}
        title={isDeposit === true ? 'Depósito de Semana' : 'Reserva'}
        failedMessage={state.failedMessage}
        successMessage={state.successMessage}
        isLoading={isBooking}
        actions={
          <>
            <button className={`btn btn--primary outlined action-btn`} onClick={handleClose}>
              Cancelar
            </button>
            <button
              className={`btn btn--primary raised action-btn ${!roomSelected ? 'disabled' : ''}`}
              onClick={handleConfirmClick}
            >
              Confirmar reserva
            </button>
          </>
        }
        actionsClass="--space-between"
      >
        <Fragment>
          {contract && !invoice && (
            <div className="grid__container grid__container--column">
              <div className="grid__item pb-32">
                <div className="grid__container grid__container--column spacing-16">
                  <div className="grid__item">
                    <label className="overline">Contrato</label>
                    <p className="card__text">
                      <b>{contract.composed_number}</b>
                    </p>
                  </div>

                  <div className="grid__item">
                    <label className="overline">Período(s) atríbuido(s)</label>
                    <ContractAllowedPeriod periodsGranted={periodsGranted} handleClick={periodClick} />
                  </div>
                </div>
              </div>

              {!isBooking && (
                <div className="grid__item">
                  <p className="card__text --ta-center" id="helper-calendar">
                    Clique na data desejada
                  </p>

                  <Calendar
                    minDate={moment(state.min_date).toDate()}
                    maxDate={moment(state.max_date).toDate()}
                    locale="pt"
                    activeStartDate={moment(startDate).toDate()}
                    // defaultActiveStartDate={moment(startDate).toDate()}
                    onChange={handleDayChange}
                    selectRange={contract.contract_type.is_points}
                    value={[range?.from ?? null, range?.to ?? null]}
                    returnValue={contract.contract_type.is_points ? 'range' : 'start'}
                    formatShortWeekday={(_, date) =>
                      isMobile ? moment(date).format('dd') : moment(date).format('ddd')
                    }
                    tileDisabled={({ date, view }) =>
                      view === 'month' &&
                      (!availableDays.includes(moment(date).weekday()) ||
                        forbiddenDates.some((forbiddenDate) => moment(date).isSame(forbiddenDate)))
                    }
                    onActiveStartDateChange={({ activeStartDate }) => {
                      setState((state) => ({ ...state, startDate: activeStartDate }));
                      setPeriodsGranted(activeStartDate);
                    }}
                  />
                </div>
              )}
              <div className="grid__item dialog__content" id="vacancies">
                {isBookingsLoading ? (
                  <Preloader />
                ) : (
                  range?.from &&
                  range?.to && (
                    <div className="grid__container grid__container--column spacing-32">
                      <div className="grid__item pb-0-force">
                        <BookingDates
                          checkin={range.from}
                          checkout={range.to}
                          calendarIconClass="--t-primary"
                          centered={true}
                        />
                      </div>
                      <div className="grid__item">
                        <Vacancies
                          isBooking={isBooking}
                          isLoading={state.isLoading}
                          vacancies={vacancies}
                          roomSelected={roomSelected}
                          handleRoomClick={handleRoomClick}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </Fragment>
      </ResponsiveDialog>
      {invoice && (
        <PaymentDialog
          invoice={invoice}
          handlePaymentClose={handleCloseInvoice}
          open={Boolean(invoice)}
          extraSuccessMessage="<br /> A sua reserva está confirmada!"
        />
      )}
    </Fragment>
  );
}

export default BookingDialog;
