import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  withWidth
} from '@material-ui/core'

class Sidebar extends Component {
  render() {
    const {
      routes,
      width,
      sidebarOpen,
      handleToggleSidebar,
      siteConfig
    } = this.props
    const getBreakpoint = () => {
      switch (width) {
        case 'xs':
        case 'sm':
        case 'md':
          return {
            variant: 'temporary',
            anchor: 'right',
            open: sidebarOpen,
            onClose: handleToggleSidebar
          }
        case 'lg':
        default:
          return {
            anchor: 'left',
            variant: 'permanent',
            open: true
          }
      }
    }
    const drawerProps = getBreakpoint()

    var links = (
      <List className="sidebar__links" style={{ padding: 0 }}>
        {routes.map((prop, index) => {
          if (prop.redirect) return null
          return (
            <NavLink
              to={prop.path}
              className="sidebar__link"
              key={index}
              onClick={handleToggleSidebar}
            >
              <ListItem button>
                <ListItemIcon className="list-item__icon">
                  {typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.pageTitle}
                  className="list-item__text"
                />
              </ListItem>
            </NavLink>
          )
        })}
      </List>
    )
    return (
      <div>
        <Drawer {...drawerProps}>
          <div className="sidebar">
            <div className="sidebar__logo">
              <img
                src={siteConfig.logo}
                alt={siteConfig.title}
                style={{
                  width: '100%',
                  maxHeight: 100,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: 150,
                  height: 'auto'
                }}
              />
            </div>
            {links}
          </div>
        </Drawer>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    siteConfig: state.siteConfig
  }
}
export default connect(mapStateToProps)(withWidth()(Sidebar))
