import axios from 'axios'
import { BASE_API_URL } from '../urls'
import { checkCallback } from '../../Payments/actions/paymentsActions'
import { handleErrors } from '../../utils/helpers/errors'
import { setHeadersToken } from '../../utils/helpers/store'

export const loadInvoices = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'INVOICES_LOADING' })
    let member = getState().members?.member
    if (member)
      axios
        .get(`${BASE_API_URL}invoices/${member.customer_id}/pending/`, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          dispatch({ type: 'INVOICES_LOADED', payload: res.data })
          Object.values(res.data).map(
            (invoice) =>
              invoice.payments &&
              Object.values(invoice.payments).map(
                (payment) =>
                  payment.status.slug === 'processing' &&
                  payment.method.slug === 'multibanco' &&
                  dispatch(checkCallback(payment))
              )
          )
        })
        .catch((error) => {
          handleErrors(error, dispatch, 'INVOICES_FAILED')
        })
    else dispatch({ type: 'INVOICES_LOADED', payload: [] })
  }
}
export const payInvoice = (payment_data) => {
  return (dispatch, getState) => {
    dispatch({ type: 'INVOICES_PAYING' })
    let member = getState().members?.member
    axios
      .post(`${BASE_API_URL}payments/${member.customer_id}`, payment_data, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'INVOICES_PAYING_SUCCESS', payload: res.data })
        dispatch(loadInvoices())
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'INVOICES_FAILED')
      })
  }
}
