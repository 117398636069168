const initialState = {
  isLoading: false,
  member: null,
  errors: [],
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MEMBER_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'MEMBER_LOADED':
      return {
        ...state,
        isLoading: false,
        member: action.payload,
      };
    case 'MEMBER_FAILED': {
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
export default membersReducer;
