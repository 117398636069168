import {
  PATTERNS,
  RESPONSES,
  MIN_CHARS_TEXT,
  LOCALES,
  DEFAULT_LOCALE,
} from './config'

export const getLocale = (locale = DEFAULT_LOCALE) => {
  return LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
}

export const getStrengthLevel = (value, minLength, locale) => {
  let tips = ''

  let patternsCheck = [],
    weeknessMatches = [],
    strongMatches = [],
    maxLevel = RESPONSES.reduce((prev, current) => {
      if (current.level > prev.level) return current
      else return prev
    }).level

  PATTERNS.forEach((item) => {
    item['matched'] = false
    if (value.match(item.pattern)) {
      item['matched'] = true
      strongMatches.push(item)
    } else {
      weeknessMatches.push(item)
    }

    patternsCheck.push(item)
  })

  let strengthLevel = RESPONSES.find((level) =>
    [strongMatches.length - 1, 0].includes(level.level)
  )

  if (
    strengthLevel &&
    !Boolean(strengthLevel.charRange.find((val) => value.length >= val))
  )
    strengthLevel = RESPONSES.find((level) =>
      level.charRange.includes(value.length)
    )

  tips = (
    <ul className="pass-strength__tips">
      <li
        className={`pass-strength__tip ${
          value.length > minLength ? 'pass-strength__tip--check' : ''
        }`}
      >
        {MIN_CHARS_TEXT.getText(minLength, locale)}
      </li>
      {patternsCheck.length > 0 &&
        patternsCheck.map((item, idx) => (
          <li
            className={`pass-strength__tip ${
              item.matched ? 'pass-strength__tip--check' : ''
            }`}
            key={idx}
          >
            {item.tip[locale]}
          </li>
        ))}
    </ul>
  )
  let maxLevelAchieved = strengthLevel.level >= maxLevel ? true : false
  return [strengthLevel, tips, maxLevelAchieved]
}
