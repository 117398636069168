const initialState = {
  isLoading: false,
  isPaying: false,
  items: [],
  error: null,
  canBook: false,
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INVOICES_LOADING':
      return { ...state, error: null, isLoading: true }
    case 'INVOICES_LOADED':
      const returnState = {
        ...state,
        isLoading: false,
        items: action.payload,
      }
      if (Object.values(action.payload).length > 0) {
        returnState['canBook'] = false
      } else {
        returnState['canBook'] = true
      }
      return returnState

    case 'INVOICES_PAYING_START':
      return { ...state, error: null, isPaying: true }
    case 'INVOICES_PAYING_FINISHED':
      return {
        ...state,
        isPaying: false,
      }

    case 'INVOICES_FAILED':
      return {
        ...state,
        error: action.errors,
        isLoading: false,
        isPaying: false,
      }

    default:
      return state
  }
}
export default invoicesReducer
