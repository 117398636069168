import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthSelector, getAuthToken } from '../components/Auth/selectors';

export default function PrivateRoutes(): React.ReactElement {
  const { isAuthenticated } = useSelector(getAuthSelector);
  const hasAuthToken = getAuthToken();
  const isLoggedIn = isAuthenticated || hasAuthToken;

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}
