import React from 'react';
import { Warning as WarningIcon } from '@material-ui/icons';
import { AlertContainer, AlertHeader, AlertHeaderIcon, AlertHeaderTitle, AlertWrapper } from './styles';

export type AlertSectionProps = {
  title: string;
  children: React.ReactNode;
};
function AlertSection({ title, children }: AlertSectionProps): React.ReactElement {
  return (
    <AlertWrapper>
      <AlertHeader>
        <AlertHeaderIcon>
          <WarningIcon />
        </AlertHeaderIcon>
        <AlertHeaderTitle>{title}</AlertHeaderTitle>
      </AlertHeader>
      <AlertContainer>{children}</AlertContainer>
    </AlertWrapper>
  );
}

export default AlertSection;
