const initialState = {
  token: null,
  user: null,
  userExists: null,
  memberFoundWithoutUser: false,
  memberExists: null,
  isAuthenticated: false,
  userCreated: null,
  recoverPasswordSent: false,
  passwordChanged: false,
  isLoading: false,
  errors: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SENDING_PASSWORD_RECOVER':
      return {
        ...state,
        isLoading: true,
        errors: null,
        recoverPasswordSent: false,
      }
    case 'SETTING_NEW_PASSWORD':
      return {
        ...state,
        isLoading: true,
        errors: null,
        passwordChanged: false,
      }
    case 'USER_CREATE_START':
    case 'LOOKING_FOR_USER':
    case 'LOOKING_FOR_MEMBER_BY_EMAIL':
    case 'LOGIN_START':
      return {
        ...initialState,
        isLoading: true,
      }
    case 'USER_EXISTS':
      return {
        ...state,
        isLoading: false,
        errors: null,
        userExists: action.payload.email,
      }
    case 'MEMBER_FOUND_WITHOUT_USER':
      return {
        ...state,
        isLoading: false,
        errors: null,
        userExists: false,
        memberFoundWithoutUser: action.payload,
      }
    case 'MEMBER_EXISTS':
      return {
        ...state,
        isLoading: false,
        errors: null,
        memberExists: action.payload,
      }
    case 'LOGIN_SUCCESSFUL':
      localStorage.setItem('token', action.payload.key)
      return {
        ...state,
        token: action.payload.key,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
        passwordChanged: false,
      }
    case 'USER_CREATED':
      return {
        ...state,
        userCreated: action.payload,
        isLoading: false,
        errors: null,
        passwordChanged: false,
      }
    case 'PASSWORD_RECOVER_SENT':
      return {
        ...state,
        isLoading: false,
        errors: null,
        recoverPasswordSent: true,
      }
    case 'PASSWORD_CHANGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        passwordChanged: true,
        errors: null,
      }
    case 'PASSWORD_CHANGE_FAIL':
      return {
        ...state,
        isLoading: false,
        passwordChanged: false,
        errors: action.errors,
      }
    case 'USER_CREATION_FAILED':
      return {
        ...state,
        userCreated: false,
        isLoading: false,
        errors: action.errors,
      }

    case 'USER_NOT_FOUND':
      return {
        ...state,
        errors: action.errors,
        userExists: false,
        isAuthenticated: false,
        isLoading: false,
        memberFoundWithoutUser: false,
      }
    case 'MEMBER_NOT_FOUND':
      return {
        ...state,
        errors: action.errors,
        memberExists: false,
        isAuthenticated: false,
        isLoading: false,
      }
    case 'AUTHENTICATION_ERROR':
      localStorage.removeItem('token')
      return {
        ...state,
        errors: {
          password: 'Senha inválida.',
        },
        isAuthenticated: false,
        isLoading: false,
        token: null,
        passwordChanged: false,
        userExists: true,
      }
    case 'LOGOUT':
      localStorage.removeItem('token')
      return {
        ...initialState,
      }

    case 'USER_LOADED':
      return {
        ...state,
        user: action.payload,
      }
    case 'USER_FAILED':
      return {
        ...state,
        errors: action.errors,
        memberExists: false,
        isAuthenticated: false,
        isLoading: false,
      }

    default:
      return state
  }
}
export default authReducer
