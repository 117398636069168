import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionHeader from '../SectionHeader';
import ContractCard from '../../../components/Contracts/ContractCard';
import BookingDialog from '../../../components/Bookings/BookingDialog';
import PartnerDialog from '../../../components/Bookings/PartnerDialog';
import AnimatedGridItem from '../../../components/utils/AnimatedGridItem';
import { getContractsSelector } from '../../../components/Contracts/selectors';
import { Contract } from '../../../components/Contracts/types';
import { loadContracts } from '../../../components/Contracts/actions/contractsActions';

enum Dialogs {
  Booking = 'Booking',
  Partner = 'Partner',
}

export type HandleOpenDialogProps = {
  dialog: keyof typeof Dialogs | null;
  open: boolean;
  contract?: Contract;
};

export function Contracts(): React.ReactElement {
  const { items: contracts } = useSelector(getContractsSelector);
  const [openDialog, setOpenDialog] = useState<keyof typeof Dialogs | null>(null);
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined);

  const dispatch = useDispatch();

  const hasContracts = contracts.length > 0;

  useEffect(() => {
    dispatch(loadContracts());
  }, [dispatch]);

  const handleOpenDialog = ({ dialog, open = true, contract }: HandleOpenDialogProps): void => {
    if (open) {
      setOpenDialog(dialog);
      setSelectedContract(contract);
    } else {
      setOpenDialog(null);
      setSelectedContract(undefined);
    }
  };

  return (
    <div className="contracts">
      <div className="grid__container spacing-32">
        <div className="grid__item xs-12">
          <SectionHeader icon="source" title="Contratos" />
        </div>

        {contracts?.map((contract, index) => (
          <AnimatedGridItem customClass="grid__item xs-12 md-6 xl-4" animate={true} key={index} index={index}>
            <ContractCard handleOpenDialog={handleOpenDialog} contract={contract} />
          </AnimatedGridItem>
        ))}
        {!hasContracts && (
          <AnimatedGridItem animate={true}>
            <div className="card">
              <div className="card__body">
                <p className="text-mutted">Nenhum contrato encontrado.</p>
              </div>
            </div>
          </AnimatedGridItem>
        )}
      </div>
      <BookingDialog
        contract={selectedContract}
        handleCloseDialog={() => handleOpenDialog({ dialog: Dialogs.Booking, open: false })}
        open={openDialog === Dialogs.Booking}
      />
      <PartnerDialog
        contract={selectedContract}
        handleCloseDialog={() => handleOpenDialog({ dialog: Dialogs.Partner, open: false })}
        open={openDialog === Dialogs.Partner}
      />
    </div>
  );
}

export default Contracts;
