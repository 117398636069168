import React from 'react'
import ConfirmDialog from '../utils/ConfirmDialog'

const ExchangeDialogs = ({ openDialog, handleOpenDialog, exchanges }) => {
  return (
    <>
      {openDialog && (
        <ConfirmDialog
          open={true}
          handleYes={openDialog.handleYes}
          handleNo={() => handleOpenDialog(false)}
          handleCloseDialog={() => handleOpenDialog(false)}
          hideCloseButton={true}
          isLoading={exchanges.isProcessing}
          failedMessage={exchanges.failedMessage}
          successMessage={exchanges.successMessage}
          text={openDialog.text}
        />
      )}
    </>
  )
}

export default ExchangeDialogs
