import React, { useState } from 'react'

import { Collapse, IconButton } from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreIcon,
} from '@material-ui/icons'

import Media from '../utils/Media'

import BookingDates from '../Bookings/BookingDates'
import Rule from './Rule'
import BookingMenu from '../Bookings/BookingMenu'
import ContractHeader from '../Contracts/ContractHeader'

const ExchangeCardSmall = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const { booking, handleOpenDialog } = props
  return (
    <div className="card card--small">
      <div className="card__header">
        <div className="avatar">
          <div className="avatar__body">
            <Media
              src={`${booking.property_type.get_featured_img}`}
              alt={booking.property_type.name}
            />
          </div>
        </div>
        <div className="content">
          <h3 className="subtitle_2">{booking.property_type.name}</h3>
          <BookingDates
            hideLabel={true}
            hideIcon={true}
            checkin={booking.checkin}
            checkout={booking.checkout}
          />
        </div>
        <div className="btns">
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="Detalhes"
          >
            <ExpandMoreIcon
              className={`expand-icon ${isExpanded ? 'expanded' : ''} `}
              fontSize="small"
            />
          </IconButton>
          {booking.exchange.status === 'available' && (
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              aria-label="Menu"
            >
              <MoreIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>

      <Collapse
        className="card__body-wrapper"
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        <div className="card__body pt-0-force">
          <ContractHeader hideLabel={true} contract={booking.contract} />
        </div>
        <div className="card__section">
          <div className="divisor divisor--h"></div>
          <div className="list">
            <div className="list__subheader body_2 pl-16-force">Regras</div>
            {Object.values(booking.exchange_rules).length > 0 &&
              Object.values(booking.exchange_rules).map((rule, index) => (
                <Rule key={index} rule={rule} index={index} />
              ))}
          </div>
        </div>
      </Collapse>

      <BookingMenu
        id={`menu-${booking.id}`}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        booking={booking}
        handleOpenDialog={handleOpenDialog}
      />
    </div>
  )
}
export default ExchangeCardSmall
