import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import moment from 'moment'

import CustomExpansionPanel from '../utils/CustomExpansionPanel'
import TransactionCardSmall from './TransactionCardSmall'
import ExchangeCardSmall from './ExchangeCardSmall'
import { NavLink } from 'react-router-dom'

const BookingsDeposited = ({ transactions, bookings, handleOpenDialog }) => {
  const bookings_in_exchange = Object.values(bookings.items).filter(
      (booking) =>
        booking.exchange &&
        (booking.exchange.status === 'available' ||
          booking.exchange.status === 'processing')
    ),
    upcoming_bookings_count = Object.values(bookings.items).filter(
      (booking) => moment(booking.checkin) > moment()
    ).length

  return (
    <CustomExpansionPanel
      title="Minhas reservas"
      helperText="As suas reservas depositadas no Banco de Trocas."
    >
      <div className="grid">
        <div className="grid__container grid__container--column spacing-16">
          {Object.values(bookings_in_exchange).length > 0 ? (
            Object.values(bookings_in_exchange).map((booking) => {
              if (booking.exchange.status === 'processing') {
                let bookingTransaction = Object.values(transactions).find(
                  (transaction) =>
                    transaction.exchange.id === booking.exchange.id
                )
                if (
                  bookingTransaction &&
                  bookingTransaction.status.slug === 'processing'
                )
                  return (
                    <div className="grid__item" key={booking.id}>
                      <TransactionCardSmall
                        booking={booking}
                        transaction={bookingTransaction}
                        handleTransactionDialog={handleOpenDialog}
                      />
                    </div>
                  )
              }
              return (
                <div className="grid__item" key={booking.id}>
                  <ExchangeCardSmall
                    booking={booking}
                    handleOpenDialog={handleOpenDialog}
                  />
                </div>
              )
            })
          ) : (
            <div className="grid__item">
              <>
                <p className="body_2 small text-mutted pb-16">
                  Você não tem reservas depositadas no Banco de Trocas.
                </p>
                {upcoming_bookings_count > 0 && (
                  <NavLink to="/reservas">
                    <button className={`btn btn--primary outlined action-btn`}>
                      Depositar reservas
                    </button>
                  </NavLink>
                )}
              </>
            </div>
          )}
        </div>
      </div>
    </CustomExpansionPanel>
  )
}

const mapStateToProps = (state) => {
  return {
    bookings: state.bookings,
    transactions: state.exchanges.transactions,
  }
}

export default connect(mapStateToProps)(BookingsDeposited)
