import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import HelpToolTip from './HelpToolTip.tsx';

const CustomExpansionPanel = ({ title, helperText, children, expanded = true }) => {
  return (
    <Accordion defaultExpanded={expanded} className="panel panel--transparent">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel__summary">
        <div className="grid__container spacing-8 --align-center">
          <div className="grid__item">
            <h2 className="body_2">{title}</h2>
          </div>
          {helperText && (
            <div className="grid__item">
              <HelpToolTip title={helperText} />
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="panel__body">{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomExpansionPanel;
