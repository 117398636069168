import React, { Fragment } from 'react'

const Greetings = ({ greet = true, name = null, message = null }) => {
  return (
    <Fragment>
      {greet === true && <p className="card__text">Olá,</p>}
      {name && (
        <h1 className="card__title --t-success">
          <b>{name}</b>
        </h1>
      )}
      {message && <p className="card__text">{message}</p>}
    </Fragment>
  )
}

export default Greetings
