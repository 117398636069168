import React from 'react'
import { Skeleton } from '@mui/material'

const ContractListCardSkeleton = ({ contract }) => {
  return (
    <div className="card">
      <div className="card__body">
        <div className="grid__container --space-between spacing-16">
          <div className="grid__item xs-4">
            <div className="grid__container --align-center spacing-16">
              <div className="grid__item xs-4">
                <Skeleton variant="rectangular" width={36} height={36} />
              </div>
              <div className="grid__item xs-8">
                <div className="subtitle_1">
                  <Skeleton />
                </div>
                <div className="subtitle_1">
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
          <div className="grid__item">
            <div className="grid__container spacing-16 --align-center">
              <div className="grid__item">
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={24}
                  style={{ borderRadius: 24 }}
                />
              </div>
              <div className="grid__item">
                <Skeleton variant="circular" width={36} height={36} />
              </div>
              <div className="grid__item">
                <Skeleton variant="circular" width={36} height={36} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractListCardSkeleton
