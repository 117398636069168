import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setHeadersToken } from '../utils/helpers/store';
import ConfirmDialog from '../utils/ConfirmDialog';
import PartnerDialog from '../Bookings/PartnerDialog';
import { getMemberSelector } from '../Members/selectors';
import { Booking } from '../Bookings/types';
import { loadBookings } from '../Bookings/actions/bookingsActions';
import { Preloader } from '../utils/Preloader';

import { BASE_API_URL } from './urls';
import ExchangeDialog from './ExchangeDialog';
import CurrentTransactions from './CurrentTransactions';
import BuyDialog from './BuyDialog';
import {
  loadExchanges,
  loadCurrentTransactions,
  setSelectedTransaction,
  handleTransaction,
} from './actions/exchangesActions';
import ExchangesCards from './ExchangesCards';
import TransactionsHistory from './TransactionsHistory';
import BookingsDeposited from './BookingsDeposited';
import { Exchange, Transaction } from './types';
import { getExchangesSelector } from './selectors';

export enum Dialogs {
  Exchange = 'Exchange',
  Buy = 'Buy',
  Partner = 'Partner',
  ConfirmExclusion = 'ConfirmExclusion',
  GiveUp = 'GiveUp',
  ConfirmTransaction = 'ConfirmTransaction',
  ConfirmPayment = 'ConfirmPayment',
}

export type HandleOpenDialogProps = {
  dialog: keyof typeof Dialogs;
  open: boolean;
  booking?: Booking | null;
  exchange?: Exchange | null;
  transaction?: Transaction | null;
};

export function Exchanges(): React.ReactElement {
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [selectedExchange, setSelectedExchange] = useState<Exchange | null>(null);
  const [response, setResponse] = useState({
    failedMessage: '',
    successMessage: '',
  });
  const [openDialog, setOpenDialog] = useState<keyof typeof Dialogs | null>(null);

  const {
    items: exchanges,
    isProcessing,
    failedMessage,
    successMessage,
    isLoading,
  } = useSelector(getExchangesSelector);
  const { member } = useSelector(getMemberSelector);

  const dispatch = useDispatch();

  const memberId = member?.customer_id;

  const notConfirmDialogs: Partial<keyof typeof Dialogs>[] = [Dialogs.Exchange, Dialogs.Buy, Dialogs.Partner];

  const reloadData = useCallback(() => {
    dispatch(loadCurrentTransactions());
    dispatch(loadBookings());
    dispatch(loadExchanges());
  }, [dispatch]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const dialogs = {
    [Dialogs.Exchange]: {
      booking: selectedBooking,
      handleYes: undefined,
      text: undefined,
    },
    [Dialogs.Buy]: {
      exchange: selectedExchange ? Object.values(exchanges).find((item) => item.id === selectedExchange.id) : {},
      handleYes: undefined,
      text: undefined,
    },
    [Dialogs.Partner]: {
      booking: selectedBooking,
      handleYes: undefined,
      text: undefined,
    },
    [Dialogs.ConfirmExclusion]: {
      handleYes: () => {
        selectedBooking && handleRemoveFromExchange(selectedBooking);
        setOpenDialog(null);
      },
      text: 'Deseja remover a sua reserva do Banco de Trocas?',
    },
    [Dialogs.GiveUp]: {
      handleYes: () => {
        dispatch(handleTransaction('giveup'));
      },
      text: 'Deseja desistir da transação?',
    },
    [Dialogs.ConfirmTransaction]: {
      handleYes: () => {
        dispatch(handleTransaction('confirm'));
      },
      text: 'Confirma o recebimento da transferência?',
    },
    [Dialogs.ConfirmPayment]: {
      handleYes: () => {
        dispatch(handleTransaction('confirm-payment'));
      },
      text: 'Confirma que efetuou a transferência?',
    },
  };

  function handleOpenDialog({
    dialog,
    open = true,
    booking = null,
    exchange = null,
    transaction = null,
  }: HandleOpenDialogProps) {
    if (transaction) {
      dispatch(setSelectedTransaction(transaction));
    }

    setOpenDialog(open ? dialog : null);
    if (!open)
      setTimeout(() => {
        setSelectedBooking(null);
        setSelectedExchange(null);
      }, 500);
    else {
      setSelectedBooking(booking);
      setSelectedExchange(exchange);
    }
    dispatch({ type: 'CLEAR_MESSAGES' });
    setResponse({ failedMessage: '', successMessage: '' });
  }

  function handleSuccess({ message = null }) {
    setResponse({ ...response, successMessage: message ? message : 'Sucesso' });
    reloadData();
  }

  function handleRemoveFromExchange(booking: Booking) {
    setResponse({ ...response, failedMessage: '' });

    let data = { is_canceled: true };
    booking.exchange &&
      axios
        .patch(`${BASE_API_URL}/${memberId}/${booking.exchange.id}/`, data, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          handleSuccess({ message: res.data ? res.data.msg : null });
          reloadData();
        })
        .catch((error) => {
          setResponse({ ...response, failedMessage: error.response.data.msg });
        })
        .finally(() => {});
  }

  const sidePanels = [
    <BookingsDeposited handleOpenDialog={handleOpenDialog} />,
    <CurrentTransactions handleOpenDialog={handleOpenDialog} />,
    <TransactionsHistory />,
  ];
  return (
    <div className="exchanges">
      {isLoading && <Preloader overlay={true} />}
      <div className="grid">
        <div className="grid__container spacing-32 xs-collumn-reverse">
          <div className="grid__item xs-12 md-8 xl-9">
            <ExchangesCards exchanges={exchanges} handleOpenDialog={handleOpenDialog} />
          </div>
          <div className="grid__item xs-12 md-4 xl-3">
            <div className="grid__container grid__container--column spacing-24">
              {sidePanels.map((panel, index) => (
                <div
                  key={index}
                  className="grid__item animated fadeInRight faster"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  {panel}
                </div>
              ))}
            </div>
          </div>
        </div>

        <ExchangeDialog
          booking={dialogs[Dialogs.Exchange].booking}
          handleCloseDialog={() => setOpenDialog(null)}
          open={openDialog === Dialogs.Exchange}
        />
        <BuyDialog
          exchange={dialogs[Dialogs.Buy].exchange}
          handleCloseDialog={() => setOpenDialog(null)}
          open={openDialog === Dialogs.Buy}
        />
        <PartnerDialog
          booking={dialogs[Dialogs.Partner].booking}
          handleCloseDialog={() => setOpenDialog(null)}
          open={openDialog === Dialogs.Partner}
        />
        <ConfirmDialog
          open={Boolean(openDialog && !notConfirmDialogs.includes(openDialog))}
          handleYes={openDialog ? dialogs[openDialog].handleYes : undefined}
          handleCloseDialog={() => {
            setOpenDialog(null);
            setResponse({ failedMessage: '', successMessage: '' });
          }}
          hideCloseButton
          isLoading={isProcessing}
          failedMessage={failedMessage}
          successMessage={successMessage}
          text={openDialog && dialogs[openDialog].text}
        />
      </div>
    </div>
  );
}

export default Exchanges;
