import axios from 'axios'
import { BASE_API_URL } from '../urls'
import { handleErrors } from '../../utils/helpers/errors'
import { setHeadersToken } from '../../utils/helpers/store'

export const loadGuides = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'COMMUNICATIONS_LOADING' })
    axios
      .get(`${BASE_API_URL}guides/`, { headers: setHeadersToken() })
      .then(res => {
        dispatch({ type: 'COMMUNICATIONS_LOADED_GUIDES', payload: res.data })
      })
      .catch(error => handleErrors(error, dispatch, 'COMMUNICATIONS_FAILED'))
  }
}
export const readGuide = slug => {
  return (dispatch, getState) => {
    dispatch({ type: 'COMMUNICATIONS_LOADING' })
    axios
      .patch(
        `${BASE_API_URL}guides/${slug}/`,
        { read: true },
        { headers: setHeadersToken() }
      )
      .then(res => {
        dispatch({ type: 'COMMUNICATIONS_READ' })
      })
      .catch(error => handleErrors(error, dispatch, 'COMMUNICATIONS_FAILED'))
  }
}
