const initialState = {
  isLoading: false,
  items: [],
  vacancies: [],
  errors: null,
  partners: [],
  upcoming: [],
  history: [],
}

const bookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFY_VACANCY_START':
    case 'BOOKINGS_LOADING':
    case 'CANCELING_EXCHANGE':
      return { ...state, errors: null, isLoading: true }
    case 'VERIFY_VACANCY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        vacancies: action.payload,
      }
    case 'BOOKINGS_LOADED':
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      }

    case 'BOOKINGS_PARTNERS_LOADED':
      return {
        ...state,
        isLoading: false,
        partners: action.payload,
      }
    case 'BOOKINGS_PARTNERS_FAILED':
    case 'BOOKINGS_FAILED':
      return {
        ...state,
        isLoading: false,
        items: [],
        partners: [],
      }

    case 'VERIFY_VACANCY_FAILED':
      return {
        ...state,
        errors: action.errors,
        vacancies: [],
        isLoading: false,
      }

    case 'CLEAR_VACANCIES':
      return {
        ...state,
        vacancies: [],
      }

    default:
      return state
  }
}
export default bookingsReducer
