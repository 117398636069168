import React from 'react'

const PaymentSelectMethodButton = ({ handleOnClick, icon, iconAlt }) => (
  <button className={`payment__button`} onClick={handleOnClick}>
    <img
      src={icon}
      alt={iconAlt}
      className="payment__icon"
      style={{ width: 80, height: 'auto', maxHeight: 80 }}
    />
  </button>
)

export default PaymentSelectMethodButton
