import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import { muteHelper, handleErrorMessage } from '../utils/functions';
import { setHeadersToken } from '../utils/helpers/store';
import ResponsiveDialog from '../utils/ResponsiveDialog';
import { useDevice } from '../../hooks/useDevice';
import { deviceTypes } from '../../constants/deviceTypes';

import { getMembership } from './utils';
import { loadBookings } from './actions/bookingsActions';
import { BASE_API_URL } from './urls';
import BookingDates from './BookingDates';
import { getBookingsSelector } from './selectors';
import { Contract } from '../Contracts/types';
import { Booking } from './types';

type PartnerDialogProps = {
  contract?: Contract | null;
  booking?: Booking | null;
  handleCloseDialog: () => void;
  open: boolean;
};

type Response = {
  failedMessage: string | React.ReactNode;
  successMessage: string | React.ReactNode;
};

const initialResponse = {
  failedMessage: '',
  successMessage: '',
};

export function PartnerDialog({ contract, booking, open, handleCloseDialog }: PartnerDialogProps): React.ReactElement {
  const { partners } = useSelector(getBookingsSelector);
  const [partnerSelected, setPartnerSelected] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<Response>({ ...initialResponse });
  const dispatch = useDispatch();

  const deviceType = useDevice();
  const isMobile = deviceType === deviceTypes.MOBILE;
  const expiredDepositInPartner = booking && moment(booking.checkin) < moment().add(1, 'months');

  useEffect(() => {
    if (expiredDepositInPartner) {
      setResponse((response) => ({
        ...response,
        failedMessage: (
          <p>
            O prazo para depositar a sua semana numa das empresas de intercâmbio expirou, mas ainda pode depositá-la no{' '}
            <NavLink to="/banco-de-trocas">Banco de Trocas</NavLink>.
          </p>
        ),
      }));
    }

    if (partners.length === 1 && !partnerSelected) {
      setPartnerSelected(1);
    }

    if (isMobile) {
      setTimeout(() => {
        document?.getElementById('confirm')?.scrollIntoView();
      }, 300);
    }
  }, [expiredDepositInPartner, partners, partnerSelected, isMobile]);

  const handleSuccess = () => {
    setResponse({
      ...response,
      successMessage: 'O seu pedido de depósito foi realizado.',
    });

    dispatch(loadBookings());
  };
  const handleClose = () => {
    setPartnerSelected(null);
    setResponse({ ...initialResponse });
    handleCloseDialog();
  };

  const handlePartnerClick = (id: number) => {
    muteHelper('partner');
    setPartnerSelected(id);
  };
  const handleConfirmClick = () => {
    setIsLoading(true);
    let data: Record<string, any> = {
      partner: partnerSelected,
      membership: booking ? booking.membership : getMembership(contract, null),
      request_action: 'confirm',
    };

    /**
     * If the dialog is called from a booking card, it must have a
     * booking in props to send a cancelation to it
     */
    if (booking) {
      data['cancel_booking'] = booking.id;
    }

    axios
      .post(`${BASE_API_URL}confirm/deposit/`, data, {
        headers: setHeadersToken(),
      })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        setResponse({
          ...response,
          failedMessage: handleErrorMessage(error.response),
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ResponsiveDialog
      open={open}
      handleCloseDialog={handleClose}
      title="Depósito"
      subtitle="Intercâmbio"
      failedMessage={response.failedMessage}
      successMessage={response.successMessage}
      isLoading={isLoading}
      actions={
        <Fragment>
          <button className={`btn btn--primary outlined action-btn`} onClick={handleClose}>
            Cancelar
          </button>
          <button
            className={`btn btn--primary raised action-btn ${!partnerSelected ? 'disabled' : ''}`}
            onClick={handleConfirmClick}
          >
            Confirmar depósito
          </button>
        </Fragment>
      }
    >
      {(contract || booking) && (
        <div className="grid__container grid__container--column spacing-16">
          {contract && (
            <div className="grid__item">
              <label className="overline">Contrato</label>
              <p className="card__text">
                <b>{contract.composed_number}</b>
              </p>
            </div>
          )}
          {booking && (
            <div className="grid__item">
              <div
                style={{
                  border: '1px solid rgba(0,0,0,0.07)',
                  padding: 16,
                  borderRadius: 5,
                }}
              >
                <div className="grid__container grid__container--column spacing-16">
                  <div className="grid__item">
                    <p className="body_2 --nomargin">
                      <b>{booking.property_type.name}</b> ({booking.contract})
                    </p>
                    <p className="smaller" style={{ whiteSpace: 'pre-wrap' }}>
                      {booking.property_type.resort.address}
                    </p>
                  </div>
                  <div className="grid__item">
                    <BookingDates checkin={booking.checkin} checkout={booking.checkout} />
                  </div>
                  <div className="grid__item">
                    {!booking.exchange && (
                      <>
                        <p>
                          Antes de depositar a sua semana numa das empresas de intercâmbio, dê uma vista de olhos no{' '}
                          <NavLink onClick={handleClose} to="/banco-de-trocas">
                            Banco de Trocas
                          </NavLink>{' '}
                          e veja se existem membros interessados na sua reserva.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="grid__item">
            {partners.length > 1 && <p id="helper-partner">Selecione para qual empresa deseja depositar sua semana</p>}
            <div className="grid__container spacing-32">
              {Object.values(partners).map((partner, index) => (
                <div className="grid__item" key={index}>
                  <button
                    className={`booking__button--apt ${
                      partnerSelected === partner.id ? 'booking__button--selected disabled' : 'booking__button'
                    }`}
                    onClick={() => handlePartnerClick(partner.id)}
                  >
                    {partner.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          {partnerSelected && (
            <div className="grid__item" id="confirm">
              <p>
                Ao clicar em confirmar, {booking && 'a sua reserva será cancelada e'} encaminharemos o pedido de
                depósito da sua semana.
              </p>
            </div>
          )}
        </div>
      )}
    </ResponsiveDialog>
  );
}

export default PartnerDialog;
