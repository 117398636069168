import React from 'react'
import Input from '../../components/utils/Input'
import { MailOutlined as MailIcon } from '@material-ui/icons'

const AuthStepCheckUser = ({
  step,
  activeStep,
  handleInputChange,
  handleOnSubmit,
  credentials,
  props,
}) => {
  return (
    <form onSubmit={handleOnSubmit}>
      <div className="grid__container grid__container--column spacing-24">
        <div className="grid__item">
          {props.auth.passwordChanged === true ? (
            <p className="card__text --t-success --t-bold">
              Senha alterada com sucesso.
            </p>
          ) : (
            <>
              <h1 className="card__text --title">Bem vindo</h1>
              <p className="card__text --subheading">
                Reserve agora as suas férias!
              </p>
            </>
          )}
        </div>

        <div className="grid__item">
          <p>
            Insira o seu email e clique em <b>continuar</b>.
          </p>
          <Input
            name="email"
            type="email"
            label="Email"
            value={credentials.email}
            handleChange={handleInputChange}
            errors={props.errors}
            icon={<MailIcon />}
            required
            autoFocus={activeStep === step ? true : false}
          />
        </div>
      </div>

      <div className="stepper__footer-actions">
        <button type="submit" className={`btn btn--primary raised action-btn`}>
          Continuar
        </button>
      </div>
    </form>
  )
}

export default AuthStepCheckUser
