import { Icon } from '@mui/material';
import React from 'react';

type SectionHeaderProps = {
  title: string;
  icon?: string;
  subtitle?: React.ReactChild | string;
  children?: React.ReactChild;
};

export function SectionHeader({ title, icon, subtitle, children }: SectionHeaderProps): React.ReactElement {
  return (
    <div className="section__header --justify-start" style={{ width: '100%' }}>
      <div className="grid__container grid__container--column spacing-16">
        <div className="grid__item mr-auto">
          <div className="grid__container spacing-8 --align-center">
            {icon && (
              <div className="grid__item section__icon">
                <Icon>{icon}</Icon>
              </div>
            )}
            <div className="grid__item">
              <h1 className="section__title">{title}</h1>
            </div>
          </div>
        </div>
        {subtitle && (
          <div className="grid__item">
            <p className="section__subtitle">{subtitle}</p>
          </div>
        )}
        {children && <div className="grid__item">{children}</div>}
      </div>
    </div>
  );
}

export default SectionHeader;
