import React, { useEffect, useState } from 'react'
import { Collapse, IconButton, Avatar } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

const CardSmall = (props) => {
  const {
    avatar,
    avatarSrc,
    avatarAlt,
    headerContent,
    headerBtns,
    children,
    actionBtns,
    actionBtnsClass,
    expanded = false,
    extraClass,
  } = props
  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const handleExpandClick = () => {
    props.handleExpandClick
      ? props.handleExpandClick()
      : setIsExpanded(!isExpanded)
  }

  return (
    <div className={`card card--small ${extraClass}`}>
      <div className="card__header">
        {(avatar || avatarSrc) && (
          <div className="avatar">
            <div className="avatar__body">
              {avatar}
              {avatarSrc && <Avatar alt={avatarAlt} src={avatarSrc} />}
            </div>
          </div>
        )}
        {headerContent && <div className="content">{headerContent}</div>}

        <div className="btns">
          {headerBtns}
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={isExpanded}
            aria-label="Detalhes"
          >
            <ExpandMoreIcon
              className={`expand-icon ${isExpanded ? 'expanded' : ''} `}
              fontSize="small"
            />
          </IconButton>
        </div>
      </div>

      <Collapse
        className="card__body-wrapper"
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        <div className="card__body">{children}</div>
        {actionBtns && (
          <div className={`card__action-btns ${actionBtnsClass}`}>
            {actionBtns}
          </div>
        )}
      </Collapse>
    </div>
  )
}

export default CardSmall
