import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, Logout } from '@mui/icons-material';

import { Menu, MenuItem, IconButton, Button, Typography, ListItemIcon, Divider } from '@material-ui/core';
import { KeyboardArrowDownRounded as ArrowDownIcon, VisibilityOff, Search } from '@material-ui/icons';

import SearchContractDialog from '../Contracts/SearchContractDialog';
import { getMemberSelector } from '../Members/selectors';
import { getUserSelector } from '../Auth/selectors';
import { clearMemberData } from '../../redux/actions/clearMemberData';

const SearchContract: React.FC = () => {
  const [isSearchContractOpen, setIsSearchContractOpen] = useState(false);
  return (
    <Fragment>
      <SearchContractDialog open={isSearchContractOpen} handleClose={() => setIsSearchContractOpen(false)} />
      <IconButton onClick={() => setIsSearchContractOpen(true)}>
        <Search style={{ height: 20, width: 20, color: '#fff' }} />
      </IconButton>
    </Fragment>
  );
};

type GreetingsProps = {
  isSimulating: boolean;
  greetingsName: string;
};
const Greetings: React.FC<GreetingsProps> = ({ isSimulating, greetingsName }) => {
  return (
    <Fragment>
      <span style={{ marginRight: 5 }}>{isSimulating ? <Visibility /> : 'Olá,'}</span>
      <b>{greetingsName}</b>
      <ArrowDownIcon />
    </Fragment>
  );
};

export function UserButton(): React.ReactElement {
  const { member } = useSelector(getMemberSelector);
  const user = useSelector(getUserSelector);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSimulating = member && member.customer_id !== user.member?.customer_id;
  const greetingsName = member?.first_name || user.email;
  const isStaff = user.is_staff;

  const logout = () => navigate('/logout');

  return (
    <Fragment>
      {isStaff && <SearchContract />}
      <Button
        size="small"
        color="inherit"
        style={{ textTransform: 'initial' }}
        className="btn"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsMenuOpen(true);
        }}
      >
        <Greetings isSimulating={isSimulating} greetingsName={greetingsName} />
      </Button>
      <Menu id="menu" anchorEl={anchorEl} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        {isSimulating && [
          <MenuItem key={Math.random() * 100} onClick={() => clearMemberData(dispatch)}>
            <ListItemIcon>
              <VisibilityOff fontSize="small" />
            </ListItemIcon>
            <Typography>Sair da visualização</Typography>
          </MenuItem>,
          <Divider key={Math.random() * 100} style={{ marginTop: 8, marginBottom: 8 }} />,
        ]}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography>Sair</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
