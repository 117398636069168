import React from 'react'
import { AppBar, Toolbar } from '@material-ui/core'

import HeaderNav from './HeaderNav'

const Header = ({ ...props }) => {
  return (
    <AppBar position="fixed" className="topnav">
      <Toolbar
        variant="dense"
        style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
        className="topbar"
      >
        <HeaderNav {...props} />
      </Toolbar>
    </AppBar>
  )
}
export default Header
