const initialState = {
  isLoading: false,
  isProcessing: false,
  successMessage: null,
  failedMessage: null,
  historyIsLoading: false,
  items: [],
  transactions: [],
  transactionHistory: [],
  selectedTransaction: null,
  error: null,
};

const exchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_TRANSACTION':
      return {
        ...state,
        selectedTransaction: action.payload,
        successMessage: null,
        failedMessage: null,
      };

    case 'TRANSACTION_GIVEUP':
    case 'TRANSACTION_CONFIRM_PAYMENT':
    case 'TRANSACTION_CONFIRM':
      return {
        ...state,
        error: null,
        isProcessing: true,
        successMessage: null,
        failedMessage: null,
      };
    case 'TRANSACTION_PROCESS_SUCCESS':
      return {
        ...state,
        successMessage: action.payload,
        isProcessing: false,
        selectedTransaction: null,
      };
    case 'TRANSACTION_PROCESS_FAILED':
      let errors = [];
      switch (typeof action.errors) {
        case 'object':
          errors = Object.values(action.errors).map((error) => {
            return error;
          });
          errors = errors.join(', ');
          break;

        default:
          errors = action.errors;
          break;
      }

      return {
        ...state,
        failedMessage: errors,
        isProcessing: false,
      };

    case 'TRANSACTIONS_HISTORY_LOADING':
      return { ...state, error: null, historyIsLoading: true };
    case 'TRANSACTIONS_LOADING':
    case 'EXCHANGES_LOADING':
      return { ...state, error: null, isLoading: true };
    case 'EXCHANGES_LOADED':
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    case 'TRANSACTIONS_LOADED':
      return {
        ...state,
        isLoading: false,
        transactions: action.payload,
      };
    case 'TRANSACTIONS_HISTORY_LOADED':
      let items = state.transactionHistory.items || [];
      if (!action.direction) {
        items = action.payload.results;
      } else {
        Object.values(action.payload.results).map(
          (result) => !items.find((item) => item.id === result.id) && items.push(result)
        );
        items.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
      }

      return {
        ...state,
        historyIsLoading: false,
        transactionHistory: {
          count: action.payload.count,
          next: action.payload.next,
          items: items,
        },
      };
    case 'TRANSACTIONS_HISTORY_FAILED':
    case 'TRANSACTIONS_FAILED':
    case 'EXCHANGES_FAILED':
      return { ...state, historyIsLoading: false };

    case 'CLEAR_MESSAGES': {
      return { ...state, successMessage: '', failedMessage: '' };
    }

    default:
      return state;
  }
};
export default exchangeReducer;
