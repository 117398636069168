import React, { Fragment } from 'react';
import moment from 'moment';

const DATE_FORMAT = 'DD MMM';
const DATE_FORMAT_FULL = 'DD MMM YYYY';
const DATE_JOIN = ' a ';

function formatPeriods(periodsGranted, handleClick) {
  let result = [],
    multiple = false;

  periodsGranted.forEach((item) => {
    const year = item.year;
    let resultIdx = result.push({ year: year, ranges: [] }) - 1;

    item.ranges.forEach((range, idx, self) => {
      const length = self.length;
      if (length > 1) multiple = true;
      const [date1, date2] = [moment(range[0]).weekday(5), moment(range[1]).weekday(6)];
      const prevDate = idx > 0 && moment(self[idx - 1][range[1]]).weekday(6);
      let chip = (
        <span
          className={`${multiple ? 'btn btn--small btn--primary outlined' : 'card__text small'}`}
          onClick={() => length > 1 && handleClick(date1)}
        >
          {!prevDate && (
            <>
              <b>{date1.format(date1.year() !== year ? DATE_FORMAT_FULL : DATE_FORMAT)}</b>
              {DATE_JOIN} <b>{date2.format(length === 1 ? DATE_FORMAT_FULL : DATE_FORMAT)}</b>
            </>
          )}
          {prevDate && idx < length - 1 && (
            <>
              <b>{date1.format(DATE_FORMAT)}</b> {DATE_JOIN} <b>{date2.format(DATE_FORMAT)}</b>
            </>
          )}

          {idx > 0 && idx === length - 1 && (
            <>
              <b>{date1.format(DATE_FORMAT)}</b>
              {DATE_JOIN}
              <b>{date2.format(date2.year() !== year ? DATE_FORMAT_FULL : DATE_FORMAT)}</b>
            </>
          )}
        </span>
      );

      result[resultIdx]['ranges'].push(chip);
    });
  });

  return result.map((item) => (
    <div key={item.year} className={`grid ${multiple ? 'mt-8' : ''}`}>
      <div className="grid__container spacing-8">
        {item.ranges.map((r, i) => (
          <div key={i} className="grid__item">
            {r}
          </div>
        ))}
      </div>
    </div>
  ));
}

export function ContractAllowedPeriod({ periodsGranted, handleClick }) {
  return <Fragment>{formatPeriods(periodsGranted, handleClick)}</Fragment>;
}

export default ContractAllowedPeriod;
