import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setMember } from '../Members/actions/membersActions'
import { setHeadersToken } from '../utils/helpers/store'
import ContractList from './ContractList'
import { BASE_API_URL } from './urls'
import { TransitionSlideUp } from '../utils/Transitions'

const SearchContractDialog = (props) => {
  const { open, handleClose, setMember } = props
  const [isLoading, setIsLoading] = useState(false)
  const [_, setContract] = useState(null)
  const [contracts, setContracts] = useState(null)
  const [resultCount, setResultCount] = useState(0)
  const [inputContract, setInputContract] = useState('')

  useEffect(() => {
    let timer
    if (inputContract.length >= 3) {
      timer = setTimeout(() => {
        setIsLoading(true)
        setContracts(null)
        setResultCount(0)
        axios
          .get(`${BASE_API_URL}${inputContract}`, {
            headers: setHeadersToken(),
          })
          .then((res) => {
            setContracts(res.data.results)
            setResultCount(res.data.count)
          })
          .catch((error) => console.log(error))
          .finally(() => setIsLoading(false))
      }, 800)
    } else {
      setContracts(null)
      setResultCount(null)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [inputContract])

  const handleSetMember = (member) => {
    setMember(member)
    handleClose()
  }

  const handleCloseDialog = () => {
    setContracts(null)
    setInputContract('')
    handleClose()
  }
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      TransitionComponent={TransitionSlideUp}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
    >
      <DialogTitle id="alert-dialog-title">
        <div
          className="grid__container  --space-between spacing-16"
          style={{ flexWrap: 'nowrap' }}
        >
          <div className="grid__item mt-8" style={{ flex: '1 auto' }}>
            <TextField
              fullWidth
              autoFocus
              placeholder="Pesquisar contrato"
              onChange={(e) => setInputContract(e.target.value)}
              value={inputContract}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    variant="filled"
                    position="end"
                    style={{ color: '#999' }}
                  >
                    {isLoading && (
                      <CircularProgress
                        style={{ height: 20, width: 20, marginRight: 4 }}
                      />
                    )}
                    <Search />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: Boolean(inputContract),
              }}
            />
            <ul
              className="text-mutted pt-8"
              style={{ fontSize: 12, margin: 0 }}
            >
              <li>Mínimo de 3 caracteres</li>
              <li>
                Pode pesquisar por: Nº do contrato, Nº de referência, nome,
                emails e telefones
              </li>
              <li>
                Não precisa pressionar ENTER nem clicar no ícone de pesquisa
              </li>
              <li>A pesquisa não diferencia maíusculas de minúsculas</li>
              <li>
                Pesquise por nome composto utilizando aspas duplas ex: "João
                Miguel"
              </li>
            </ul>
          </div>
          <div className="grid__item">
            <IconButton onClick={handleCloseDialog}>
              <Close />
            </IconButton>
          </div>
        </div>
        {contracts && (
          <div className="mt-16">
            <p className="text-mutted" style={{ fontSize: 12 }}>
              <b>{resultCount}</b> encontrado(s){' '}
              {contracts &&
                contracts.length > 0 &&
                contracts.length < resultCount && (
                  <Fragment>
                    | exibindo <b>{contracts.length}</b>
                  </Fragment>
                )}
            </p>
          </div>
        )}
      </DialogTitle>
      {((contracts && contracts.length > 0) || isLoading) && (
        <DialogContent>
          <div className="pb-24">
            <ContractList
              contracts={contracts}
              isLoading={isLoading}
              inputContract={inputContract}
              setMember={handleSetMember}
              viewContract={setContract}
            />
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    setMember: (member) => dispatch(setMember(member)),
  }
}
export default connect(null, mapDispatchToProps)(SearchContractDialog)
