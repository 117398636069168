import { Navigate } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LockOutlined as PassIcon } from '@material-ui/icons';

import { setNewPassword } from '../../components/Auth/actions/authActions';
import Base from './Base';
import Input from '../../components/utils/Input';
import { Preloader } from '../../components/utils/Preloader';
import PassStrengthBar from '../../components/react-pass-strengh-bar/PassStrengthBar';

class ResetPassword extends Component {
  state = {
    password: '',
    password_confirm: '',
    errors: [],
  };

  componentWillMount() {
    const full_url = window.location.pathname,
      url = full_url.split('/').filter((item) => item.length > 0);
    this.setState({
      token: url.slice(-1).toString(),
    });
  }

  handleSetNewPassword = (e) => {
    e.preventDefault();
    const { token, password, password_confirm } = this.state;
    const data = {
      token: token,
      password: password,
      password_confirm: password_confirm,
    };
    this.props.setNewPassword(data);
  };
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };
  render() {
    if (this.props.auth.passwordChanged === true) return <Navigate to="/login" />;
    return (
      <Base>
        {this.props.auth.passwordChanged === false && (
          <div className="grid__item xs-12">
            {this.props.auth.isLoading === true ? (
              <Preloader />
            ) : (
              <form onSubmit={this.handleSetNewPassword}>
                <div className="grid__container spacing-24">
                  <div className="grid__item xs-12">
                    <p className="card__text">Insira sua nova senha.</p>
                  </div>
                  <div className="grid__item xs-12">
                    <Input
                      name="password"
                      type="password"
                      label="Nova senha"
                      value={this.state.password}
                      handleChange={this.handleChange}
                      errors={this.props.errors}
                      icon={<PassIcon />}
                      required
                    />
                    <PassStrengthBar value={this.state.password} />
                  </div>
                  <div className="grid__item xs-12">
                    <Input
                      name="password_confirm"
                      type="password"
                      label="Confirme a nova senha"
                      value={this.state.password_confirm}
                      handleChange={this.handleChange}
                      errors={this.props.errors}
                      icon={<PassIcon />}
                      required
                    />
                  </div>
                  <div className="grid__item xs-12">
                    <div className="grid__container stepper__footer-actions">
                      <div className="grid__item action-btn-wrapper">
                        <button type="submit" className={`btn btn--primary raised action-btn`}>
                          Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
      </Base>
    );
  }
}

const mapStateToProps = (state) => {
  let errors = {};
  if (state.auth.errors) {
    Object.keys(state.auth.errors).map((field) => (errors[field] = state.auth.errors[field]));
  }

  return {
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewPassword: (data) => dispatch(setNewPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
