import React from 'react'
import nophoto from '../../assets/img/nophoto.jpg'

const Media = ({ src, alt = 'no photo', className = 'media' }) => {
  return (
    <img className={className} src={src !== 'null' ? src : nophoto} alt={alt} />
  )
}

export default Media
