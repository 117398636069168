import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Preloader } from '../../components/utils/Preloader';

import {
  login,
  findUserByEmail,
  findMember,
  createUser,
  sendPasswordRecover,
} from '../../components/Auth/actions/authActions';
import { SwipeStep } from '../../components/utils/SwipeStep';
import AuthStepCheckUser from './AuthStepCheckUser';
import AuthStepInsertPass from './AuthStepInsertPass';
import AuthStepPassRecoverySent from './AuthStepPassRecoverySent';
import AuthStepUserNotFound from './AuthStepUserNotFound';

function getSteps() {
  return [0, 1, 2];
}

export const LoginStepper = (props) => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    password_confirm: '',
    contract: '',
    customer_id: '',
  });
  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = (step) => {
    if (step) {
      setActiveStep(step);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleCredentialsChange = (name) => (e) => {
    let value = typeof e === 'object' ? e.target.value : e;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleCheckUser = (e) => {
    e.preventDefault();
    props.findUserByEmail(credentials.email);
    handleNext();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    props.login(credentials.email, credentials.password);
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    let customer_id;
    if (credentials.customer_id) customer_id = credentials.customer_id;
    if (props.auth.memberFoundWithoutUser !== false) customer_id = props.auth.memberFoundWithoutUser.customer_id;

    props.createUser(credentials.email, credentials.password, credentials.password_confirm, customer_id);
  };

  const handleFindMember = (e) => {
    e.preventDefault();
    let customer_id;
    if (credentials.customer_id) customer_id = credentials.customer_id;
    if (props.auth.memberFoundWithoutUser !== false) customer_id = props.auth.memberFoundWithoutUser.customer_id;

    props.findMember(customer_id, credentials.contract);
  };

  const hanldeSendPasswordRecovery = () => {
    props.sendPasswordRecover(credentials.email);
  };

  const setStepContent = (step, activeStep) => {
    switch (step) {
      case 0:
        return (
          <AuthStepCheckUser
            step={step}
            activeStep={activeStep}
            handleInputChange={handleCredentialsChange}
            handleOnSubmit={handleCheckUser}
            credentials={credentials}
            props={props}
          />
        );
      case 1:
        return props.auth.userExists ? (
          props.auth.recoverPasswordSent === true ? (
            <AuthStepPassRecoverySent />
          ) : (
            <AuthStepInsertPass
              step={step}
              activeStep={activeStep}
              handleOnSubmit={handleLogin}
              handleInputChange={handleCredentialsChange}
              handleBack={handleBack}
              credentials={credentials}
              props={props}
              hanldeSendPasswordRecovery={hanldeSendPasswordRecovery}
            />
          )
        ) : (
          <AuthStepUserNotFound
            activeStep={activeStep}
            handleBack={handleBack}
            props={props}
            credentials={credentials}
            handleCreateUser={handleCreateUser}
            handleFindMember={handleFindMember}
            handleInputChange={handleCredentialsChange}
          />
        );

      default:
        return '';
    }
  };

  return (
    <SwipeableViews axis={'x'} index={activeStep} disabled={true} style={{ width: '100%' }}>
      {getSteps().map((step, index) => (
        <Fragment key={index}>
          {props.server.errors ? (
            props.server.errors.text
          ) : Math.abs(activeStep - index) <= 2 ? (
            <SwipeStep step={index} activeStep={activeStep}>
              {props.auth.isLoading ? <Preloader /> : setStepContent(index, activeStep)}
            </SwipeStep>
          ) : null}
        </Fragment>
      ))}
    </SwipeableViews>
  );
};

const mapStateToProps = (state) => {
  let errors = {};
  if (state.auth.errors) {
    Object.keys(state.auth.errors).map((field) => (errors[field] = state.auth.errors[field]));
  }

  return {
    server: state.server,
    auth: state.auth,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    createUser: (email, password, password_confirm, customer_id) =>
      dispatch(createUser(email, password, password_confirm, customer_id)),
    findUserByEmail: (email) => dispatch(findUserByEmail(email)),
    sendPasswordRecover: (email) => dispatch(sendPasswordRecover(email)),
    findMember: (customer_id, contract) => dispatch(findMember(customer_id, contract)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginStepper);
