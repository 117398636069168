import React from 'react'
import Input from '../../components/utils/Input'
import FormBase from './FormBase'

const FindMemberForm = ({
  contract,
  hide_customer_id,
  customer_id,
  errors,
  handleSubmit,
  handleChange,
  handleBack,
}) => {
  return (
    <FormBase handleSubmit={handleSubmit} handleBack={handleBack}>
      <div className="grid__container grid__container--column spacing-24">
        <div className="grid__item">
          <Input
            name="contract"
            label="Nº Contrato"
            value={contract}
            handleChange={handleChange}
            errors={errors}
            required
            autoFocus={true}
          />
        </div>
        {!hide_customer_id && (
          <div className="grid__item">
            <Input
              name="customer_id"
              label="Nº de Identificação"
              value={customer_id}
              handleChange={handleChange}
              errors={errors}
              required
            />
          </div>
        )}
      </div>
    </FormBase>
  )
}

export default FindMemberForm
