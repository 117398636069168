import axios from 'axios';
import { BASE_API_URL } from '../urls';
import { handleErrors } from '../../utils/helpers/errors';
import { setHeadersToken } from '../../utils/helpers/store';

export const loadContracts = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CONTRACTS_LOADING' });
    const customerId = getState().members?.member?.customer_id;
    if (customerId) {
      axios
        .get(`${BASE_API_URL}${customerId}`, {
          headers: setHeadersToken(),
        })
        .then((res) => dispatch({ type: 'CONTRACTS_LOADED', payload: res.data }))
        .catch((error) => handleErrors(error, dispatch));
    } else {
      dispatch({ type: 'CONTRACTS_LOADED', payload: [] });
    }
  };
};
