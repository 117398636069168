import React from 'react'

const FormActionButtons = ({ handleBack }) => {
  return (
    <div className="grid__container stepper__footer-actions">
      <div className="action-btn-wrapper">
        <button
          type="button"
          className="btn btn--primary outlined action-btn"
          onClick={handleBack}
        >
          Voltar
        </button>
      </div>

      <div className="action-btn-wrapper">
        <button type="submit" className="btn btn--primary raised action-btn">
          Próximo
        </button>
      </div>
    </div>
  )
}

export default FormActionButtons
