import React from 'react'
import { connect } from 'react-redux'

import 'moment/locale/pt'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import NumberFormat from 'react-number-format'

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Input,
  Collapse,
  FormHelperText,
} from '@material-ui/core'

import ResponsiveDialog from '../utils/ResponsiveDialog'

import { RULE_DIALOGS } from './ExchangeDialog'

const ExchangeRuleDialog = (props) => {
  const {
    handleCloseDialog,
    propertyTypes,
    rule,
    open,
    handleSaveRule,
    handleChangeRuleInput,
    ruleDialogType,
    errors,
  } = props

  return (
    <ResponsiveDialog
      open={open}
      handleCloseDialog={handleCloseDialog}
      hideCloseButton={true}
      dialogTitle={
        <>
          <span>
            <img
              src={ruleDialogType && RULE_DIALOGS[ruleDialogType]['icon']}
              alt="Regra"
              style={{ width: 100 }}
            />
          </span>
          <span>{ruleDialogType && RULE_DIALOGS[ruleDialogType]['title']}</span>
        </>
      }
      actions={
        <>
          <button
            className="btn btn--primary outlined action-btn"
            onClick={handleCloseDialog}
          >
            Cancelar
          </button>
          <button
            className="btn btn--primary raised action-btn"
            onClick={handleSaveRule}
          >
            Salvar
          </button>
        </>
      }
    >
      <div className="dialog__body">
        <div className="grid__container grid__container--column spacing-40">
          {ruleDialogType !== 'value' && (
            <div className="grid__item">
              <Collapse in={ruleDialogType !== 'value'}>
                <div className="grid__container grid__container--column spacing-40">
                  <div className="grid__item">
                    <p className="text-mutted">
                      Selecione o Empreendimento (resort e tipologia)
                    </p>
                    <FormControl
                      style={{ width: 160 }}
                      className="form-group"
                      error={errors && errors['property_type'] && true}
                    >
                      <InputLabel htmlFor="property_type">
                        Empreendimento
                      </InputLabel>
                      <Select
                        value={
                          rule && rule.property_type ? rule.property_type : ''
                        }
                        onChange={handleChangeRuleInput}
                        input={
                          <Input name="property_type" id="property_type" />
                        }
                      >
                        {Object.values(propertyTypes).length > 0 &&
                          Object.values(propertyTypes).map((property) => (
                            <MenuItem key={property.id} value={property.id}>
                              {property.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors && errors['property_type'] && (
                        <FormHelperText>
                          {errors['property_type']}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className="grid__item">
                    <p className="text-mutted">Selecione o período</p>
                    <div className="grid__container spacing-16 --align-center">
                      <div className="grid__item" style={{ maxWidth: 110 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            label="De"
                            name="range_start"
                            id="range_start"
                            error={
                              errors && errors['range_start'] ? true : false
                            }
                            helperText={errors && errors['range_start']}
                            disablePast={true}
                            value={rule && rule.range_start}
                            format="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangeRuleInput('range_start', date)
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="grid__item" style={{ maxWidth: 110 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            label="Até"
                            name="range_end"
                            id="range_end"
                            error={errors && errors['range_end'] ? true : false}
                            helperText={errors && errors['range_end']}
                            disablePast={true}
                            value={rule && rule.range_end}
                            format="DD/MM/YYYY"
                            onChange={(date) =>
                              handleChangeRuleInput('range_end', date)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          )}
          {ruleDialogType && ruleDialogType.toLowerCase().includes('value') && (
            <div className="grid__item">
              <p className="text-mutted">Informe o valor</p>
              <FormControl
                style={{ width: 90 }}
                error={errors && errors['fee'] && true}
              >
                <InputLabel shrink htmlFor="fee">
                  Valor
                </InputLabel>
                <NumberFormat
                  id="fee"
                  name="fee"
                  value={rule && rule.fee}
                  onChange={handleChangeRuleInput}
                  customInput={Input}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  startAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                  }
                />
                {errors && errors['fee'] && (
                  <FormHelperText className="--t-alert">
                    {errors['fee']}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        </div>
      </div>
    </ResponsiveDialog>
  )
}
const mapStateToProps = (state) => {
  return {
    propertyTypes: state.resorts.propertyTypes,
  }
}
export default connect(mapStateToProps)(ExchangeRuleDialog)
