const USER_LOCALE = navigator.language || navigator.userLanguage

export const LOCALES = ['en', 'pt-BR']
export const DEFAULT_LOCALE = LOCALES.includes(USER_LOCALE) ? USER_LOCALE : 'en'

export const PATTERNS = [
  {
    pattern: /[a-z]/g,
    tip: {
      en: 'A lowercase letter',
      'pt-BR': 'Uma letra minúscula',
    },
  },
  {
    pattern: /[A-Z]/g,
    tip: {
      en: 'An uppercase letter',
      'pt-BR': 'Uma letra maiúscula',
    },
  },
  {
    pattern: /[0-9]/g,
    tip: {
      en: 'A number',
      'pt-BR': 'Um número',
    },
  },
  {
    //eslint-disable-next-line
    pattern: /[{}\`~$&+,:;=?@#|\'<>\.\^*()%!\-\[\]\(\)]/g,
    tip: {
      en: 'A special char i.e.: {}~$&+,:;=?@#|<>.^*()%!-[]()',
      'pt-BR': 'Um caractere especial ex: {}~$&+,:;=?@#|<>.^*()%!-[]()',
    },
  },
]

export const RESPONSES = [
  {
    level: 3,
    className: 'strong',
    charRange: [8],
    text: {
      en: 'Strong password, contratulations!',
      'pt-BR': 'Senha forte, parabéns!',
    },
  },
  {
    level: 2,
    text: {
      en: 'Good password',
      'pt-BR': 'Senha boa',
    },
    className: 'good',
    charRange: [7],
  },
  {
    level: 1,
    className: 'medium',
    charRange: [5, 6],
    text: {
      en: (
        <span>
          <b>Medium password</b>
          <br />
          Not good yet.
        </span>
      ),
      'pt-BR': (
        <span>
          <b>Senha média</b>
          <br />
          Ainda não está boa.
        </span>
      ),
    },
  },
  {
    level: 0,
    className: 'bad',
    charRange: [0, 1, 2, 3, 4],
    text: {
      en: (
        <span>
          <b>Weak password </b>
          <br />
          Watch out! Evil-minded people may, easily, hack your account!
        </span>
      ),
      'pt-BR': (
        <span>
          <b>Senha fraca</b>
          <br />
          Cuidado! Pessoas mal intensionadas podem invadir, facilmente, a sua
          conta!
        </span>
      ),
    },
  },
]

export const MIN_CHARS_TEXT = {
  en: `At least [minChars] chars`,
  'pt-BR': `Ao menos [minChars] caracteres`,
  getText: function (minChars, locale = DEFAULT_LOCALE) {
    return this[locale]?.replace('[minChars]', minChars)
  },
}

export const TIPS_TITLE = {
  en: 'Tips for a strong password',
  'pt-BR': 'Dicas para uma senha forte',
}
