const initialState = {
  isLoading: true,
  errors: null,
  data: null,
};

const siteConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SITE_CONFIG_LOADING':
      return { ...state, errors: null, isLoading: true };
    case 'SITE_CONFIG_LOADED':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload },
      };

    case 'SITE_CONFIG_FAILED':
      return {
        ...state,
        isLoading: false,
      };

    case 'MAINTENANCE_CHECK_LOADED': {
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    }

    default:
      return state;
  }
};
export default siteConfigReducer;
