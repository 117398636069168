import React, { useState } from 'react'
import { Preloader } from '../utils/Preloader'

const Vacancies = ({
  vacancies,
  roomSelected,
  handleRoomClick,
  isLoading,
  isBooking,
}) => {
  let error_msg =
    vacancies && vacancies.hasOwnProperty('error') && vacancies['error']

  const [roomName, setRoomName] = useState('')

  if (isBooking && roomSelected)
    return (
      <>
        <p className="body_2 --ta-center --nomargin">Empreendimento</p>
        <p className="--ta-center">
          <b>{roomName}</b>
        </p>
      </>
    )

  return (
    <>
      {!error_msg && Object.keys(vacancies).length > 0 ? (
        <div className="grid__container grid__container--column spacing-40">
          <div className="grid__item">
            <p className="card__text --ta-center" id="helper-property">
              Selecione o Empreendimento
            </p>
            <div className="grid__container spacing-16 --justify-center">
              {Object.keys(vacancies).map((resortName) =>
                Object.values(vacancies[resortName]).map((vacancy, i) => (
                  <div className="grid__item xs-6 sm-auto" key={i}>
                    <button
                      key={i}
                      className={`${
                        roomSelected === vacancy.id
                          ? 'booking__button--selected'
                          : 'booking__button'
                      }`}
                      onClick={() => {
                        handleRoomClick(vacancy.id)
                        setRoomName(`${resortName} - ${vacancy.tipology}`)
                      }}
                    >
                      <div className="btn__content">
                        <label className="booking__button-caption">
                          {resortName}
                        </label>
                        <span className="booking__button-apt">
                          {vacancy.tipology}
                        </span>
                      </div>
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
          {isLoading && (
            <div className="grid__item" id="confirm">
              <Preloader />
            </div>
          )}
        </div>
      ) : (
        <p className="--t-alert --t-bold --ta-center">
          {error_msg ? error_msg : 'Não existe disponibilidade para esta data.'}
        </p>
      )}
    </>
  )
}

export default Vacancies
