import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import PaymentDialog from '../Payments/PaymentDialog';

import Invoice from './Invoice';
import { getInvoicesSelector } from './selectors';
import { Invoice as InvoiceType } from './types';

export function Invoices(): React.ReactElement | null {
  const { items: invoices } = useSelector(getInvoicesSelector);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceType | null>(null);

  const hasInvoices = Object.keys(invoices).length > 0;

  if (!hasInvoices) {
    return null;
  }

  return (
    <Fragment>
      {Object.values(invoices).map((invoice, index) => (
        <div
          key={invoice.id}
          className="grid__item xs-12 sm-6 md-4 animated zoomIn faster"
          style={{ animationDelay: `${(index + 1) * 0.1}s` }}
        >
          <Invoice invoice={invoice} handlePayClick={() => setSelectedInvoice(invoice)} />
        </div>
      ))}
      <PaymentDialog
        invoice={selectedInvoice}
        open={Boolean(selectedInvoice)}
        handlePaymentClose={() => setSelectedInvoice(null)}
      />
    </Fragment>
  );
}

export default Invoices;
