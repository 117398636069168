import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ResponsiveDialog from '../../utils/ResponsiveDialog';
import Guide from './Guide';
import { loadGuides } from '../actions/communicationsActions';

const Guides = (props) => {
  const [openGuideDialog, setopenGuideDialog] = useState(Boolean(props.guides));
  const { loadGuides } = props;
  useEffect(() => {
    loadGuides();
  }, [loadGuides]);

  if (!props.guides.length > 0) return null;

  return Object.values(props.guides).length > 0 ? (
    <ResponsiveDialog transition="grow" open={openGuideDialog}>
      {Object.values(props.guides).map((guide, index) => (
        <Guide key={index} guide={guide} handleClose={() => setopenGuideDialog(false)} />
      ))}
    </ResponsiveDialog>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    guides: state.communications.guides,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadGuides: () => dispatch(loadGuides()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Guides);
