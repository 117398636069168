import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SiteConfig } from './@types';
import { Error } from './components/Error';
import { maintenanceCheck } from './components/utils/actions/siteConfigActions';
import { Preloader } from './components/utils/Preloader';
import { getSiteConfigSelector } from './selectors';

const Logo: React.FC<{ siteConfig: SiteConfig }> = ({ siteConfig }) => (
  <img
    src={siteConfig.logo}
    alt={siteConfig.title}
    style={{
      maxHeight: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 150,
    }}
  />
);

export function getIsLocked(siteConfig: SiteConfig) {
  /** Programmed lock/release */
  const { lock_at_date, lock_at_time, release_at_date, release_at_time } = siteConfig;

  let lockedByDate = lock_at_date ? moment(lock_at_date).isSameOrBefore(moment()) : true;
  let lockedByTime = lock_at_time ? moment(lock_at_time, '"HH:mm:ss').isSameOrBefore(moment()) : true;
  let isLocked = lockedByDate || lockedByTime || (lockedByDate && lockedByTime);

  let releasedByDate = release_at_date ? moment(release_at_date).isSameOrBefore(moment()) : true;
  let releasedByTime = release_at_time
    ? moment(release_at_time, '"HH:mm:ss').isSameOrBefore(moment()) || (release_at_date && releasedByDate)
    : true;

  let isReleased = releasedByDate && releasedByTime;

  return {
    isLocked: isLocked && !isReleased,
    releaseAtDate: release_at_date,
    releaseAtTime: release_at_time,
  };
}

type MaintenanceProps = {
  children: React.ReactNode;
};
export function Maintenance({ children }: MaintenanceProps): React.ReactElement {
  const siteConfigAPI = useSelector(getSiteConfigSelector);
  const { isLoading, error, data: siteConfig } = siteConfigAPI;
  const isLoadingConfig = isLoading && !Boolean(error);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(maintenanceCheck());
  }, [dispatch, pathname]);

  if (isLoadingConfig && !siteConfig) {
    return <Preloader overlay={true} text="A carregar..." />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const { isLocked, releaseAtDate, releaseAtTime } = getIsLocked(siteConfig);
  const { maintenance, logo } = siteConfig;
  const inMaintenance = maintenance || isLocked;

  return (
    <Fragment>
      {inMaintenance ? (
        <section className="section" style={{ minHeight: '100vh' }}>
          <div className="container">
            {logo && (
              <div className="section__header">
                <Logo siteConfig={siteConfig} />
              </div>
            )}
            <div className="section__body --ta-center">
              <h1 className="section__title">Site em manutenção</h1>
              {isLocked && (
                <Fragment>
                  <p>O site estará disponível em </p>
                  {releaseAtDate && (
                    <p>
                      <b>{moment(releaseAtDate).format('DD [de] MMMM [de] YYYY, dddd')}</b>
                      {releaseAtTime && (
                        <Fragment>
                          {' '}
                          às <b>{moment(releaseAtTime, 'HH:mm:ss').format('HH:mm')}</b>
                        </Fragment>
                      )}
                    </p>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </section>
      ) : (
        children
      )}
    </Fragment>
  );
}

export default Maintenance;
