import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';

type HelpToolTipProps = {
  title: string;
  icon?: React.ReactElement;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

function HelpToolTip({ icon, title, placement = 'top' }: HelpToolTipProps): React.ReactElement {
  return (
    <Tooltip placement={placement} enterDelay={100} title={<div className="tooltip">{title}</div>}>
      <IconButton style={{ backgroundColor: 'transparent', padding: 0 }} aria-label="Ajuda">
        {icon ? icon : <HelpIcon color="primary" />}
      </IconButton>
    </Tooltip>
  );
}

export default HelpToolTip;
