import React from 'react'

import BookingDates from './BookingDates'

const BookingData = ({
  booking,
  property_type,
  checkin,
  checkout,
  calendarIconClass,
}) => {
  if (booking) {
    property_type = booking.property_type
    checkin = booking.checkin
    checkout = booking.checkout
  }
  return (
    <div className="grid__container grid__container--column spacing-8">
      {property_type && (
        <div className="grid__item">
          <div className="grid__container spacing-8">
            <div className="grid__item">
              <label className="overline">Empreendimento</label>
            </div>
            <div className="grid__item">
              <p className="--t-bold">{property_type.name}</p>
            </div>
          </div>
        </div>
      )}
      {checkin && checkout && (
        <div className="grid__item">
          <BookingDates
            hideLabel={true}
            checkin={checkin}
            checkout={checkout}
            calendarIconClass={calendarIconClass}
          />
        </div>
      )}
    </div>
  )
}

export default BookingData
