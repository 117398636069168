import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Preloader } from '../utils/Preloader';
import CustomExpansionPanel from '../utils/CustomExpansionPanel';

import TransactionCardSmall from './TransactionCardSmall';
import { loadHistoryTransactions } from './actions/exchangesActions';
import { getExchangesSelector } from './selectors';

export function TransactionsHistory(): React.ReactElement {
  const {
    transactionHistory: { items: transactions, count, next },
    isLoading,
  } = useSelector(getExchangesSelector);
  const dispatch = useDispatch();

  return (
    <CustomExpansionPanel
      title="Histórico"
      helperText="Histórico de transações concluídas (confirmadas ou canceladas)."
      expanded={false}
    >
      <div className="grid">
        <div className="grid__container grid__container--column spacing-8">
          {!transactions && isLoading ? (
            <Preloader />
          ) : (
            <Fragment>
              {count > 0 ? (
                Object.values(transactions).map((transaction, index) => (
                  <div
                    key={index}
                    className="grid__item animated zoomIn faster"
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    <TransactionCardSmall transaction={transaction} extraClass="hoverFadeIn" />
                  </div>
                ))
              ) : (
                <div className="grid__item">
                  <p className="section__subtitle section__subtitle--small">Ainda não existem transações.</p>
                </div>
              )}
              {next && (
                <div className="grid__item" style={{ textAlign: 'center' }}>
                  {isLoading ? (
                    <Preloader />
                  ) : (
                    <button
                      className="btn btn--primary outlined action-btn"
                      // @ts-ignore
                      onClick={() => dispatch(loadHistoryTransactions('next'))}
                    >
                      Carregar mais
                    </button>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </CustomExpansionPanel>
  );
}

export default TransactionsHistory;
