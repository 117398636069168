/**
 * Function to add class text-mutted to texts
 *
 * @param {string} id element id
 */
export function muteHelper(id) {
  let el = document.getElementById('helper-' + id)
  if (el) {
    let elClasses = el.classList

    if (!elClasses.contains('text-mutted')) {
      el.classList.add('text-mutted')
    }
  }
}

/**
 * Function to add infinite animation from Animate.css but
 * with delay between animation like: shake on every 3s
 *
 * @param {string} id element id
 * @param {string} animation name of animation
 * @param {int} duration
 * @param {int} delay
 */
export function animateInfinite(id, animation, duration, delay) {
  let el = document.getElementById(id),
    timer
  if (el) {
    el.classList.add('animated')
    function addClass() {
      el.classList.add(animation)
    }
    function removeClass() {
      el.classList.remove(animation)
    }
    setTimeout(() => {
      clearTimeout(timer)
      addClass()
      timer = setTimeout(removeClass, duration)
    }, duration + delay)
  }
}

/**
 * Function to format axios response error message
 *
 * @param {*} response
 */
export function handleErrorMessage(response) {
  let errorMessage = []

  if (response.status && response.status >= 500)
    errorMessage.push('Erro ' + response.status)

  if (response.data) {
    errorMessage.push(
      response.data.msg
        ? response.data.msg
        : response.data.non_field_errors
        ? response.data.non_field_errors.join('\r\n')
        : 'Erro desconhecido'
    )
  }

  return errorMessage.join('\r\n')
}
