import React from 'react'
import moment from 'moment'

import { AccessTime as CalendarIcon } from '@material-ui/icons'
import ExchangeBankLink from './ExchangeBankLink'

const UpcomingBooking = ({ booking }) => {
  return (
    <div className="grid__container grid__container--column spacing-8">
      <div className="grid__item">
        <div className="grid__container spacing-16 --align-center">
          <div className="grid__item">
            <p className="bigger --t-success">
              Daqui a <b>{moment(booking.checkin).fromNow(true)}</b>
            </p>
          </div>
          {booking.exchange && (
            <div className="grid__item">
              <ExchangeBankLink booking={booking} />
            </div>
          )}
        </div>
      </div>
      <div className="grid__item">
        <div className="grid__container grid__container--column spacing-8">
          {booking.property_type && (
            <div className="grid__item">
              <label className="overline">Empreendimento</label>
              <p className="--t-bold">{booking.property_type.name}</p>
            </div>
          )}
          {booking.checkin && booking.checkout && (
            <div className="grid__item">
              <div className="grid__container spacing-8 --align-center">
                <div
                  className="grid__item"
                  style={{ fontSize: '20px', display: 'flex' }}
                >
                  <CalendarIcon
                    className="defaultIconClass"
                    fontSize="inherit"
                  />
                </div>
                <div className="grid__item xs-10">
                  <p className="smaller">
                    {moment(booking.checkin).format('dddd, LL')}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UpcomingBooking
