import { Dispatch } from 'react';
import { AnyAction } from 'redux';

export function clearMemberData(dispatch: Dispatch<AnyAction>) {
  dispatch({ type: 'MEMBER_LOADED', payload: null });
  dispatch({ type: 'CONTRACTS_LOADED', payload: [] });
  dispatch({ type: 'INVOICES_LOADED', payload: [] });
  dispatch({ type: 'BOOKINGS_LOADED', payload: [] });
  dispatch({ type: 'EXCHANGES_LOADED', payload: [] });
  dispatch({ type: 'TRANSACTIONS_LOADED', payload: [] });
}
