import React from 'react'

import PaymentListItem from '../Payments/PaymentListItem'

const InvoicePayments = ({ payments }) => {
  return (
    <div className="grid__container grid__container--column spacing-16 mt-16">
      {payments.map((payment) => (
        <div
          className={`grid__item ${
            payment.status.slug === 'canceled' ? 'hoverFadeIn' : ''
          }`}
          key={payment.id}
          style={{
            borderTop: '1px solid #efefef',
          }}
        >
          <PaymentListItem payment={payment} />
        </div>
      ))}
    </div>
  )
}

export default InvoicePayments
