import React, { Fragment } from 'react';
import ResponsiveDialog, { ResponsiveDialogProps } from './ResponsiveDialog';

export function ConfirmDialog({
  open,
  handleYes,
  isLoading,
  handleCloseDialog,
  handleNo = handleCloseDialog,
  hideCloseButton,
  failedMessage,
  successMessage,
  title,
  text,
  textYes,
  textNo,
  children,
}: ResponsiveDialogProps): React.ReactElement {
  return (
    <ResponsiveDialog
      open={open}
      title={title}
      failedMessage={failedMessage}
      successMessage={successMessage}
      handleCloseDialog={handleCloseDialog}
      hideCloseButton={hideCloseButton}
      isLoading={isLoading}
      actions={
        <Fragment>
          <button className="btn btn--primary outlined action-btn" onClick={handleNo}>
            {textNo ? textNo : 'Não'}
          </button>

          <button className="btn btn--primary raised action-btn" onClick={handleYes}>
            {textYes ? textNo : 'Sim'}
          </button>
        </Fragment>
      }
    >
      {text && <p>{text}</p>}
      {children}
    </ResponsiveDialog>
  );
}

export default ConfirmDialog;
