const initialState = {
  isLoading: false,
  errors: null,
}

const serverReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SERVER_OK':
    case 'INTERNET_OK':
      return { ...state, errors: null, isLoading: false }

    case 'SERVER_ERROR_UNKNOWN':
      return {
        ...state,
        errors: { code: 666, text: 'Código 666 - Erro desconhecido.' },
        isLoading: false,
      }
    case 'SERVER_ERROR_500':
      return {
        ...state,
        errors: { code: 500, text: 'Código 500 - Erro de servidor' },
        isLoading: false,
      }

    case 'OFFLINE':
      return {
        ...state,
        errors: { code: 'off', text: 'Você está sem conexão.' },
        isLoading: false,
      }

    case 'ONLINE':
      return { ...initialState }

    default:
      return state
  }
}
export default serverReducer
