import axios from 'axios'
import { handleErrors } from '../helpers/errors'

export const checkServer = () => {
  return (dispatch, getState) => {
    if (getState().server.errors !== 'Offline') {
      dispatch({ type: 'SERVER_PING' })
      axios
        .get('/ping/')
        .then((res) => dispatch({ type: 'SERVER_OK' }))
        .catch((error) => handleErrors(error, dispatch))
    }
  }
}
export const setInternetConnection = (status) => {
  return (dispatch, getState) => {
    dispatch({ type: status })
  }
}
