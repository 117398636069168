import React, { Component } from 'react'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import axios from 'axios'
import { BASE_API_URL } from './urls'

import { withWidth } from '@material-ui/core'

import { setHeadersToken } from '../utils/helpers/store'
import ResponsiveDialog from '../utils/ResponsiveDialog'
import { loadExchanges } from './actions/exchangesActions'
import { loadBookings } from '../Bookings/actions/bookingsActions'
import ExchangeRuleDialog from './ExchangeRuleDialog'
import Rule from './Rule'

import moment from 'moment'

import RuleBooking from '../../assets/img/rule-booking.svg'
import RuleBookingValue from '../../assets/img/rule-booking-value.svg'
import RuleValue from '../../assets/img/rule-value.svg'
import CustomInput from '../utils/CustomInput'
import { Add as AddIcon } from '@material-ui/icons'

const DEFAULT_START_RANGE =
    moment() < moment('2019-01-01')
      ? moment('2019-01-01').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
  DEFAULT_END_RANGE = moment('2019-12-31').format('YYYY-MM-DD')

const NEW_RULE = {
  property_type: '',
  range_start: DEFAULT_START_RANGE,
  range_end: DEFAULT_END_RANGE,
  fee: '',
}

export const RULE_DIALOGS = {
  booking: {
    icon: RuleBooking,
    title: 'Troca por troca',
    text: 'Trocar sua reserva por outra sem complicações.',
    quote: 'Ex: Troco a minha reserva por um T1 em Carvoeiro em Março.',
  },
  bookingValue: {
    icon: RuleBookingValue,
    title: 'Troca com recebimento de valor',
    text:
      'Trocar sua reserva por outra e ainda receber um valor de compensação.',
    quote:
      'Ex: Troco a minha reserva por um T1 em Carvoeiro em Março e mais € 50.',
  },
  value: {
    icon: RuleValue,
    title: 'Aluguer',
    text: 'Alugar reserva por um valor.',
    quote: 'Ex: Alugo por € 300.',
  },
}

const iniState = {
  steps: '',
  stepsCount: 0,
  activeStep: 0,
  partnerSelected: null,
  isLoading: false,
  failedMessage: null,
  successMessage: null,
  openRule: false,
  ruleDialogType: null,
  errors: null,
  id: '',
  iban: '',
  rules: [],
  editingRule: null,
  tempRule: null,
  tempRuleErrors: null,
  showRuleButtons: true,
}

class ExchangeDialog extends Component {
  state = JSON.parse(JSON.stringify(iniState))

  hasFee = () => {
    if (
      this.state.rules &&
      this.state.rules.filter((rule) => parseFloat(rule.fee) > 0).length > 0
    )
      return true
    return false
  }
  handleSuccess = ({ message = null }) => {
    this.setState({
      isLoading: false,
      successMessage: message,
    })
    this.props.loadExchanges()
    this.props.loadBookings()
  }
  handleClose = () => {
    this.props.handleCloseDialog()
    setTimeout(() => {
      this.setState({ ...JSON.parse(JSON.stringify(iniState)) })
    }, 500)
  }
  showRuleButtons = () => {
    this.setState({
      showRuleButtons: true,
    })
  }
  stepsContent = () => {
    this.first_time = true
    if (
      this.props.booking &&
      this.props.booking.exchange_rules &&
      Object.keys(this.props.booking.exchange_rules).length > 0
    )
      this.first_time = false

    let content = {
      0: (
        <div className="dialog__body">
          <div className="wrapper wrapper--hidden">
            <div className="grid__container grid__container--column spacing-24">
              <div className="grid__item">
                <p>
                  Para depositar a sua reserva no nosso Banco de Trocas, precisa
                  definir uma ou várias regras, por ex:
                </p>
              </div>
              <div className="grid__item">
                <div className="grid__container grid__container--column spacing-32">
                  {Object.values(RULE_DIALOGS).map((rule, index) => (
                    <div className="grid__item" key={index}>
                      <div className="grid__container spacing-16 --align-center">
                        <div className="grid__item">
                          <img
                            src={rule.icon}
                            alt={rule.title}
                            style={{ width: 96 }}
                          />
                        </div>
                        <div className="grid__item xs-9">
                          <div className="grid__container grid__container--column">
                            <div className="grid__item">
                              <p className="--t-bold">
                                {rule.title}
                                <br />
                                <span className="body_2">{rule.quote}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      1: (
        <div className="dialog__body">
          <div className="wrapper wrapper--hidden">
            <div className="grid__container grid__container--column spacing-40">
              {this.state.rules.length > 0 && (
                <div className="grid__item">
                  <p>
                    <b>Regras</b>
                  </p>
                  <div className="grid__container grid__container--column">
                    {this.state.rules.map((rule, index) => (
                      <div
                        className="grid__item list__item list__item--small"
                        key={index}
                      >
                        <Rule
                          rule={rule}
                          index={index}
                          handleEdit={() =>
                            this.handleOpenRuleDialog({
                              open: true,
                              index: index,
                            })
                          }
                          handleDelete={() => this.handleDeleteRule(index)}
                        />
                      </div>
                    ))}
                  </div>
                  {this.hasFee() && (
                    <CustomInput
                      mask="**** **** **** **** **** **** **** ****"
                      id="iban"
                      name="iban"
                      placeholder="PT50"
                      className="form-group mt-16"
                      handleChange={this.handleChange}
                      value={this.state.iban}
                      type="text"
                      label="IBAN"
                      helperText="O seu IBAN será informado ao adquirente, certifique-se de o ter digitado corretamente."
                      errors={this.state.errors}
                      required
                      autoFocus={this.state.iban === '' && true}
                    />
                  )}
                </div>
              )}

              <div className="grid__item">
                {!this.state.id && !this.state.rules.length > 0 && (
                  <p>Selceione a regra.</p>
                )}
                <div className={`shrink ${this.state.showRuleButtons && 'in'}`}>
                  <button
                    className="btn btn--primary outlined"
                    onClick={this.showRuleButtons}
                  >
                    <div className="--ds-flex-row--align-center">
                      <AddIcon className="mr-8" />
                      <span>Adicionar nova regra</span>
                    </div>
                  </button>
                </div>
                <div
                  className={`shrink ${!this.state.showRuleButtons && 'in'}`}
                >
                  <div className="grid__container spacing-16">
                    {Object.keys(RULE_DIALOGS).map((key, index) => (
                      <div className="grid__item xs-6 sm-4" key={index}>
                        <button
                          className="btn btn--primary outlined btn--rule"
                          onClick={() =>
                            this.handleOpenRuleDialog({
                              open: true,
                              ruleType: key,
                            })
                          }
                        >
                          <img
                            src={RULE_DIALOGS[key]['icon']}
                            alt={RULE_DIALOGS[key]['title']}
                            className="icon"
                          />
                          <p className="text">{RULE_DIALOGS[key]['title']}</p>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    }

    !this.first_time && delete content[0]

    return content
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) this.updateState()
  }

  updateState = (bookingProps) => {
    let state = {
        stepsCount: Object.keys(this.stepsContent()).length,
      },
      booking = bookingProps ? bookingProps : this.props.booking

    if (booking) {
      if (booking.exchange_rules) {
        state['rules'] = Object.values(booking.exchange_rules).map((rule) => {
          rule['fee'] = rule.fee && parseFloat(rule.fee)
          return rule
        })
        state['showRuleButtons'] = false
      }
      if (booking.exchange) {
        state['iban'] = booking.exchange.iban
        state['id'] = booking.exchange.id
      }
    }
    this.setState({
      ...state,
      isLoading: false,
    })
  }

  getStep = (step) => {
    switch (step) {
      case !isNaN(+step):
        return step
      case 'next':
        return this.state.activeStep >= this.state.stepsCount
          ? 0
          : this.state.activeStep + 1
      case 'back':
        return this.state.activeStep > 0 ? this.state.activeStep - 1 : 0
      default:
        return 0
    }
  }
  handleChangeStep = (step) => {
    this.setState({
      activeStep: this.getStep(step),
    })
  }
  handleDeleteRule = (index) => {
    let rules = this.state.rules
    rules.splice(index, 1)
    this.setState({ rules: rules })
  }
  handleSaveRule = () => {
    const { rules, tempRule, editingRule, ruleDialogType } = this.state
    let tempRuleErrors = {}
    if (tempRule) {
      /**
       * Errors handling
       */
      if (ruleDialogType !== 'value') {
        if (tempRule.property_type !== (null || '')) {
          if (!tempRule.range_start)
            tempRuleErrors['range_start'] = 'Selecione a data de início'
          if (!tempRule.range_end)
            tempRuleErrors['range_end'] = 'Selecione a data de fim'
        }
        if (tempRule.property_type === (null || ''))
          tempRuleErrors['property_type'] = 'Selecione o tipo de empreendimento'
      }

      if (
        ruleDialogType.toLowerCase().includes('value') &&
        tempRule.fee === (null || '')
      )
        tempRuleErrors['fee'] = 'Informe o valor'

      if (Object.values(tempRuleErrors).length > 0)
        return this.setState({ tempRuleErrors: tempRuleErrors })

      if (
        tempRule.fee &&
        (!tempRule.property_type || tempRule.property_type === '')
      ) {
        tempRule.property_type = tempRule.range_start = tempRule.range_end = null
      }

      if (editingRule !== null) {
        rules[editingRule] = tempRule
      } else {
        rules.push(tempRule)
      }

      this.setState({
        ruleDialogType: null,
        openRule: false,
        rules: rules,
        editingRule: null,
        tempRule: null,
        tempRuleErrors: null,
        showRuleButtons: false,
      })
    }
  }

  getRuleByIndex = (index = null) => {
    if (index !== null) return { ...this.state.rules[index] }
    return { ...NEW_RULE }
  }

  handleOpenRuleDialog = ({ open, ruleType, index = null }) => {
    let tempRule = this.getRuleByIndex(index)

    if (!ruleType) {
      if (tempRule.property_type === ('' || null) && tempRule.fee > 0) {
        ruleType = 'value'
      } else if (tempRule.property_type !== ('' || null) && tempRule.fee > 0) {
        ruleType = 'bookingValue'
      } else {
        ruleType = 'booking'
      }
    }

    if (ruleType === 'value') {
      tempRule.range_start = null
      tempRule.range_end = null
      tempRule.property_type = ''
      this.setState({
        tempRule: tempRule,
      })
    }

    this.setState({
      openRule: open,
      editingRule: index,
      tempRule: tempRule,
      tempRuleErrors: null,
      ruleDialogType: ruleType,
    })
  }

  handleChange = (prop) => (event) => {
    let value = event.target.value
    if (value && prop === 'iban') {
      value = value.toUpperCase()
    }
    return this.setState({ [prop]: value })
  }

  handleChangeRuleInput = (event, date = null) => {
    let tempRule = this.state.tempRule
    if (typeof event === 'string' && date !== null) {
      tempRule[event] = date.format('YYYY-MM-DD')
    } else {
      tempRule[event.target.name] = event.target.value
    }
    this.setState({ tempRule: tempRule })
  }

  handleConfirm = () => {
    const data = {
      booking: this.props.booking.id,
      rules: this.state.rules,
      iban: this.state.iban,
      id: this.state.id,
    }
    this.setState({ isLoading: true }, () => {
      axios({
        method: data.id ? 'patch' : 'post',
        url: `${BASE_API_URL}${this.props.memberId}/${
          data.id ? data.id + '/' : ''
        }`,
        data,
        headers: setHeadersToken(),
      })
        .then((res) => {
          this.handleSuccess({ message: res.data ? res.data.msg : null })
        })
        .catch((error) =>
          this.setState({
            isLoading: false,
            failedMessage: error.response.data.msg,
            errors: error.response.data,
          })
        )
    })
  }

  render() {
    const { open } = this.props,
      { activeStep, isLoading } = this.state,
      steps = this.stepsContent()

    return (
      <ResponsiveDialog
        open={open}
        handleCloseDialog={this.handleClose}
        hideCloseButton={true}
        dialogTitle="Depósito"
        dialogSubTitle="Banco de Trocas"
        failedMessage={this.state.failedMessage}
        successMessage={this.state.successMessage}
        actions={
          <>
            <button
              className="btn btn--primary outlined action-btn"
              onClick={() =>
                this.state.activeStep > 0
                  ? this.handleChangeStep('back')
                  : this.handleClose()
              }
            >
              {this.state.activeStep > 0 ? 'Voltar' : 'Cancelar'}
            </button>

            {this.state.activeStep < this.state.stepsCount - 1 ? (
              <button
                className="btn btn--primary raised action-btn"
                onClick={() => this.handleChangeStep('next')}
              >
                Continuar
              </button>
            ) : (
              <button
                className={`btn btn--primary raised action-btn ${
                  (this.state.rules.length > 0 ? false : 'disabled') ||
                  (this.hasFee() && !this.state.iban ? 'disabled' : false)
                }`}
                onClick={this.handleConfirm}
              >
                {this.first_time ? 'Confirmar ' : 'Atualizar '}depósito
              </button>
            )}
          </>
        }
        isLoading={isLoading}
      >
        <SwipeableViews
          // animateHeight
          axis={'x'}
          index={activeStep}
          disabled={true}
          style={{ width: '100%' }}
        >
          {Object.values(steps).map((step, index) => (
            <div
              className={`swipeable__view ${activeStep !== index && 'hidden'}`}
              key={index}
            >
              {step}
            </div>
          ))}
        </SwipeableViews>
        <ExchangeRuleDialog
          open={this.state.openRule}
          handleCloseDialog={() => this.handleOpenRuleDialog({ open: false })}
          failedMessage={this.state.failedMessage}
          handleSaveRule={this.handleSaveRule}
          handleChangeRuleInput={this.handleChangeRuleInput}
          ruleDialogType={this.state.ruleDialogType}
          rule={this.state.tempRule}
          errors={this.state.tempRuleErrors}
        />
      </ResponsiveDialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    partners: state.bookings.partners,
    propertyTypes: state.resorts.propertyTypes,
    memberId: state.members?.member?.customer_id,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadExchanges: () => dispatch(loadExchanges()),
    loadBookings: () => dispatch(loadBookings()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(ExchangeDialog))
