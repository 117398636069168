import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExchangeDialog from '../Exchanges/ExchangeDialog';
import { hasFunds } from '../Contracts/utils';
import HelpToolTip from '../utils/HelpToolTip';
import { getContractsSelector } from '../Contracts/selectors';
import { Contract } from '../Contracts/types';

import PartnerDialog from './PartnerDialog';
import BookNow from './BookNow';
import BookingHistory from './BookingHistory';
import BookingCard from './BookingCard';
import { getBookingsSelector } from './selectors';
import { Booking } from './types';
import { loadBookings } from './actions/bookingsActions';

export type HandleOpenDialogProps = {
  dialog: string;
  open: boolean;
  contract?: Contract | null;
  booking?: Booking | null;
};

export function Bookings(): React.ReactElement {
  const { items: contracts } = useSelector(getContractsSelector);
  const { items: bookings } = useSelector(getBookingsSelector);

  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBookings());
  }, [dispatch]);

  const handleOpenDialog = ({ dialog, open = true, contract = null, booking = null }: HandleOpenDialogProps) => {
    setOpenDialog(open ? `${dialog}Dialog` : null);
    setSelectedBooking(open ? booking : null);
    setSelectedContract(open ? contract : null);
  };

  const hasContractsAllowedToBook = Object.values(contracts).some((contract) => hasFunds(contract));

  const upcomingBookings = Object.values(bookings).filter((booking) => {
    return moment(booking.checkin) > moment() && ['confirmed', 'unconfirmed'].includes(booking.status);
  });
  const hasUpcomingBookings = upcomingBookings.length > 0;

  return (
    <Fragment>
      <div className="grid__container spacing-32">
        <div className="grid__item xs-12 md-9 xl-10">
          <div className="grid">
            <div className="grid__container spacing-16">
              {hasUpcomingBookings ? (
                upcomingBookings.map((item, index) => (
                  <div
                    key={index}
                    className="grid__item xs-12 md-6 lg-4 xl-3 animated zoomIn faster"
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    <BookingCard handleOpenDialog={handleOpenDialog} booking={item} />
                  </div>
                ))
              ) : (
                <div className="grid__item xs-12 md-6 lg-4 xl-3 animated zoomIn faster">
                  <div className="panel">
                    <div className="card__body">
                      <p className="text-mutted">Não tem reservas agendadas.</p>
                    </div>
                    {hasContractsAllowedToBook && <BookNow />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item xs-12 md-3 xl-2">
          <Accordion
            defaultExpanded={false}
            className="panel panel--transparent animated fadeInRight faster"
            style={{ animationDelay: '0.5s' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel__summary">
              <div className="grid__container spacing-8 --align-center">
                <div className="grid__item">
                  <h2 className="body_2">Histórico</h2>
                </div>
                <div className="grid__item">
                  <HelpToolTip title="A partir de 2019." />
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="panel__body">
              <BookingHistory bookings={bookings} contracts={contracts} />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <PartnerDialog
        contract={selectedContract}
        booking={selectedBooking}
        handleCloseDialog={() => setOpenDialog(null)}
        open={openDialog === 'PartnerDialog'}
      />
      <ExchangeDialog
        booking={selectedBooking}
        handleCloseDialog={() => setOpenDialog(null)}
        open={openDialog === 'ExchangeDialog'}
      />
    </Fragment>
  );
}

export default Bookings;
