import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  CompareArrows as TradeIcon,
  Group as PartnersIcon,
  Delete as DeleteIcon,
  Create as EditIcon,
} from '@mui/icons-material';
import { Booking } from './types';
import { HandleOpenDialogProps } from './Bookings';

type BookingMenuProps = {
  booking: Booking;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleOpenDialog: (data: HandleOpenDialogProps) => void;
  isBookingFromExchange: boolean;
};
export function BookingMenu({
  booking,
  anchorEl,
  handleClose,
  handleOpenDialog,
  isBookingFromExchange,
}: BookingMenuProps): React.ReactElement {
  return (
    <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      {booking.exchange ? (
        <div>
          <MenuItem
            className="list__item"
            onClick={() => {
              handleClose();
              handleOpenDialog({
                dialog: 'Exchange',
                open: true,
                booking: booking,
              });
            }}
          >
            <ListItemIcon className="list-item__icon">
              <EditIcon />
            </ListItemIcon>
            <ListItemText className="list-item__text" inset>
              <p className="primary --t-primary">Editar</p>
              <p className="secondary --t-secondary">Editar as regras para a troca</p>
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="list__item"
            onClick={() => {
              handleClose();
              handleOpenDialog({
                dialog: 'ConfirmExclusion',
                open: true,
                booking: booking,
              });
            }}
          >
            <ListItemIcon className="list-item__icon --t-alert">
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText className="list-item__text" inset>
              <p className="primary --t-alert">Excluir</p>
              <p className="secondary --t-alert">Remover a reserva do Banco de Trocas</p>
            </ListItemText>
          </MenuItem>
        </div>
      ) : (
        <div>
          <MenuItem
            className="list__item"
            onClick={() => {
              handleOpenDialog({
                dialog: 'Exchange',
                open: true,
                booking: booking,
              });
              handleClose();
            }}
          >
            <ListItemIcon className="list-item__icon">
              <TradeIcon />
            </ListItemIcon>
            <ListItemText className="list-item__text" inset>
              <p className="primary --t-primary">Banco de Trocas</p>
              <p className="secondary --t-secondary">Depositar reserva no Banco de Trocas</p>
            </ListItemText>
          </MenuItem>
          {booking.can_deposit_partner && !isBookingFromExchange && (
            <MenuItem
              className="list__item"
              onClick={() => {
                handleOpenDialog({
                  dialog: 'Partner',
                  open: true,
                  booking: booking,
                });
                handleClose();
              }}
            >
              <ListItemIcon className="list-item__icon">
                <PartnersIcon />
              </ListItemIcon>
              <ListItemText className="list-item__text" inset>
                <p className="primary --t-primary">Intercâmbio</p>
                <p className="secondary --t-secondary">Depositar semana numa das empresas de intercâmbio</p>
              </ListItemText>
            </MenuItem>
          )}
        </div>
      )}
    </Menu>
  );
}

export default BookingMenu;
