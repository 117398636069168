import axios from 'axios';
import { BASE_API_URL, BASE_API_URL_MEMBER } from '../urls';

import { handleErrors } from '../../utils/helpers/errors';
import { setHeadersToken } from '../../utils/helpers/store';
import { clearMemberData } from '../../../redux/actions/clearMemberData';

export const login = (email, password) => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOGIN_START' });
    const data = {
      username: email,
      password: password,
    };
    axios
      .post(`${BASE_API_URL}auth/login/`, data)
      .then((res) => {
        dispatch({ type: 'LOGIN_SUCCESSFUL', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'AUTHENTICATION_ERROR');
      });
  };
};
export const createUser = (email, password, password_confirm, customer_id) => {
  return (dispatch, getState) => {
    dispatch({ type: 'CREATE_USER_START' });
    const data = {
      email: email,
      password: password,
      password_confirm: password_confirm,
      customer_id: customer_id,
    };
    axios
      .post(`${BASE_API_URL_MEMBER}create-user/`, data)
      .then((res) => {
        dispatch({ type: 'USER_CREATED', payload: res.data });
        dispatch(login(email, password));
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'USER_CREATION_FAILED');
      });
  };
};

export const findUserByEmail = (email) => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOOKING_FOR_USER' });
    axios
      .get(`${BASE_API_URL}${email}/`)
      .then((res) => {
        dispatch({ type: 'USER_EXISTS', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'USER_NOT_FOUND');
        dispatch(findMemberByEmail(email));
      });
  };
};
export const findMemberByEmail = (email) => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOOKING_FOR_MEMBER_BY_EMAIL' });
    axios
      .get(`${BASE_API_URL_MEMBER}find/${email}/`)
      .then((res) => {
        dispatch({ type: 'MEMBER_FOUND_WITHOUT_USER', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'USER_NOT_FOUND');
      });
  };
};

export const sendPasswordRecover = (email) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SENDING_PASSWORD_RECOVER' });
    axios
      .post(`${BASE_API_URL}auth/password/reset/`, { email: email })
      .then((res) => {
        dispatch({ type: 'PASSWORD_RECOVER_SENT', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'PASSWORD_RECOVER_FAILED');
      });
  };
};
export const setNewPassword = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SETTING_NEW_PASSWORD' });

    axios
      .post(``, data)
      .then((res) => {
        dispatch({ type: 'PASSWORD_CHANGE_SUCCESS', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'PASSWORD_CHANGE_FAIL');
      });
  };
};
export const findMember = (customer_id, contract) => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOOKING_FOR_MEMBER' });
    axios
      .get(`${BASE_API_URL_MEMBER}${customer_id}-${contract}`)
      .then((res) => {
        dispatch({ type: 'MEMBER_EXISTS', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'MEMBER_NOT_FOUND');
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    axios
      .post(`${BASE_API_URL}auth/logout/`)
      .then((res) => {
        dispatch({ type: 'LOGOUT', payload: res.data });
        clearMemberData(dispatch)
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'LOGOUT_FAILED');
      });
  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    axios
      .get(`${BASE_API_URL}`, { headers: setHeadersToken() })
      .then((res) => {
        dispatch({ type: 'USER_LOADED', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'USER_FAILED');
      });
  };
};
