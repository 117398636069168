import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Description as ContractIcon } from '@mui/icons-material';

import { HandleOpenDialogProps } from '../../views/Dashboard/Contracts';
import { getSiteConfigSelector } from '../../selectors';

import { get_weeks_period, hasFunds } from './utils';
import { Contract } from './types';

type ContractCardProps = {
  contract: Contract;
  handleOpenDialog: (data: HandleOpenDialogProps) => void;
};

export function ContractCard({ contract, handleOpenDialog }: ContractCardProps): React.ReactElement {
  const { data: siteConfig } = useSelector(getSiteConfigSelector);

  let fixedWeeks: number[] = [];
  contract.memberships.forEach((membership) => {
    if (membership.fixed_week) fixedWeeks.push(membership.fixed_week);
  });

  let actions_enabled = hasFunds(contract) || contract.pay_per_use,
    credit_balance = contract.credit_balance;

  let weeks = get_weeks_period(contract);
  let weeksGrantedGrouped: `${string} a ${string}`[] = [];
  Object.values(weeks).forEach(
    (obj) =>
      obj['year'] === moment().year() &&
      obj['grant'].forEach((item: string[]) => weeksGrantedGrouped.push(`${item[0]} a ${item[1]}`))
  );

  return (
    <div className="card">
      <div className="card__body">
        <div className="grid">
          <div className="grid__container spacing-xs-16 spacing-md-32">
            <div className="grid__item xs-12 sm-auto">
              <div className="grid__container grid__container--column spacing-24">
                <div className="grid__item">
                  <div className="grid__container spacing-16 --align-center">
                    <div className="grid__item" style={{ fontSize: '40px', display: 'flex' }}>
                      <ContractIcon fontSize="inherit" style={{ opacity: 0.25 }} />
                    </div>
                    <div className="grid__item">
                      <label className="overline">Número</label>
                      <p className="card__title">
                        {(contract.is_platform && contract.ref_number) || contract.composed_number}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid__item">
                  <div className="grid__container grid__container--column spacing-16">
                    <div className="grid__item">
                      <label className="overline">Tipo</label>
                      <p className="card__text">{contract.contract_type.cst_description}</p>
                    </div>
                    {contract.property_type && (
                      <div className="grid__item">
                        <label className="overline">Empreendimento</label>
                        <p className="card__text">{contract.property_type.name}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__container grid__container--column spacing-16">
                    <div className="grid__item">
                      <label className="overline">
                        Semanas atribuídas{' '}
                        {contract.contract_type.cst_description.toLowerCase() === 'absolute' && `em ${moment().year()}`}
                      </label>
                      <p className="card__text">{weeksGrantedGrouped.join(', ')}</p>
                    </div>
                    {fixedWeeks.length > 0 && (
                      <div className="grid__item">
                        <label className="overline">Semana(s) fixa(s)</label>
                        <p className="card__text">{fixedWeeks.join(', ')}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!contract.pay_per_use && (
              <Fragment>
                <div className="grid__item xs-12 divisor divisor--h hide-for-sm p-0-force m-8 "></div>
                <div className="grid__item xs-12 sm-auto ml-auto --ta-sm-right --ta-xs-left">
                  <div className="grid">
                    <div className="grid__container grid__container--column spacing-16">
                      {Object.values(credit_balance).map((item, i) => {
                        if (!item.credits.Pontos && !item.credits.Semanas) {
                          return null;
                        }
                        return (
                          <div className="grid__item xs-12" key={i}>
                            <div className="grid__container grid__container--column spacing-8 --ta-sm-right">
                              <div className="grid__item">
                                <label className="overline">{item.display_text}</label>
                              </div>
                              <div className="grid__item">
                                {Object.keys(item.credits).length > 0 ? (
                                  Object.entries(item.credits).map(([k, v], idx) => (
                                    <span key={idx}>
                                      <span className="chip chip--info light">{`${k}: ${v}`}</span>{' '}
                                    </span>
                                  ))
                                ) : (
                                  <span className="chip chip--light-gray light">Sem saldo</span>
                                )}
                              </div>
                            </div>
                            <p></p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            <div className="grid__item xs-12 pt-0 mt-sm-16 mt-xs-8">
              {/* <div className="divisor divisor--h mb-16"></div> */}
              <div className="card__action-btns --justify-start p-0-force">
                {siteConfig.site.domain === 'membros.pontagrande.com' && contract.can_deposit_partner && (
                  <button
                    className={`btn btn--primary outlined action-btn ${!actions_enabled && 'disabled'}`}
                    onClick={() =>
                      handleOpenDialog({
                        dialog: 'Partner',
                        open: true,
                        contract: contract,
                      })
                    }
                  >
                    Depositar
                  </button>
                )}
                <button
                  className={`btn btn--primary raised action-btn ${!actions_enabled && 'disabled'}`}
                  onClick={() =>
                    handleOpenDialog({
                      dialog: 'Booking',
                      open: true,
                      contract: contract,
                    })
                  }
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractCard;
