import moment from 'moment'

/**
 * Return bool for contract has credits
 * @param {obj} contract
 */
export const hasFunds = (contract) =>
  Object.values(contract.credit_balance).some((item) =>
    Object.values(item.credits).some((it) => it > 0)
  )

export const get_weeks_period = (contract) => {
  let weeks = []

  Object.values(contract.memberships).forEach((membership) =>
    membership.booking_period.forEach((item) => {
      let yearIdx = weeks.findIndex((it) => it.year === item.year)
      if (yearIdx === -1)
        yearIdx =
          weeks.push({
            year: item.year,
            grant: [],
            grant_dates: [],
            deny: [],
            deny_dates: [],
          }) - 1

      Object.entries(item.calendar).forEach(([key, val]) => {
        if (['grant', 'deny'].includes(key))
          val.forEach((r) => {
            let idx = weeks[yearIdx][key].findIndex(
              (i) => i[0] === r[0] && i[1] === r[1]
            )
            if (idx === -1) weeks[yearIdx][key].push(r)
          })
        if (key.includes('_dates'))
          val.forEach(
            (date) =>
              !weeks[yearIdx][key].includes(date) &&
              weeks[yearIdx][key].push(date)
          )
      })
    })
  )

  /**
   * Remove duplicates and sort by item[0] than item[1]
   */
  Object.values(weeks).forEach((obj) =>
    Object.entries(obj).forEach(
      ([key, item]) =>
        ['grant', 'deny'].includes(key) &&
        item.sort((a, b) =>
          a[0] < b[0] ? -1 : a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0
        )
    )
  )
  /**
   * Better grouping
   * (1,12), (1,17)
   * Become (1,17)
   */

  Object.values(weeks).forEach((obj, weekIdx) =>
    Object.entries(obj).forEach(([key, item]) => {
      let newArr = [],
        group
      if (['grant', 'deny'].includes(key)) {
        item.forEach((item, i, self) => {
          if (i === 0) group = [...item]

          if (i > 0) {
            let before = self[i - 1]

            if (
              (item[0] >= before[0] &&
                item[0] <= before[1] &&
                item[1] > before[1]) ||
              item[0] === before[1] + 1
            )
              group[1] = item[1]

            if (item[0] > before[1] + 1) {
              newArr.push(group)
              group = [...item]
            }

            if (i === self.length - 1) {
              newArr.push(group)
            }
          } else if (self.length === 1) {
            newArr.push(group)
          }
        })
        weeks[weekIdx][key] = newArr
      }
    })
  )

  return weeks
}

/**
 * Return merged forbidden dates from contract memberships
 */
export const getForbiddenDates = (contract) => {
  let forbiddenDates = []
  if (contract) {
    let weeks = get_weeks_period(contract)
    weeks.forEach((obj) =>
      Object.entries(obj).forEach(([key, arr]) => {
        if (key === 'deny_dates') {
          arr.forEach((range) =>
            range.forEach(
              (day) =>
                !forbiddenDates.find((fd) => moment(fd).isSame(day)) &&
                forbiddenDates.push(day)
            )
          )
        }
      })
    )
  }
  return forbiddenDates
}

/**
 * Return array of periods using dates 'from' and 'to' to be displayed on
 * granted period
 *
 * @param {date} date
 * @param {obj} contract
 */
export const getPeriodsGranted = (date, contract) => {
  let periodsGranted = []
  if (contract) {
    let weeks = get_weeks_period(contract),
      currYear = date ? moment(date).year() : moment().year()

    weeks = weeks.filter((obj) => obj.year === currYear)

    weeks.forEach((obj) =>
      Object.entries(obj).forEach(([key, arr]) => {
        if (key === 'grant_dates') {
          arr.forEach((range) => {
            let idx = periodsGranted.findIndex((it) => it.year === obj.year)
            if (idx < 0)
              idx = periodsGranted.push({ year: obj.year, ranges: [] }) - 1
            let from = range[0],
              to = range[range.length - 1]
            !periodsGranted[idx].ranges.find(
              (it) => moment(it[0]).isSame(from) && moment(it[1]).isSame(to)
            ) && periodsGranted[idx]['ranges'].push([from, to])
          })
        }
      })
    )
  }
  return periodsGranted
}
