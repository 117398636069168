import React from 'react'
import FormActionButtons from './FormActionButtons'

const FormBase = ({ handleSubmit, handleBack, children }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid__container grid__container--column spacing-24">
        <div className="grid__item">{children}</div>
        <div className="grid__item">
          <FormActionButtons handleBack={handleBack} />
        </div>
      </div>
    </form>
  )
}

export default FormBase
