import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { withWidth } from '@material-ui/core';
import GuideStep from './GuideStep';
import { Preloader } from '../../utils/Preloader';
import { readGuide } from '../actions/communicationsActions';

class Guide extends Component {
  state = {
    isLoading: null,
    activeStep: 0,
  };

  getStep = (step) => {
    switch (step) {
      case 'next':
        return this.state.activeStep >= Object.values(this.props.guide.steps).length ? 0 : this.state.activeStep + 1;
      case 'back':
        return this.state.activeStep > 0 ? this.state.activeStep - 1 : 0;
      default:
        return !isNaN(+step) ? step : 0;
    }
  };
  handleChangeStep = (step) => {
    this.setState({
      activeStep: this.getStep(step),
    });
  };

  handleFinish = (slug) => {
    this.props.readGuide(slug);
    this.props.handleClose();
  };

  render() {
    const guide = this.props.guide,
      stepsCount = Object.values(guide.steps).length,
      isLoading = this.props.communications.isLoading;
    let activeStep = this.state.activeStep;

    let bullets = () => {
      let items = [];
      for (let i = 0; i < stepsCount; i++) {
        items.push(
          <span
            onClick={() => this.handleChangeStep(i)}
            key={i}
            className={`bullet ${activeStep === i && 'active'} clickable`}
          />
        );
      }
      return items;
    };

    return (
      <Fragment>
        <div className="dialog__bullets">{bullets()}</div>

        <SwipeableViews
          // animateHeight
          axis={'x'}
          index={activeStep}
          disabled={true}
          style={{ width: '100%' }}
        >
          {Object.values(guide.steps).map((step, index) => (
            <GuideStep
              className={`dialog__body swipeable__view ${activeStep !== index && 'hidden'}`}
              key={index}
              step={step}
              member={this.props.member}
            />
          ))}
        </SwipeableViews>

        {isLoading ? (
          <Preloader />
        ) : (
          <div className="dialog__action-btns">
            {this.state.activeStep > 0 && (
              <button className={`btn btn--primary outlined action-btn`} onClick={() => this.handleChangeStep('back')}>
                Voltar
              </button>
            )}
            <button
              type="submit"
              className={`btn btn--primary raised action-btn`}
              onClick={() =>
                this.state.activeStep < stepsCount - 1 ? this.handleChangeStep('next') : this.handleFinish(guide.slug)
              }
            >
              {this.state.activeStep < stepsCount - 1 ? 'Continuar' : 'Finalizar'}
            </button>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.members.member,
    communications: state.communications,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    readGuide: (slug) => dispatch(readGuide(slug)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(Guide));
