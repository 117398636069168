import React from 'react'

const PaymentMethodStep = ({
  method_name,
  processing_time,
  image,
  children,
}) => (
  <div className="grid__container --align-center spacing-16">
    <div className="grid__item xs-12 md-auto">
      <img src={image} alt={method_name} />
    </div>
    <div className="grid__item xs-12 md-auto" style={{ flex: '1 auto' }}>
      <div className="grid__container grid__container--column spacing-16 pt-16">
        <div className="grid__item">
          <label className="overline">Forma de pagamento</label>
          <p className="card__text --t-bold">{method_name}</p>
        </div>
        <div className="grid__item">
          <label className="overline">Tempo de processamento</label>
          <p className="card__text --t-bold">{processing_time}</p>
        </div>
        <div className="grid__item">{children}</div>
      </div>
    </div>
  </div>
)

export default PaymentMethodStep
