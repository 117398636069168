import TransferIcon from '../../assets/img/transfer.png';
import PaypalIcon from '../../assets/img/paypal.png';
import MultibancoIcon from '../../assets/img/multibanco.png';
import Cards from '../../assets/img/cards.png';

export type CardMethodType = 'stripe' | 'paypal';
export type ManualMethodType = 'multibanco' | 'bank-transfer';
export type PaymentMethodType = CardMethodType | ManualMethodType;

export const CardMethods: Record<string, CardMethodType> = {
  stripe: 'stripe',
  paypal: 'paypal',
};

export const ManualMethods: Record<string, ManualMethodType> = {
  multibanco: 'multibanco',
  bankTransfer: 'bank-transfer',
};

export const PaymentMethods = {
  ...CardMethods,
  ...ManualMethods,
};

type PaymentMethodsType = {
  [key in keyof typeof PaymentMethods]: {
    title: string;
    processingTime: string;
    children: React.ReactElement | null;
    name: PaymentMethodType;
    icon: string;
  };
};

export const PAYMENT_METHODS: PaymentMethodsType = {
  [CardMethods.stripe]: {
    title: 'Cartão (crédito/débito)',
    processingTime: 'imediato',
    children: null,
    name: CardMethods.stripe,
    icon: Cards,
  },
  [CardMethods.paypal]: {
    title: 'PayPal',
    processingTime: 'imediato',
    children: null,
    name: CardMethods.paypal,
    icon: PaypalIcon,
  },
  [ManualMethods.multibanco]: {
    title: 'Multibanco',
    processingTime: 'até 24h',
    children: null,
    name: ManualMethods.multibanco,
    icon: MultibancoIcon,
  },
  [ManualMethods.bankTransfer]: {
    title: 'Transferência Bancária',
    processingTime: 'até 5 dias úteis',
    children: null,
    name: ManualMethods.bankTransfer,
    icon: TransferIcon,
  },
};
