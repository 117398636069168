import moment from 'moment'

/**
 * Return the membership that has more credits
 * @param {obj} contract
 */
export const getMembership = (contract, checkin) => {
  let membership = contract.memberships.find((membership) => {
    if (checkin)
      return membership.booking_period.find((bookingPeriod) => {
        return bookingPeriod.calendar.grant_dates.find((grantedDate) =>
          grantedDate.includes(moment(checkin).format('YYYY-MM-DD'))
        )
      })

    return Object.values(membership.balance).find((b) => b > 0)
  })

  if (!membership) return contract.memberships[0].id
  return membership.id
}
