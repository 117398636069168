import React from 'react'
import ContractListCard from './ContractListCard'
import ContractListCardSkeleton from './ContractListCardSkeleton'

const ContractList = ({
  contracts,
  isLoading = false,
  inputContract = '',
  setMember,
}) => {
  if (!isLoading && (!contracts || (contracts && contracts.length < 1)))
    return null
  return (
    <div className="grid">
      <div className="grid__container grid__container--column spacing-8">
        {isLoading
          ? [1, 2, 3].map((count, idx) => (
              <div className="grid__item" key={idx}>
                <ContractListCardSkeleton />
              </div>
            ))
          : contracts.map((contract, idx) => (
              <div className="grid__item" key={idx}>
                <ContractListCard
                  contract={contract}
                  inputContract={inputContract}
                  setMember={setMember}
                />
              </div>
            ))}
      </div>
    </div>
  )
}

export default ContractList
