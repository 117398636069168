import axios from 'axios';
import { BASE_API_URL } from '../urls';
import { setHeadersToken } from '../../utils/helpers/store';
import { handleErrors } from '../../utils/helpers/errors';

export const loadBookings = () => {
  return (dispatch, getState) => {
    const memberId = getState().members?.member?.customer_id;
    if (memberId) {
      dispatch({ type: 'BOOKINGS_LOADING' });
      axios
        .get(`${BASE_API_URL}${memberId}`, {
          headers: setHeadersToken(),
        })
        .then((res) => {
          dispatch({ type: 'BOOKINGS_LOADED', payload: res.data });
        })
        .catch((error) => {
          handleErrors(error, dispatch, 'BOOKINGS_LOADED_FAILED');
        });
    } else {
      dispatch({ type: 'BOOKINGS_LOADED', payload: [] });
    }
  };
};
export const loadPartners = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'BOOKINGS_PARTNERS_LOADING' });

    axios
      .get(`${BASE_API_URL}partners/`, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        dispatch({ type: 'BOOKINGS_PARTNERS_LOADED', payload: res.data });
      })
      .catch((error) => {
        handleErrors(error, dispatch, 'BOOKINGS_PARTNERS_FAILED');
      });
  };
};
