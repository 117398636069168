const initialState = {
  isLoading: false,
  isPaying: false,
  items: [],
  errors: null,
}

const paymentsReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'PAYMENTS_LOADING':
    case 'PAYMENT_START':
      return { ...state, errors: null, isLoading: true }
    case 'PAYMENTS_LOADED':
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      }
    case 'PAYMENT_CHECK_START':
      return {
        ...state,
        isLoading: true,
      }
    case 'PAYMENTS_SUCCESS':
    case 'PAYMENT_CHECK_SUCCESS':
      return {
        ...state,
        isLoading: false,
      }
    case 'PAYMENT_CHECK_FAILED':
      return {
        ...state,
        isLoading: false,
      }

    case 'PAYMENT_FAILED':
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      }

    default:
      return state
  }
}
export default paymentsReducers
