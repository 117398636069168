import { CompareArrows as TradeIcon } from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'

export const ExchangedBooking = ({
  icon = <TradeIcon color='primary' />,
  text = 'Reserva trocada',
  style = {},
}) => (
  <Grid container alignItems='center' spacing={2} style={style}>
    {icon && <Grid item>{icon}</Grid>}
    {text && (
      <Grid>
        <Typography variant='body2'>{text}</Typography>
      </Grid>
    )}
  </Grid>
)
