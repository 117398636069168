import React, { Fragment } from 'react'

const GuideStep = ({ step, member, props, children }) => {
  return (
    <div {...props}>
      {step && (
        <Fragment>
          {step.slug.toLowerCase().indexOf('greetings') >= 0 ? (
            <h1 className="dialog__greetings">
              Olá <span className="member">{member && member.first_name}</span>,
            </h1>
          ) : (
            <div className="dialog__header">
              {step.title && (
                <h1 className="dialog__title --nocase">{step.title}</h1>
              )}
              {step.subtitle && (
                <p className="dialog__subtitle">{step.subtitle}</p>
              )}
            </div>
          )}
          {step.featured_img && (
            <img
              className="dialog__featured_img"
              src={step.featured_img}
              alt={step.title}
            />
          )}
          {step.content && (
            <div
              className="dialog__content"
              dangerouslySetInnerHTML={{ __html: step.content }}
            />
          )}
        </Fragment>
      )}
      {children}
    </div>
  )
}

export default GuideStep
