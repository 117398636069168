import React from 'react'
import CreateUserForm from './CreateUserForm'
import FindMemberForm from './FindMemberForm'
import Greetings from './Greetings'

const AuthStepUserNotFound = ({
  activeStep,
  handleInputChange,
  handleBack,
  credentials,
  props,
  handleCreateUser,
  handleFindMember,
}) => {
  const { memberExists, memberFoundWithoutUser } = props.auth
  return (
    <div className="grid__container grid__container--column spacing-24">
      {!memberExists ? (
        <>
          <div className="grid__item">
            {!memberFoundWithoutUser ? (
              <>
                <h1 className="card__title --t-alert">
                  Desculpe, mas não encontramos o seu email.
                </h1>
                <p>
                  Insira o seu Nº de Identificação e Nº de Contrato que podem
                  ser encontrados na carta/email que lhe enviámos.
                </p>
              </>
            ) : (
              <Greetings
                name={memberFoundWithoutUser.full_name}
                message="Te encontramos, mas precisamos confirmar seu Nº
                                de Contrato."
              />
            )}
          </div>
          <div className="grid__item">
            <FindMemberForm
              activeStep={activeStep}
              contract={credentials.contract}
              hide_customer_id={memberFoundWithoutUser ? true : false}
              customer_id={credentials.customer_id}
              errors={props.errors}
              handleChange={handleInputChange}
              handleSubmit={handleFindMember}
              handleBack={handleBack}
            />
          </div>
        </>
      ) : (
        <div className="grid__item">
          <div className="grid__container grid__container--column spacing-24">
            <div className="grid__item">
              <Greetings
                greet={memberFoundWithoutUser && false}
                name={memberExists.member.full_name}
                message={
                  !memberExists.member.user
                    ? `Vamos actualizar os seus dados para aceder ao sistema.`
                    : `Você já tem um email registado:`
                }
              />

              {/* Member and user already exist */}
              {memberExists.member.user && (
                <>
                  <p className="card__text --t-alert">
                    <b>{memberExists.member.user.email}</b>
                  </p>
                  <p>Favor utilize-o para aceder ao nosso sistema.</p>
                  <div className="stepper__footer-actions">
                    <button
                      type="button"
                      className={`btn btn--primary outlined action-btn`}
                      onClick={() => {
                        handleInputChange('email')(
                          memberExists.member.user.email
                        )
                        handleBack()
                      }}
                    >
                      Voltar
                    </button>
                  </div>
                </>
              )}
            </div>
            {!memberExists.member.user && (
              <div className="grid__item">
                <CreateUserForm
                  email={credentials.email}
                  password={credentials.password}
                  password_confirm={credentials.password_confirm}
                  errors={props.errors}
                  handleSubmit={handleCreateUser}
                  handleChange={handleInputChange}
                  handleBack={handleBack}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AuthStepUserNotFound
