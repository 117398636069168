export const handleErrors = (error = null, dispatch, dispatchAfter = null) => {
  if (error.response) {
    if (error.response.status)
      switch (error.response.status) {
        case 500:
          dispatch({ type: 'SERVER_ERROR_500' })
          break

        default:
          break
      }
    error.response.data &&
      dispatchAfter &&
      dispatch({ type: dispatchAfter, errors: error.response.data })
  } else {
    dispatch({ type: 'SERVER_ERROR_UNKNOWN' })
    throw error
  }
  // throw error
}
