import React from 'react';
import { useSelector } from 'react-redux';
import { Member as MemberType } from '../../../components/Members/types';

import { getMemberSelector } from '../../../components/Members/selectors';
import { getSiteConfigSelector } from '../../../selectors';
import SectionHeader from '../SectionHeader';

const MemberData: React.FC<{ member: MemberType }> = ({ member }) => {
  return (
    <div className="grid">
      <div className="grid__container grid__container--column spacing-16">
        <div className="grid__item">
          <label className="overline">Nome</label>
          <p className="card__title">
            {member.first_name} {member.middle_name} {member.last_name}
          </p>
        </div>
        <div className="grid__item">
          <label className="overline">Email</label>
          <p className="small">{member.email ? member.email : '-'}</p>
        </div>
        <div className="grid__item">
          <label className="overline">Telemóvel</label>
          <p>{member.phone_mobile ? member.phone_mobile : '-'}</p>
        </div>
        <div className="grid__item">
          <label className="overline">Casa</label>
          <p>{member.phone_home ? member.phone_home : '-'}</p>
        </div>
        <div className="grid__item">
          <label className="overline">Trabalho</label>
          <p>{member.phone_work ? member.phone_work : '-'}</p>
        </div>
        <div className="grid__item">
          <label className="overline">Endereço</label>
          <p className="small">{`${member.address} - ${member.post_code}`}</p>
        </div>
      </div>
    </div>
  );
};

export function Member(): React.ReactElement {
  const { data: siteConfig } = useSelector(getSiteConfigSelector);
  const { member } = useSelector(getMemberSelector);
  const { contact_email: contactEmail } = siteConfig;

  return (
    <div className="grid__container grid__container--column spacing-32">
      <div className="grid__item">
        <SectionHeader
          icon="person"
          title="Dados"
          subtitle={
            <span>
              Caso queria atualizar algum dado, favor entrar em contato conosco pelo email{' '}
              <a href={`mailto:${contactEmail}`}>
                <b>{contactEmail}</b>.
              </a>
            </span>
          }
        />
        <div className="grid__item xs-12 md-6 animated zoomIn faster">
          <div className="panel">
            <div className="panel__body">
              {member ? <MemberData member={member} /> : <div>Dados não encontrados.</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
