import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Chip, Avatar } from '@material-ui/core'

import { ArrowRightAlt } from '@material-ui/icons'

import EuroCoin from '../utils/EuroCoin'

import BookingData from '../Bookings/BookingData'
import CardSmall from '../utils/CardSmall'
import BookingDates from '../Bookings/BookingDates'

export const TransactionCardSmall = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isManuallyClosed, setIsManuallyClosed] = useState(false)
  const { transaction, handleTransactionDialog, buyerId, extraClass } = props
  const booking = props.booking || transaction?.exchange?.booking

  const handleSetIsExpanded = useCallback(() => {
    if (
      transaction &&
      transaction?.status?.slug === 'processing' &&
      !isManuallyClosed
    )
      setIsExpanded(true)
  }, [transaction, isManuallyClosed])

  const handleExpand = (value) => {
    setIsExpanded(value)
    setIsManuallyClosed(true)
  }

  useEffect(() => {
    handleSetIsExpanded()
  }, [handleSetIsExpanded])

  if (!booking || !buyerId) return null

  return (
    <CardSmall
      extraClass={extraClass}
      handleExpandClick={() => handleExpand(!isExpanded)}
      avatar={
        transaction.status.slug !== 'processing' && (
          <Avatar style={{ backgroundColor: 'transparent', color: '#bdbdbd' }}>
            <ArrowRightAlt
              style={{
                fontSize: 30,
                transform:
                  transaction.exchange.seller !== buyerId && 'rotate(180deg)',
              }}
            />
          </Avatar>
        )
      }
      avatarSrc={
        transaction.status.slug === 'processing' &&
        booking.property_type.get_featured_img
      }
      avatarAlt={
        transaction.status.slug === 'processing' && booking.property_type.name
      }
      expanded={isExpanded}
      headerContent={
        <>
          <h1 className='card__title'>{booking.property_type.name}</h1>
          <BookingDates
            hideLabel={true}
            hideIcon={true}
            checkin={booking.checkin}
            checkout={booking.checkout}
          />
          <div className='grid__container spacing-8 --align-center'>
            <div className='grid__item'>
              <label
                className={`chip chip--status chip--${
                  transaction.status.class_name
                } ${!isExpanded ? 'condensed' : ''}`}
              >
                {transaction.status.name}
              </label>
            </div>
            <div className='grid__item'>
              <small className={`${isExpanded && '--t-zero'}`}>
                {moment(transaction.created_at).calendar()}
              </small>
            </div>
          </div>
        </>
      }
      actionBtns={
        handleTransactionDialog &&
        transaction.buyer === buyerId &&
        transaction.is_paid === true ? (
          <p className='action-btn --t-bold --ta-left'>
            A aguardar a confirmação até
            <br />
            {moment(transaction.created_at).add(3, 'days').calendar()}
          </p>
        ) : (
          transaction.status.slug === 'processing' && (
            <>
              {transaction.buyer === buyerId && (
                <button
                  className={`btn btn--alert outlined action-btn btn--small`}
                  onClick={() =>
                    handleTransactionDialog({
                      dialog: 'GiveUp',
                      open: true,
                      transaction: transaction,
                    })
                  }
                >
                  Desistir
                </button>
              )}
              <button
                className={`btn btn--primary raised action-btn`}
                onClick={() =>
                  handleTransactionDialog({
                    dialog:
                      transaction.exchange.seller === buyerId
                        ? 'ConfirmTransaction'
                        : 'ConfirmPayment',
                    open: true,
                    transaction: transaction,
                  })
                }
              >
                Confirmar Transferência
              </button>
            </>
          )
        )
      }
    >
      <p className='--t-bold'>Regra</p>
      <div className='grid__container grid__container--column spacing-16'>
        {transaction.booking && (
          <div className='grid__item'>
            <BookingData booking={transaction.booking} />
          </div>
        )}
        {transaction.rule.fee && (
          <>
            <div className='grid__item'>
              {transaction.booking && <p className='text-mutted'>+ valor:</p>}
              <Chip
                variant='outlined'
                style={{ fontWeight: 'bold' }}
                avatar={<EuroCoin />}
                label={transaction.rule.fee}
              />
            </div>
            {transaction.status.slug === 'processing' &&
              transaction.exchange.seller !== buyerId && (
                <div className='grid__item'>
                  <label className='overline'>IBAN</label>
                  <p>{transaction.exchange.iban}</p>
                </div>
              )}
          </>
        )}
      </div>
      <div className='card__divisor' />
      <div className='grid__container spacing-16'>
        <div className='grid__item'>
          <label className='overline'>Início da transação</label>
          <p className='small'>{moment(transaction.created_at).calendar()}</p>
        </div>
        {transaction.status.slug !== 'processing' && (
          <div className='grid__item'>
            <label className='overline'>Fim da transação</label>
            <p className='small'>{moment(transaction.updated_at).calendar()}</p>
          </div>
        )}
        {transaction.status.slug === 'canceled' && (
          <div className='grid__item xs-12'>
            <label className='overline'>Motivo do cancelamento</label>
            <p className='small'>{transaction.canceled_reason}</p>
          </div>
        )}
      </div>
    </CardSmall>
  )
}

const mapStateToProps = (state) => ({
  buyerId: state.members?.member?.customer_id,
})

export default connect(mapStateToProps)(TransactionCardSmall)
