import React from 'react';
import moment from 'moment';
import BookingDates from './BookingDates';
import { connect } from 'react-redux';
import { Description as ContractIcon } from '@material-ui/icons';
import { ExchangedBooking } from '../commom/ExchangedBooking';

const Booking = ({ booking, contracts }) => {
  const isBookingFromExchange = !Boolean(contracts?.find((contract) => booking.contract === contract.composed_number));
  return (
    <div className="card hoverFadeIn">
      <div className="card__body">
        <div className="grid__container grid__container--column spacing-8">
          <div className="grid__item">
            <div className="list-item__text --t-bold">
              <span className={`chip chip--status ${booking.status} mr-8`}></span>{' '}
              {!booking.partner ? booking.property_type.name : booking.partner.name}
              <ExchangedBooking
                style={{ marginTop: 2 }}
                icon={
                  !isBookingFromExchange ? (
                    <ContractIcon className="defaultIconClass" style={{ fontSize: 20 }} />
                  ) : undefined
                }
                text={!isBookingFromExchange ? booking.contract : undefined}
              />
            </div>
          </div>
          {booking.partner ? (
            <div className="grid__item">
              <p className="list-item__text">Pedido em {moment(booking.confirmed_at).format('DD MMM YYYY')}</p>
            </div>
          ) : (
            <div className="grid__item">
              <BookingDates checkin={booking.checkin} checkout={booking.checkout} hideLabel={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Empty = () => <p className="section__subtitle section__subtitle--small">Ainda não possui histórico.</p>;

const BookingHistory = (props) => {
  const { bookings, contracts } = props;
  const pastOrDeposit = Object.values(bookings)
    .filter(
      (booking) =>
        ['canceled', 'confirmed'].includes(booking.status) && (moment(booking.checkin) < moment() || booking.partner)
    )
    .sort((a, b) => {
      let aDate = a.checkin,
        bDate = b.checkin;
      if (a.partner || b.partner)
        [aDate, bDate] = [moment(a.confirmed_at).format('YYYY-MM-DD'), moment(b.confirmed_at).format('YYYY-MM-DD')];
      return moment(aDate).isSame(moment(bDate)) ? 0 : moment(aDate).isBefore(moment(bDate)) ? 1 : -1;
    });

  if (!bookings || Object.values(pastOrDeposit).length === 0) return <Empty />;

  return (
    <div className="grid">
      <div className="grid__container grid__container--column spacing-8">
        {Object.values(pastOrDeposit).map((booking) => (
          <div className="grid__item" key={booking.id}>
            <Booking booking={booking} contracts={contracts} />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contracts: state.contracts?.items,
});

export default connect(mapStateToProps)(BookingHistory);
